import { Model, model, modelAction, prop } from 'mobx-keystone';


@model('pt/Image')
class Image extends Model({
    name: prop<string>(''),
    url: prop<string>(''),
    size: prop<number[]>(() => []),
    scale: prop<number>(1),
}) {

    @modelAction
    setScale(scale: number) {
        this.scale = scale;
    }

}


export default Image;
