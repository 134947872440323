import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Body, Title } from '@strategies/ui';
import { Group, Input, Label } from '@strategies/react-form';

import InnerPanel from './InnerPanel';
import Record from '../../../models/Record';
import { layout } from '../../../models/Layout';
import Category from '../../../models/Category';
import { SelectCategory } from '../../SelectCategory/SelectCategory';

const tryRounding = (x: number | string) => x !== '-' ? Math.round(x as number) : x;
const tryFixing = (x: number | string) => x !== '-' ? (x as number).toFixed(2) : x;


export default observer(function DimensionsInnerPanel() {
    const { history, records, ui, units } = useStores();

    const withHistory = useMemo(() => ({
        onFocus: () => history.startGroup(),
        onBlur: () => history.isGrouping && history.stopGroup(),
    }), [history]);

    const setValue = useCallback((setter: (r: Record) => any) => {
        records.selected.forEach(setter);
    }, [records.selected]);

    const getValue = useCallback((getter: (r: Record) => any) => {
        let value: any = null;

        for (let record of records.selected) {
            if (value === null) {
                value = getter(record);
            } else if (value !== getter(record)) {
                return '-';
            }
        }

        return value;
    }, [records.selected]);

    const category: Category | string = getValue(r => r.category);


    return records.selected.length > 0 ? (
        <InnerPanel 
            className="DimensionsInnerPanel"
            active={ui.dimensionsInnerPanel}
            setActive={active => ui.setDimensionsInnerPanel(active)}
        >
            <Title>Dimensions</Title>
            <Body>
                {ui.layout === layout.ADJACENCY ? <>
                    <Group>
                        <Input
                            name="Net"
                            type="number"
                            placeholder="-"
                            value={tryRounding(getValue(r => r.targetNetUnits))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setTargetNetUnits(value))
                            }}
                            right={units.squareUnitsDisplay}
                            {...withHistory}
                        />

                        <Input
                            className="efficiency-factor"
                            name="Net to Gross"
                            type="number"
                            placeholder="-"
                            value={tryFixing(getValue(r => r.efficiencyFactor))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setEfficiencyFactor(value))
                            }}
                            right={'%'}
                            {...withHistory}
                        />

                        <Input
                            name="Gross"
                            placeholder="-"
                            type="number"
                            value={tryRounding(getValue(r => r.targetGrossUnits))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setTargetGrossUnits(value))
                            }}
                            right={units.squareUnitsDisplay}
                            {...withHistory}
                        />
                    </Group>

                    <Group className="with-category">
                        <Input
                            name={`$/${units.squareUnitsDisplay}`}
                            type="number"
                            placeholder="-"
                            value={tryFixing(getValue(r => r.costPerSqUnit))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setCostPerSqUnit(value))
                            }}
                            left="$"
                            {...withHistory}
                        />

                        <Label name="Category">
                            <SelectCategory
                                menuPlacement="bottom"
                                category={category === '-' ? null : category as Category}
                                setCategory={(category: Category) => setValue(r => r.setCategory(category))}
                            />
                        </Label>
                    </Group>
                </> : <>
                    <Group>
                        <Input
                            name="Length"
                            type="number"
                            placeholder="-"
                            value={tryRounding(getValue(r => r.planHeight))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setPlanHeight(value))
                            }}
                            right={units.linearUnitsDisplay}
                            {...withHistory}
                        />

                        <Input
                            name="Width"
                            placeholder="-"
                            type="number"
                            value={tryRounding(getValue(r => r.planWidth))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setPlanWidth(value))
                            }}
                            right={units.linearUnitsDisplay}
                            {...withHistory}
                        />

                        <Input
                            name="Area"
                            type="number"
                            placeholder="-"
                            value={tryRounding(getValue(r => r.squareUnits))}
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setSquareUnits(value))
                            }}
                            right={units.squareUnitsDisplay}
                            {...withHistory}
                        />
                    </Group>

                    <Group className="with-category">
                        <Input
                            name="Rotation"
                            type="number"
                            placeholder="-"
                            value={getValue(r => Math.round(r.layout.rotation))}//we need to pass the rounded rotation value to getValue otherwise values that should display the same may be off by a fraction and not show up
                            onKeyUp={(value) => {
                                if (typeof value !== "number" || isNaN(value)) return;
                                setValue(r => r.setPlanRotation(value))
                            }}
                            onFocus={() => history.startGroup()}
                            onBlur={() => { 
                                //CODE-REVIEW - note this works if user clicks somewhere else on the panel, but not if they click e.g. on the plan view (this onBlur is never triggered)
                                setValue(r => r.setPlanRotation(r.planRotation % 360)); 
                                history.stopGroup()
                            }}
                            right="deg"
                        />

                        <Label name="Category">
                            <SelectCategory
                                menuPlacement="bottom"
                                category={category === '-' ? null : category as Category}
                                setCategory={(category: Category) => setValue(r => r.setCategory(category))}
                            />
                        </Label>
                    </Group>
                </>}
            </Body>
        </InnerPanel>
    ) : <></>;
});
