import { v4 } from 'node-uuid';
import Versions from '@strategies/versions';

import { layout } from './models/Layout';
import { control } from './models/Position';

export function portFromRealtimeFormat(snapshot:any) {
    return {persistStore:snapshot}
}

export default new Versions({

    '1.0.0': (data: any) => {
        const LinkedPosition = (data: any) => ({
            $modelType: 'pt/LinkedPosition',
            link: data.linkId,
            x: data.x,
            y: data.y,
        });

        const ControlPoints = (data: any) => ({
            $modelId: v4(),
            $modelType: 'mobx-keystone/ObjectMap',
            items: Object.keys(data).reduce((items, id) => {
                items[id] = {
                    ...data[id]
                };

                return items;
            }, {} as any),
        });

        const Shape = (data: any) => {
            const shapeMap = {
                circle: 'Circle',
                rect: 'Rectangle',
                polygon: 'Polygon',
            };

            const s: {
                $modelType: string;
                rotation: number;
                width?: number;
                height?: number;
                radius?: number;
                controlPointType?: control;
                vertices?: any[];
                controlPoints?: any;
                position: any;
            } = {
                // @ts-ignore
                $modelType: `pt/${shapeMap[data.type]}`,
                rotation: data.rotation,
                position: {
                    $modelType: 'pt/Position',
                    ...data.position,
                }
            };

            switch(data.type) {
                case 'circle':
                    s.radius = data.rx;
                    break;
                // @ts-ignore
                case 'polygon':
                    s.controlPointType = data.controlPointType === 'vertex' ? control.VERTEX : control.ORTHO;
                    s.controlPoints = ControlPoints(data.controlPoints);
                    s.vertices = data.vertices.map(LinkedPosition);
                case 'rectangle': // eslint-disable-line
                    s.width = data.width;
                    s.height = data.height;
            }

            return s;
        };

        const Layout = (data: any) => ({
            $modelType: 'pt/Layout',
            placed: data.isPlaced,
            shape: Shape(data.shape),
        });

        const Record = (data: any) => {
            const r = {
                $modelType: 'pt/Record',
                categoryRef: {
                    $modelType: 'pt/CategoryRef',
                    id: data.parentDepartment,
                },
                ...data,
                layouts: {
                    [layout.PLAN]: Layout(data.layouts.plan),
                    [layout.ADJACENCY]: Layout(data.layouts.adjacency),
                }
            };

            delete r.parentDepartment;
            delete r.department;

            return r;
        };

        const Arrow = (data: any) => ({
            $modelType: 'pt/Arrow',
            id: v4(),
            originRef: {
                $modelType: 'pt/RecordRef',
                id: data.origin,
            },
            targetRef: {
                $modelType: 'pt/RecordRef',
                id: data.target,
            },
        });

        const file = {
            $modelType: 'pt/File',
            fileId: data.persistStore.fileId,//TODO data.persistStore is undefined!
            fileName: data.persistStore.fileName,

            project: {
                $modelType: 'pt/Project',
                ...data.persistStore.project,
            },

            image: {
                $modelType: 'pt/Image',
                name: data.persistStore.imageName,
                scale: data.persistStore.imageScale,
                size: data.persistStore.imageSize,
                url: data.persistStore.imageURL,
            },

            users: [],
            records: [],
            arrows: [],
            categories: [],
        };

        file.records = data.persistStore.records.map(Record);
        file.arrows = data.persistStore.arrows.map(Arrow);
        file.categories = data.persistStore.parentDepartments.map((data: any) => ({
            $modelType: 'pt/Category',
            ...data
        }));
        // file.users = data.persistStore.userCollection.map((data: any) => ({
        //     $modelType: 'pt/User',
        //     ...data
        // }));

        delete file.project.unitSystem;

        return file;
    },

});
