//@ts-nocheck
import React, { Component } from 'react';
import { observer } from "mobx-react";
import { StoresContext, useStores } from '@strategies/stores';

import { shape } from '../../models/Shape';
import File from "../../models/File";
import ExportOptions from "../../models/ExportOptions";


@observer
class PlanShape extends Component {

    static contextType = StoresContext;
    private objRef: React.RefObject<SVGGraphicsElement>;

    constructor(props) {
        super(props);

        this.objRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.objRef.current) {
            this.props.record.setSVGElement(this.objRef.current);
        }
    }

    render() {
        const { record, exportMode } = this.props;
        const { app, stage, units } = this.context;
        const { layout } = record;

        const textAreaStyle = {
            width: layout.width,
            height: layout.height
        };

        const globalTranslateString = `translate(${-layout.width / 2}, ${-layout.height / 2})`;

        const fontSize = (baseVal) => {
            const logScale = Math.pow(stage.scale, 0.8);
            return (baseVal / logScale);
        };

        const textStyle = (baseVal, wrap) => {
            const style = { fontSize: fontSize(baseVal) + 'em' };

            if (!wrap) {
                style.whiteSpace = 'nowrap';
            }

            return style;
        };

        const scaledSize = {
            width: stage.scale * layout.width,
            height: stage.scale * layout.height,
        };

        const breakPoints = {//calibrated
            singleRowMax: {
                small: { scale: 0.75, height: 24 },
                large: { scale: 5, height: 90 },
            },
            doubleRowMax: {
                small: { scale: 0.75, height: 42 },
                large: { scale: 5, height: 125 },
            },
            tripleRowMax: {
                small: { scale: 0.75, height: 60 },
                large: { scale: 5, height: 160 },
            },
            widthMax: {
                small: { scale: 0.35, width: 40 },
                large: { scale: 5, width: 150 },
            }
        };

        const breakPoint = (key, prop) => {
            const bp = breakPoints[key];
            //use linear interpolation between small and large breakpoints to get breakpoint 'height' for current scale
            const norm = (stage.scale - bp.small.scale) / (bp.large.scale - bp.small.scale);
            return bp.small[prop] + norm * (bp.large[prop] - bp.small[prop]);
        };

        const nameScale = 1.2;

        const nameWrap = () => {
            return scaledSize.height > breakPoint('tripleRowMax', 'height');
        };

        const canDisplayName = () => {
            return scaledSize.height > breakPoint('doubleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        const canDisplayArea = () => {
            return scaledSize.height > breakPoint('singleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        const RenderShape = (props) => {
            let useForRef = props.useForRef;
            const newProps = {};
            Object.keys(props).forEach((k) => {
                if (k === 'useForRef') return;
                newProps[k] = props[k];
            });
            if (layout.shape.type === shape.RECTANGLE) {
                return <rect ref={(useForRef) ? this.objRef : null}
                             width={layout.width}
                             height={layout.height} {...newProps}/>;
            }
            if (layout.shape.type === shape.POLYGON) {
                return <polygon ref={(useForRef) ? this.objRef : null}
                                points={layout.shape.sizedVertices.map(p => p.x + ',' + p.y).join(' ')} {...newProps}/>;
            }
        };

        if (exportMode) {
            const file = this.context.supermodel.file as File;

            const textCenter = { x: layout.width / 2, y: layout.height / 2 };

            let nameFontSize = 12;
            let areaFontSize = 12;

            if (file.exportOptions) {
                nameFontSize *= file.exportOptions.nameScale;
                areaFontSize *= file.exportOptions.areaScale;
            }

            return (
                <g className="Rect" transform={`rotate(${layout.rotation}) ` + globalTranslateString}>
                    {<RenderShape fill={record.color} fillOpacity={0.9} stroke={'black'}/>}
                    <g transform={`translate(${textCenter.x}, ${textCenter.y})`}>
                        {nameFontSize > 0 && <text fontSize={nameFontSize} textAnchor="middle" fill={'#111111'}
                                                   y={-10}>{record.name}</text>}
                        {areaFontSize > 0 && <text fontSize={areaFontSize} textAnchor="middle" fill={'#222222'}
                                                   y={10}>{units.displayArea(record.targetNetM)}</text>}
                    </g>
                </g>
            );
        }

        return (
            <g className="Rect" transform={`rotate(${layout.rotation}) ` + globalTranslateString}>

                {<RenderShape fill={record.color} fillOpacity={0.9}/>}

                {!this.props.editLabel &&
                    <foreignObject
                        className="label"
                        x="5"
                        y="5"
                        width={layout.width - 10}
                        height={layout.height - 10}>
                        <div className="label-text">
                            <div className="label-text-inner">
                                {canDisplayName() &&
                                    <div className="label-name"
                                         style={textStyle(nameScale, nameWrap())}>{record.name}</div>}
                                {canDisplayArea() &&
                                    <div
                                        className="label-area"
                                        style={textStyle(1.3, false)}>{units.displayArea(record.squareM)}
                                    </div>}
                            </div>
                        </div>
                    </foreignObject>}

                <RenderShape
                    useForRef={true}
                    className={'object ' + ((record.selected) ? 'selected ' : '') + ((record.active) ? 'active ' : '') + ((record.hasWarnings && !app.inPresentationMode) ? 'warning ' : '')}
                    onMouseDown={this.props.handleMouseDown}
                    onDoubleClick={this.props.handleEditingName}
                    onClick={this.props.handleMouseClick}
                    onMouseEnter={this.props.handleMouseEnter}
                    onMouseLeave={this.props.handleMouseLeave}
                    fill={"transparent"}/>

                {this.props.editLabel &&
                    <foreignObject
                        className="label"
                        x="5"
                        y="5"
                        width={layout.width - 10}
                        height={layout.height - 10}>
                        <div className="name-edit">
                            <textarea
                                autoFocus
                                style={textAreaStyle}
                                value={record.name}
                                onMouseDown={(e) => e.stopPropagation()}
                                onKeyPress={this.props.handleKeyPress}
                                onChange={(e) => this.props.editName(e.target.value)}
                            />
                        </div>
                    </foreignObject>}
            </g>
        );
    }
}

PlanShape.defaultProps = {
    exportMode: false,
};
export default PlanShape;
