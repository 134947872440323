import { computed } from 'mobx';
import { inverse } from 'transformation-matrix';
import { Model, model, modelAction, prop } from 'mobx-keystone';


export interface ITransformMatrix {
    x?: number;
    y?: number;
    a?: number;
    b?: number;
    c?: number;
    d?: number;
    e?: number;
    f?: number;
}

@model('pt/TransformMatrix')
class TransformMatrix extends Model({
    x: prop<number>(0),
    y: prop<number>(0),
    a: prop<number>(1),
    b: prop<number>(0),
    c: prop<number>(0),
    d: prop<number>(1),
    e: prop<number>(-5000),
    f: prop<number>(-5000),
}) implements ITransformMatrix {

    @modelAction
    set(value: ITransformMatrix) {
        for (let item in value) {
            // @ts-ignore
            this[item] = value[item];
        }
    }

    @computed
    get inverse() {
        return inverse(this);
    }

}


export default TransformMatrix;
