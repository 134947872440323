import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import SuperModel from '../stores/SuperModelStore';
import { CheckboxSlider } from "@strategies/react-form";
import UIStore from "../stores/UIStore";
import { FiUsers } from "react-icons/fi";

type UserPhotoProps = { photoURL?: string, color: string, name: string };
export const UserPhoto = (props: UserPhotoProps) => {
    const {
        color,
        photoURL,
        name,
    } = props;
    return <div className="UserPhoto" style={{ borderColor: color, background: color }}>
        {photoURL && <img src={photoURL} alt={`${name}'s profile pic`} referrerPolicy="no-referrer"/>}
        {!photoURL && <div className={'big-char'}>{name.slice(0, 1)}</div>}
    </div>
};


type UserProps = { photoURL: string | null, color: string, name: string };
const User = (props: UserProps) => {
    const {
        color,
        photoURL,
        name,
    } = props;
    return <li className={`active-user`}>
        <UserPhoto photoURL={photoURL || undefined} color={color} name={name}/>

        <div className="active-user__info">{name}</div>
    </li>
};

export default observer(function ActiveUsers() {
    const ui = useStores().ui as UIStore;
    const supermodel = useStores().supermodel as SuperModel;
    const { remoteUsers, localUser } = supermodel.users;
    if (ui.showActiveUsers < 0) return null;

    if (!localUser) return null;
    return (
        <div className={`ActiveUsers`} style={{ left: ui.showActiveUsers }}>
            <div className={'header'}>
                <FiUsers/>
                <span className={'panel-title'}>Team</span>
            </div>
            <ul>
                {<User color={localUser.color} photoURL={localUser.photoURL} name={localUser.name}/>}
                {remoteUsers.map(user => <User key={user.uid} color={user.color} photoURL={user.photoURL}
                                               name={user.name}/>)}
            </ul>

            {remoteUsers.length > 0 &&
                <div className="option-toggle">
                    <CheckboxSlider
                        value={ui.showCollaboratorsCursors}
                        onChange={(val: boolean) => ui.setShowCollaboratorsCursors(val)}
                    />
                    <div className={'label'}>
                        Show collaborators' cursors
                    </div>
                </div>
            }
            <div className={'buttons'}>
                <button className="Button secondary" onClick={() => ui.setShowActiveUsers(false)}>Close</button>
                <button className="Button secondary" onClick={() => {
                    if (window.confirm('Are you sure you want to logout?')) {
                        supermodel.logout()
                    }
                }}>Logout
                </button>
                <button className="Button secondary" onClick={() => {
                    const params = encodeURIComponent(JSON.stringify({
                        action: 'open',
                        ids: [supermodel.file.fileId]
                    }))
                    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}?state=${params}`).then(() => {
                        alert('Copied Shareable Link to Clipboard')
                    });
                }}>Share
                </button>
            </div>
        </div>
    );
});
