import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Title, Body } from '@strategies/ui';
import { CheckboxSlider } from '@strategies/react-form';

import InnerPanel from './InnerPanel';
import ClampedBar from "../charting/ClampedBar";
import StackedBar from "../charting/StackedBar";
import FillBar from "../charting/FillBar";
import Category from "../../../models/Category";

const _$ = (x: number) => `$${Math.round(x).toLocaleString()}`;
export default observer(function CostInnerPanel() {
    const { app, supermodel: { file }, units, ui } = useStores();

    return (
        <>
            <InnerPanel 
                className={"CostInnerPanel"}
                active={ui.costInnerPanel} 
                setActive={active => ui.setCostInnerPanel(active)} 
            >
                <Title>Cost Summary</Title>
                <Body>
                    <div className="summary-group">

                        <div className="BarChart">
                            <label>$/{units.squareUnitsDisplay}</label>
                            <div className="BarChart__graphic">
                                <label className={"BarChart__graphic__label"}>
                                    ${(Math.round(app.totalCost / app.totalTargetSquareUnits) || 0).toLocaleString()}
                                </label>
                            </div>
                        </div>

                        <ClampedBar
                            label={"Total"}
                            values={app.totalCost}
                            clamp={{ min: 0, max: file.project.programBudget }}
                            ramp={['#696969']}
                            format={_$}
                            presentationMode={app.inPresentationMode}
                        />

                        <StackedBar
                            label={"Target"}
                            values={Math.round(file.project.programBudget)}
                            total={Math.round(file.project.programBudget)}
                            ramp={['#D9D9D9']}
                            format={_$}
                        />

                            <div className="DepartmentView">
                                <FillBar
                                    label={`Total Cost All Categories`}
                                    value={app.totalCost}
                                    total={app.totalCost}
                                    ramp={['#696969']}
                                    format={_$}
                                />

                                {file.categories.map((d: Category) => (
                                    <FillBar
                                        key={d.id}
                                        label={d.name}
                                        value={d.cost}
                                        total={app.totalCost}
                                        ramp={[d.color]}
                                        format={_$}
                                    />
                                ))}
                            </div>
                    </div>
                    <label className="presentation-mode-toggle">
                        <CheckboxSlider
                            value={app.inPresentationMode}
                            onChange={() => app.setPresentationMode(!app.inPresentationMode)}
                        />
                        Presentation Mode (hides overages)
                    </label>
                </Body>

            </InnerPanel>
        </>
    );
});
