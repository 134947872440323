import { useCallback } from 'react';
import { observer } from 'mobx-react';


type BarProps = {
    ramp?: string[];
    total: number;
    values: number|number[];
};

export default observer(function Bar({ values, total, ramp = [] }: BarProps) {
    if (!Array.isArray(values)) {
        values = [values];
    }

    const getStyle = useCallback((i: number, value: number) => {
        const style: any = {
            width: `${(100 * value / total) || 0}%`,
        };

        if (ramp[i]) {
            style.background = ramp[i];
        }

        return style;
    }, [total, ramp]);

    return (
        <div className="Bar">
            {values.map((value, i) => (
                <div
                    key={i}
                    className="Bar__graphic"
                    style={getStyle(i, value)}
                />
            ))}
        </div>
    );
});
