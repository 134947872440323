import { v4 } from 'node-uuid';
import { computed } from 'mobx';
import { stores } from '@strategies/stores';
import { Model, model, modelAction, prop, rootRef } from 'mobx-keystone';

import Record from './Record';


const colors = ["#b6d187", "#fbb684", "#e7c57c", "#f69785", "#c6a4cd", "#aabbe1", "#9eaf9b", "#c9ad8f", "#ca9d99", "#b0a88c", "#ababab", "#c0bbdd", "#cddeab", "#fdcba5", "#eed5a3", "#f9b4a4", "#d4bddc", "#c1cde9", "#b6c2b2", "#dac7b2", "#d6b5b1", "#c9c3b0", "#c7c8ca", "#d6d3ea", "#dee9c9", "#fee0c7", "#f3e3c2", "#fcd3c6", "#e4d7ea", "#d9e0f2", "#cfd6cc", "#e6dacc", "#e3cecb", "#dbd7ca", "#dcddde"];


@model('pt/Category')
class Category extends Model({
    id: prop<string>(() => v4()),
    name: prop<string>(),
    color: prop<string>(() => colors.shift() || '#ccc').withSetter(),
    description: prop<string>('').withSetter()
}) {

    getRefId() {
        return this.id;
    }

    @modelAction
    setName(name: string) {
        this.name = name;
    }


    @computed
    get records():Record[] {
        return stores.records.all.filter((r: Record) => r.category === this)
    }

    @computed
    get cost() {
        return this.records.reduce((a: number, b: Record) => a + b.cost, 0);
    }

    @computed
    get gross() {
        return this.records.reduce((a: number, b: Record) => a + b.targetGrossUnits, 0);
    }

}

export const categoryRef = rootRef<Category>('pt/CategoryRef');
export default Category;
