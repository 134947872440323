import { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { StoresContext } from '@strategies/stores';
import { observer } from "mobx-react";
import { getSnapshot } from 'mobx-keystone';
import { saveAs } from 'file-saver';
import { Radio, Group, Description } from '@strategies/react-form';

import Stage from "../Stage/Stage";
import { Body, Button, Buttons, Modal, SubTitle, Title } from "@strategies/ui";
import * as makerjs from "makerjs";
import RecordsStore from "../../stores/RecordsStore";
import UnitsStore from "../../stores/UnitsStore";
import TableStore from "../../stores/TableStore";

const PRGM = 'PRGM';
const CSV = 'CSV';
const CAD = 'CAD';
const PDF = 'PDF';
const SVG = 'Image';
const REVIT = 'REVIT';

type Option = {
    name:string,
    value:string,
    subtext:string
}

const eOptions = [
    {
        name: SVG,
        value: SVG,
        subtext: "Printable image of all elements on the currently visible stage."
    },
    { name: CAD, value: CAD, subtext: "Exports the canvas elements of the view currently active." },
    { name: CSV, value: CSV, subtext: "Exports the 'Tabular' data." },
    // {
    //     name: PDF,
    //     value: PDF,
    //     subtext: "Exports the 'Tabular' data and the canvas elements of the view currently active."
    // },
    { name: PRGM, value: PRGM, subtext: 'Programming Tool project file.' },
];
const sOptions = [
    {
        name: REVIT,
        value: REVIT,
        subtext: "Create a Speckle stream and send your data into a new or exiting Revit file."
    }
];

const postExportMessage = "Your file has been downloaded to your local computer. Please check your local downloads folder to retrieve your file.";
const postSendMessage = "Your data has been pushed to a Speckle stream. For more information please find instructions on here of how to retrieve your data within Revit.";
const successIcon = '🎉';

type ExportModalProps = {

}

@observer
class ExportModal extends Component<ExportModalProps> {

    static contextType = StoresContext;

    constructor(props: any) {
        super(props);
    }

    getDivider() {
        return (<hr style={{
            backgroundColor: '#7d7a96',
            height: 1,
            marginTop: '10px'
        }}/>);
    }

    captureFormatChange = (val:string) => {
        const { ui } = this.context;
        ui.setOptionChange(val);
    };

    isSendState() {
        //TODO: It would be great to move sOptions etc. to a store in future. May be ExportStore.
        const { ui } = this.context;
        let sendOptions:string[] = [];
        sOptions.map((val, i) => {
            sendOptions.push(val.value);
        });
        return sendOptions.includes(ui.optionChange);
    }

    getExportOptions = (options:Option[]) => {
        const { ui } = this.context;

        const isChecked = (value:string) => {
            return ui.optionChange === value;
        };

        const len = options.length;

        return options.map((option, i) => (
            <div key={`${option.value}`}>
                <label htmlFor={`${option.value}`}>
                    <Group>
                        <Radio
                            name={option.name}
                            value={option.value}
                            onChange={value => this.captureFormatChange(value as string)}
                            checked={isChecked(option.value)}
                        />
                        <Description className="desc">
                            {option.subtext}
                        </Description>
                    </Group>
                </label>
                {i < len - 1 && this.getDivider()}
            </div>
        ));
    };

    getFormatTitle = () => {
        return (<div>
            <div className="title-text">
                CHOOSE YOUR DOWNLOAD FILE FORMAT
            </div>
        </div>);
    };

    downloadPrgm() {
        const { file } = this.context.supermodel;

        const appState = JSON.stringify(getSnapshot(file))
        const blob = new Blob([appState], { type: 'application/x-prgm;charset=utf-8' });

        saveAs(blob, `${file.fileName}.prgm`);
    }

    downloadSvg() {
        const { layout } = this.context.ui;

        const exportOptions = {
            width: 2000,
            height: 1000,
        };
        const data = ReactDOMServer.renderToString(<Stage id={'download-svg'} exportMode={true} exportOptions={exportOptions} layout={layout}/>);

        const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
        saveAs(blob, `${layout}.svg`);
    }

    downloadCSV(data:any) {
        const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "export.csv");
    }

    downloadDXF() {
        const { layout } = this.context.ui;
        const records = this.context.records as RecordsStore;
        const units = this.context.units as UnitsStore;
        const models: any = {};
        records.all.forEach((r) => {
            const polyModel = new makerjs.models.ConnectTheDots(
                true,
                r.globalVertices.map(({ x, y }) => [x, -y])
            );

            let lineModel;
            if (r.name !== "") {
                lineModel = {
                    type: 'line',
                    origin: [r.shape.center.x - r.shape.width / 2, -r.shape.center.y / 2],
                    // @ts-ignore
                    end: [r.shape.center.x + r.shape.width / 2, -r.shape.center.y / 2]
                };
                makerjs.model.addCaption(lineModel, r.name,
                    [r.shape.center.x - r.shape.width / 2, -r.shape.center.y / 2],
                    [r.shape.center.x + r.shape.width / 2, -r.shape.center.y / 2]
                )
                // @ts-ignore
                lineModel.layer = `${r.categoryName}_name`
            }
            // @ts-ignore
            polyModel.layer = (r.categoryName !== "") ? r.categoryName : 'Layer0';
            makerjs.model.addCaption(polyModel, units.formatArea(units.toSqUnits(r.squareM)),
                [r.shape.center.x - r.shape.width / 2, -r.shape.center.y],
                [r.shape.center.x + r.shape.width / 2, -r.shape.center.y]
            )
            models[r.id] = {models: { "text_line": lineModel, "polylines": polyModel}};
        })

        // https://documentation.help/AutoCAD-DXF/WS1a9193826455f5ff18cb41610ec0a2e719-7a6f.htm
        // 6 for Meter, 2 for Feet

        const dxfunit = (units.unit === 1) ? 6 : 2;
        const dxf = makerjs.exporter.toDXF(
            { models: models },
            { usePOLYLINE: true, units: `${dxfunit}`, fontSize: 12}
        );

        // export should set the units to 0, which is the default value: unitless--
        // however, it sets to an empty string in the dxf so we fix it with regex
        // we know the $insunits (variable) and 70 (group number) from the autocad documentation (link above)

        const dxfwrite = "$INSUNITS\n70\n" + `${dxfunit}` + "\n";
        const unitsdxf = dxf.replace(/\$INSUNITS(\r\n|\n|\r)70(\r\n|\n|\r)\s/, dxfwrite)
        const blob = new Blob([unitsdxf], { type: "application/dxf;charset=utf-8" });
        saveAs(blob, `${layout}.dxf`);
    }

    download() {
        const { app, ui } = this.context;

        if (ui.optionChange === SVG) {
            ui.setExportModal(false);
            ui.setExportPreviewModal(true);
            return;//i.e. don't show PostExportState
            // this.downloadSvg();
        } else if (ui.optionChange === PRGM) {
            this.downloadPrgm();
        } else if (ui.optionChange === CSV) {
            const table = this.context.table as TableStore;
            this.downloadCSV(table.csvData);
        } else if (ui.optionChange === CAD) {
            this.downloadDXF();
        }
        //closeModal();
        ui.setPostExportState(true);
    }

    renderSections(sectionName:string, options:Option[]) {
        return (<div className="content">
            <div className="content-header">
                {sectionName}
            </div>
            {this.getDivider()}
            <div className="format">
                <div className={"export-options"}>
                    {this.getExportOptions(options)}
                </div>
            </div>
        </div>);
    }


    getPostExportMessage(message:string) {
        return (<div className={"post-state"}>
            <div className={"title"}>{successIcon + " Success!"}</div>
            <div className={"message"}>{message}</div>

        </div>);
    }


    render() {

        const { ui } = this.context;
        if (!ui.postExportState) {
            return (
                <Modal
                    className="ExportModal"
                    active={ui.exportModal}
                    onClose={() => ui.setExportModal(false)}
                >
                    <Title>Export or Send</Title>
                    <SubTitle>Export or send your information and data to other software programs.</SubTitle>

                    <Body>
                        {this.renderSections("Export", eOptions)}
                        {this.renderSections("Send", sOptions)}
                    </Body>
                    <Buttons>
                        {/*<Button className="secondary" onClick={() => ui.setExportPreviewModal(true)}>PREVIEW</Button>*/}

                        <Button className="secondary" onClick={() => ui.setExportModal(false)}>
                            Cancel
                        </Button>

                        <Button onClick={() => this.download()}>
                            Continue
                        </Button>
                    </Buttons>

                </Modal>

            );
        } else {
            return (
                <Modal
                    className="ExportModal"
                    active={ui.exportModal}
                    onClose={() => ui.setExportModal(false)}
                >
                    <Title>{this.isSendState() ? "Send" : "Export"}</Title>
                    <SubTitle>{this.isSendState() ? "Send your information and data to other software programs." : "Export your information and data to other software programs."}</SubTitle>

                    <Body>
                        {this.isSendState() ? this.getPostExportMessage(postSendMessage) : this.getPostExportMessage(postExportMessage)}

                    </Body>
                    <Buttons>
                        <Button onClick={() => {
                            ui.setExportModal(false);
                            ui.setPostExportState(false);
                        }}>
                            Done
                        </Button>
                    </Buttons>

                </Modal>

            );
        }

    }
}


export default ExportModal;
