import React from 'react';
import { observer } from "mobx-react";
import ReactDataSheet from "react-datasheet";
import 'react-datasheet/lib/react-datasheet.css';
import { StoresContext } from '@strategies/stores';
import { Button, Buttons } from "@strategies/ui";
import Record from '../../models/Record'
import CategoriesStore from "../../stores/CategoriesStore";


interface IProps {
}

interface IState {
    grid: { value: string }[][]
}


@observer
class SpreadsheetPaste extends React.Component<IProps, IState> {

    static contextType = StoresContext;

    constructor(props: any) {
        super(props);
        const numRows = 150;
        const grid = [];
        for (let i = 0; i < numRows; i++) {
            grid.push([{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }]);
        }
        this.state = { grid };
    }

    closeModal() {
        const { ui } = this.context;
        ui.setSpreadsheetPasteModal(false);

    }

    createSpaces() {
        const { supermodel, ui } = this.context;
        const { appStateManager } = supermodel;
        const categories = this.context.categories as CategoriesStore;
        appStateManager?.ensureFileCreated().then(() => {

            const { records, units } = this.context;
            this.state.grid.forEach((row) => {
                const [name, category, quantity, size, cost, notes, color] = row;

                const quantityNum = parseInt(quantity.value);
                const sizeNum = parseFloat(size.value.replace(/,/g, ''));
                if (isNaN(sizeNum) || isNaN(quantityNum)) return;

                // console.log('sizeNum', sizeNum, 'quantityNum', quantityNum);


                for (let j = 0; j < quantityNum; j++) {
                    const record = records.create() as Record;
                    record.setName(name.value);
                    record.setComment(notes.value);
                    const valM = units.fromSqUnits(sizeNum);
                    record.setTargetNet(valM, true);
                    if (category.value) {
                        let cat = categories.findByName(category.value);
                        if (!cat) {
                            cat = categories.create(category.value);
                        }
                        cat && record.setCategory(cat);
                    }
                    const costNum = parseFloat(cost.value.replace(/,/g, ''));
                    if (!isNaN(costNum) && costNum > 0) {
                        record.setCostPerSqUnit(parseFloat(cost.value.replace(/,/g, '')));
                    }
                    if (color && /^#[0-9A-F]{6}$/i.test(color.value)) {
                        if (record.category) {
                            record.category.setColor(color.value);
                        }
                    }
                }

            });

            this.closeModal();
        })
        ui.setForceIntroModal(false);
    }

    render() {

        const columns = ['Name', 'Category', 'Quantity', 'Size', 'Cost', 'Notes', 'Color'];

        return (
            <div className="SpreadsheetPaste">
                <div className="sheet-container">
                    {/* @ts-ignore */}
                    <ReactDataSheet
                        // @ts-ignore
                        data={this.state.grid}
                        sheetRenderer={props => (
                            <table className={props.className + ' my-awesome-extra-class'}>
                                <thead>
                                <tr>
                                    {columns.map((col, i) => (<th key={`col_${i}`}>{col}</th>))}
                                </tr>
                                </thead>
                                <tbody>
                                {props.children}
                                </tbody>
                            </table>
                        )}
                        // @ts-ignore
                        valueRenderer={cell => cell.value}
                        onCellsChanged={changes => {
                            const grid = this.state.grid.map(row => [...row]);
                            changes.forEach(({ row, col, value }) => {
                                grid[row][col] = { ...grid[row][col], value: `${value}` };
                            });
                            this.setState({ grid });
                        }}
                    />
                </div>

                <div className={"modal-buttons"}>
                    <Buttons>
                        <Button className="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                        <Button
                            onClick={() => {
                                this.createSpaces();
                            }}>Create Spaces</Button>

                    </Buttons>

                </div>

            </div>
        )
    }
}

export default SpreadsheetPaste;
