import React from 'react';
import { observer } from 'mobx-react';

type LabelCategoryProps = {
    color?: string;
    children?: React.ReactNode;
};
export const LabelCategory = observer(({color, children }: LabelCategoryProps) => {
    return (
        <div>
            <label>
                <span className={(children) ? "LabelColor" : "EditCategoryHandle"} style={{
                    backgroundColor: color
                }}>
                    {children}
                </span>
            </label>
        </div>
    )
});

