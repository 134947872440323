import { Component, useEffect } from 'react';
import { observer } from "mobx-react";
import { StoresContext, useStores } from '@strategies/stores';

import Shape from "./Shape";
import DrawCircle from "./DrawCircle";
import SelectionBox from "./SelectionBox";
import MarqueeTool from "./MarqueeTool";
import Arrow from "./Arrow";

import StageStore, { tool } from '../../stores/StageStore';
import { RemoteUser } from "./RemoteUser";
import RecordsStore from "../../stores/RecordsStore";
import ArrowsStore from "../../stores/ArrowsStore";
import SuperModel from "../../stores/SuperModelStore";
import UIStore from "../../stores/UIStore";

type AdjacencyViewProps = {
    exportMode?: boolean;
};
const AdjacencyView = observer(({ exportMode = false }: AdjacencyViewProps) => {
    //NOTE: temporary solution until we find a good way to provide strongly typed stores using useStores
    const records = useStores().records as RecordsStore;
    const arrows = useStores().arrows as ArrowsStore;
    const stage = useStores().stage as StageStore;
    const ui = useStores().ui as UIStore;
    const supermodel = useStores().supermodel as SuperModel;

    useEffect(() => {
        records.unplaced.forEach((record, i) => {
            record.layout.position.reset();
            record.layout.move(i * 100 + i * 10, 0)
        });
    }, []);

    return (
        <>
            {records.sortedBySize.map((el) =>
                <Shape
                    key={el.id}
                    // @ts-ignore
                    exportMode={exportMode}
                    record={el}
                />
            )}

            {arrows.all.map((el) =>
                <Arrow
                    key={`${el.id}${String(stage.scale)}`}
                    // @ts-ignore
                    exportMode={exportMode}
                    arrow={el}
                />
            )}

            {!exportMode && records.selected.length && !stage.dragging && !stage.resizing &&
                <SelectionBox/>
            }

            {!exportMode && stage.tool === tool.SHAPE && stage.drawing &&
                <DrawCircle/>
            }

            {!exportMode && stage.marqueeSelect &&
                <MarqueeTool/>
            }

            {ui.showCollaboratorsCursors &&
                <g>
                    {supermodel.userStore.adjacencyViewUsers.map(user => <RemoteUser key={user.uid} user={user}
                                                                                     stroke={'#ffffff'}/>)}
                </g>
            }
        </>
    );
});

export default AdjacencyView;
