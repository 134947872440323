import { computed } from 'mobx';
import { ExtendedModel, Model, model, modelAction, prop } from 'mobx-keystone';


@model('pt/Position')
class Position extends Model({
    x: prop<number>(0),
    y: prop<number>(9)
}) {

    @computed
    get value() {
        return {
            x: this.x,
            y: this.y
        };
    }

    @modelAction
    move(x: number, y: number) {
        this.x += x;
        this.y += y;
    }

    @modelAction
    set(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    @modelAction
    assign({ x, y }: Position) {
        this.x = x;
        this.y = y;
    }

    @modelAction
    reset() {
        this.set(0, 0);
    }

}

@model('pt/LinkedPosition')
class LinkedPosition extends ExtendedModel(Position, {
    link: prop<string>(''),
})  {
    @modelAction
    setLink(link: string) {
        this.link = link;
    }
}


export enum control {
    ORTHO,
    VERTEX
};

@model('pt/ControlPoint')
class ControlPoint extends ExtendedModel(Position, {
    id: prop<string>(),
    type: prop<control>(),
    index: prop<number>(),
}) {

    @modelAction
    setType(type: control) {
        this.type = type;
    }

    @modelAction
    setIndex(index: number) {
        this.index = index;
    }

}


export default Position;
export {
    ControlPoint,
    LinkedPosition,
}
