import { Store, stores } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';

import { layout } from '../models/Layout';
import Category from "../models/Category";


export default class UIStore extends Store {

    constructor() {
        super();
        makeObservable(this);

        const updateClientSize = () => {
            this.setClientSize(document.documentElement.clientWidth, document.documentElement.clientHeight);
        };
        window.addEventListener("resize", updateClientSize);
        updateClientSize();
    }

    @computed
    get layout() {
        return stores.supermodel.users.current.layout;
    }

    setLayout(layout: layout) {
        stores.supermodel.users.current.setLayout(layout);
    }

    @computed
    get tableUpdatesDisabled() {
        return this.spreadsheetPasteModal || !this.tabularView;
    }

    @computed
    get viewableArea() {
        //from Overlay CSS
        const toolBarWidth = 56;
        const toolBarHeight = 16 + 39 + 10;
        const dashboardWidth = 40 + 516;

        const tableHeight = 22 + (this.height - toolBarHeight) / 2;

        const visibleHeight = this.height - toolBarHeight - (this.tabularView ? tableHeight : 0);
        const visibleWidth = (this.width - toolBarWidth - (this.dashboardView ? dashboardWidth : 0));

        const bottomSpace = this.tabularView ? tableHeight : 0;
        const rightSpace = this.dashboardView ? dashboardWidth : 0;

        return {
            top: toolBarHeight,
            left: toolBarWidth,
            right: rightSpace,
            bottom: bottomSpace,
            width: visibleWidth,
            height: visibleHeight
        }
    }

    @observable
    width: number = 1000;

    @observable
    height: number = 1000;

    @action
    setClientSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    @computed
    get introModal() {
        if (this.googlePickerActive) return false;
        if (!stores.supermodel.file.fileId) {
            return true;
        } else {
            return this.forceIntroModal;
        }
    }


    @observable
    spreadsheetPasteModal: boolean = false;

    @action
    setSpreadsheetPasteModal(open: boolean = true) {
        this.spreadsheetPasteModal = open;
    }

    @observable
    exportPreviewModal: boolean = false;

    @action
    setExportPreviewModal(open: boolean = true) {
        this.exportPreviewModal = open;
    }

    @observable
    forceIntroModal: boolean = false;


    @action
    setForceIntroModal(open: boolean = true) {
        this.forceIntroModal = open;
    }

    @observable
    infoModal: boolean = false;

    @action
    setInfoModal(open: boolean = true) {
        this.infoModal = open;
    }

    @observable
    settingsModal: boolean = false;

    @action
    setSettingsModal(open: boolean = true) {
        this.settingsModal = open;
    }

    @observable
    loadImageModal: boolean = false;

    @action
    setLoadImageModal(open: boolean = true) {
        this.loadImageModal = open;
    }

    @observable
    scaleImageModal: boolean = true;

    @action
    setScaleImageModal(open: boolean = true) {
        this.scaleImageModal = open;
    }

    @observable
    exportModal: boolean = false;

    @action
    setExportModal(open: boolean = true) {
        this.exportModal = open;
    }

    @observable
    postExportState: boolean = false;

    @action
    setPostExportState(open: boolean = true) {
        this.postExportState = open;
    }

    @observable
    optionChange: string = "PRGM";

    @action
    setOptionChange(value: string ) {
        this.optionChange = value;
    }

    @observable
    copyModal: boolean = false;

    @action
    setCopyModal(open: boolean = true) {
        this.copyModal = open;
    }

    @observable
    editCategory?: Category;

    @action
    setEditCategory(category: Category | undefined) {
        this.editCategory = category;
    }

    @observable
    dashboardView: boolean = true;

    @action
    setDashboardView(open: boolean = true) {
        this.dashboardView = open;
    }

    @observable
    costInnerPanel: boolean = true;

    @action
    setCostInnerPanel(active: boolean = true) {
        this.costInnerPanel = active;
    }

    @observable
    dimensionsInnerPanel: boolean = true;

    @action
    setDimensionsInnerPanel(active: boolean = true) {
        this.dimensionsInnerPanel = active;
    }

    @observable
    elementsInnerPanel: boolean = true;

    @action
    setElementsInnerPanel(active: boolean = true) {
        this.elementsInnerPanel = active;
    }

    @observable
    selectionInnerPanel: boolean = true;

    @action
    setSelectionInnerPanel(active: boolean = true) {
        this.selectionInnerPanel = active;
    }

    @observable
    summaryInnerPanel: boolean = true;

    @action
    setSummaryInnerPanel(active: boolean = true) {
        this.summaryInnerPanel = active;
    }


    @observable
    tabularView: boolean = true;

    @action
    setTabularView(open: boolean = true) {
        this.tabularView = open;
    }

    @observable
    showCollaboratorsCursors: boolean = true;

    @action
    setShowCollaboratorsCursors(show: boolean = true) {
        this.showCollaboratorsCursors = show;
    }

    @observable
    showActiveUsers: number = -1;

    @action
    setShowActiveUsers(show: boolean, xPosition?: number) {
        this.showActiveUsers = show ? (xPosition || 0) : -1;
    }

    @observable
    googlePickerActive: boolean = false;

    @action
    setGooglePickerActive(value: boolean) {
        // console.log('setGooglePickerActive', value);
        this.googlePickerActive = value;
    }

    @observable
    exportOptionsExpanded: boolean = false;

    @action
    setExportOptionsExpanded(value: boolean) {
        this.exportOptionsExpanded = value;
    }
}
