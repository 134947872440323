import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Body, Button, Buttons, Modal, SubTitle, Title } from "@strategies/ui";
import { Group, Input, Select } from "@strategies/react-form";
import { FiInfo } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { fromUnit, toUnit, unitOptions } from "../../stores/UnitsStore";
import { useStores } from "@strategies/stores";
import { useHistoryGroup } from '../../utils';


type SectionBlockProps = {
    name: string;
    subName?: string;
    children: React.ReactNode;
};

export default observer(function ProjectSettingsModal() {
    const { units: u, supermodel: { file }, ui, history } = useStores();
    const currencyUnit = u.currencyUnitsDisplay;
    const { project: p, fileName } = file;

    useHistoryGroup(ui.settingsModal, 'project settings');

    useEffect(() => ReactTooltip.rebuild());

    const cancel = useCallback(() => {
        history.cancelGroup();
        ui.setSettingsModal(false);
    }, [ui, history])

    const floatOf = (val: string | number | undefined): number => {
        return parseFloat(`${val}`.replace(/[^\d.\-]/g, ""));
    }

    const getSectionTitleAndDiv = (title: string) => {
        return (
            <div>
                {(title !== "") ? <>
                    <div className="section-title">
                        {title}
                    </div>
                    <hr style={{
                        backgroundColor: '#7d7a96',
                        height: 1,
                        width: '700px',
                        marginTop: '10px'
                    }}/>
                </> : <>
                    <hr style={{
                        backgroundColor: '#7d7a96',
                        height: 1,
                        width: '700px',
                        marginTop: '10px'
                    }}/>
                </>}
            </div>
        );
    }

    return (
        <Modal className="ProjectSettings" active={ui.settingsModal} onClose={() => ui.setSettingsModal(false)}>
            <Title>Project Settings & Targets</Title>
            <SubTitle>Set your project area and cost assumptions.</SubTitle>
            <Body>
                <div className="content">
                    <SectionBlock name={"project-info"} subName={"section-tooltip"}>
                        <Input
                            name={"Project Name"}
                            value={fileName}
                            className="project-name"
                            onChange={(value) => file.setFileName(value as string)}
                            data-tip="info.."
                            data-place="right"
                        />
                        <div className={"tool-tip"}>
                            <FiInfo data-tip={"This is also the name of your file."} data-place={"right"}/>
                        </div>
                    </SectionBlock>
                    {getSectionTitleAndDiv("Units")}
                    <Select
                        name="Project Units"
                        value={fromUnit(u.unit)}
                        onChange={(target) => u.setUnit(toUnit(target as string))}
                        options={unitOptions}
                    />
                    {getSectionTitleAndDiv("Area")}

                    <SectionBlock name="section-block">
                        <Group>
                            <Input
                                name={"Target Gross Area"}
                                type="number"
                                right={u.squareUnitsDisplay}
                                value={Math.round(p.targetGrossArea)}
                                onKeyUp={(value) => p.setTargetGrossArea(floatOf(value))}
                            />

                            <Input
                                name={"Grossing Factor"}
                                type="number"
                                right={'%'}
                                value={p.grossingFactor}
                                onKeyUp={(value) => p.setGrossingFactor(floatOf(value))}
                            />

                            <Input
                                name={"Net Area"}
                                type="number"
                                right={u.squareUnitsDisplay}
                                value={Math.round(p.netArea)}
                                onKeyUp={(value) => p.setTargetNetArea(floatOf(value))}
                            />
                        </Group>
                    </SectionBlock>
                    {getSectionTitleAndDiv("Costs")}
                    <SectionBlock name="section-block">
                        <Input
                            name={"Total Project Budget"}
                            type="number"
                            left={currencyUnit}
                            value={Math.round(p.totalProjectBudget)}
                            onKeyUp={(value: string | number) => p.setTotalProjectBudget(floatOf(value))}
                        />
                    </SectionBlock>
                    <SectionBlock name="section-block secondary">
                        <Group>
                            <Input
                                name={"Escalation Rate"}
                                type="number"
                                right={'%'}
                                value={Math.round(100 * p.escalationRate) / 100}
                                onKeyUp={(value: string | number) => p.setEscalationRate(floatOf(value))}
                            />

                            <Input
                                name={"Escalation Time"}
                                type="number"
                                right={'months'}
                                value={p.escalationTime}
                                onKeyUp={(value: string | number) => p.setEscalationTime(floatOf(value))}
                            />
                            <Input
                                name={"Escalation Perc"}
                                type="number"
                                right={'%overall'}
                                value={Math.round(100 * p.escalationPerc) / 100}
                                onKeyUp={(value: string | number) => p.setEscalationPerc(floatOf(value))}
                            />

                            <Input
                                name={"Escalation Cost"}
                                type="number"
                                left={currencyUnit}
                                value={Math.round(p.escalationCost)}
                                onKeyUp={(value: string | number) => p.setEscalationCost(floatOf(value))}
                            />
                        </Group>
                    </SectionBlock>
                    <SectionBlock name="section-block secondary">
                        <Input
                            name={"Other Costs"}
                            type="number"
                            left={currencyUnit}
                            value={p.otherCosts}
                            onKeyUp={(value: string | number) => p.setOtherCosts(floatOf(value))}
                        />
                    </SectionBlock>
                    {getSectionTitleAndDiv("")}
                    <SectionBlock name="section-block">
                        <Input
                            name={"Construction Budget"}
                            type="number"
                            left={currencyUnit}
                            value={Math.round(p.constructionBudget)}
                            onKeyUp={(value: string | number) => p.setConstructionBudget(floatOf(value))}
                        />
                    </SectionBlock>

                    <SectionBlock name="section-block secondary">
                        <Group>
                            <Input
                                name={"Soft Cost Perc"}
                                type="number"
                                right={'%'}
                                value={Math.round(p.softCostsPerc * 10) / 10}
                                onKeyUp={(value: string | number) => p.setSoftCostsPerc(floatOf(value))}
                            />

                            <Input
                                name={"Soft Cost"}
                                type="number"
                                left={currencyUnit}
                                value={Math.round(p.softCost)}
                                onKeyUp={(value: string | number) => p.setSoftCost(floatOf(value))}
                            />
                        </Group>
                    </SectionBlock>
                    <SectionBlock name="section-block secondary">
                        <Group>
                            <Input
                                name={"Program Budget"}
                                type="number"
                                left={currencyUnit}
                                value={Math.round(p.programBudget)}
                                onKeyUp={(value: string | number) => p.setProgramBudget(floatOf(value))}
                            />

                            <Input
                                name={"Cost per unit"}
                                type="number"
                                right={currencyUnit + '/' + u.squareUnitsDisplay}
                                value={Math.round(p.costPerUnit)}
                                onKeyUp={(value: string | number) => p.setCostPerUnit(floatOf(value))}
                            />
                        </Group>
                    </SectionBlock>

                </div>
            </Body>

            <Buttons>
                <Button className="secondary" onClick={cancel}>
                    Cancel
                </Button>

                <Button onClick={() => ui.setSettingsModal(false)}>
                    Save
                </Button>
            </Buttons>
        </Modal>

    );
});

const SectionBlock = observer(({ name, subName, children }: SectionBlockProps) => {
    return (
        <>
            <div className={name}>
                {subName ? <>
                    <div className={subName}>
                        {children}
                    </div>
                </> : <>
                    {children}
                </>}
            </div>

        </>


    )
})
