import { Store, stores } from '@strategies/stores';
import { computed, makeObservable } from 'mobx';

import Arrow from '../models/Arrow';
import Record from '../models/Record';


export default class ArrowsStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get all(): Arrow[] {
        return stores.supermodel.file.arrows;
    }

    create(origin: Record, target: Record) {
        stores.supermodel.file.addArrow(origin, target);
    }

    remove(arrow: Arrow) {
        stores.supermodel.file.removeArrow(arrow);
    }

}
