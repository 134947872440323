import { Component } from 'react';
import { observer } from "mobx-react";
import { IReactionDisposer, reaction } from "mobx";
import { applyToPoints, compose, rotateDEG } from 'transformation-matrix';
import { StoresContext } from '@strategies/stores';

import Handle from './Handle';
import DrawArrow from "./DrawArrow";
import Shape, { BoundingBox, Point, Polygon, shape as shapeType } from '../../models/Shape';
import { layout } from '../../models/Layout';
import { control } from '../../models/Position';
import Record from '../../models/Record';

import {
    combineBBoxes,
    convertPts,
    cos,
    degToRadian,
    distance,
    getLength,
    setHeightAndDeltaH,
    setWidthAndDeltaW,
    sin,
    stageToArea,
    stageToLinear
} from "../../utils";
import { BsArrow90DegLeft } from "react-icons/all";
import RecordsStore from "../../stores/RecordsStore";

//import {undoManager} from "../../stores/RootStore";

type SelectionBoxProps = {}
type SelectionBoxState = {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
    arrow: boolean;
}

type IdPoint = Point & {
    id: string;
}

type HandlePosition = IdPoint & {
    opacity: number;
    grab?: (shape: Shape, x: number, y: number) => void;
}

@observer
class SelectionBox extends Component<SelectionBoxProps, SelectionBoxState> {

    static contextType = StoresContext;
    private handleSize: number;
    private arrowHandleSize: number;
    private calculateBounds: boolean;
    private buildBoundingBoxReactionDisposer?: IReactionDisposer;

    constructor(props: SelectionBoxProps) {
        super(props);

        this.handleSize = 8;
        this.arrowHandleSize = 6;
        this.calculateBounds = true;

        this.state = {
            height: 0,
            width: 0,
            x: 0,
            y: 0,
            rotation: 0,
            arrow: false
        };
    }

    buildBoundingBox = () => {
        if (!this.calculateBounds) return;
        const bboxes = [];
        const records = this.context.records as RecordsStore;
        const { selected } = records;

        //for single selections we can use the rotated bounding box (it's neater)
        //but for multiple selections that can have different rotations, we need to treat this as a new
        //unrotated selection
        if (selected.length === 1) {
            bboxes.push(selected[0].layout.shape.box);

            this.setState({
                rotation: selected[0].layout.rotation
            });
        } else {
            selected.forEach((record) => {
                bboxes.push(record.layout.boundingBox);
            });
            this.setState({
                rotation: 0
            });
        }

        const { x, y, width, height } = combineBBoxes(bboxes);
        this.setState({ x, y, width, height });
    };

    componentDidMount() {
        this.buildBoundingBoxReactionDisposer = reaction(
            () => (this.context.records as RecordsStore).selected.map(record => record.layout.area),
            () => this.buildBoundingBox(),
            {
                fireImmediately: true
            }
        );
    }

    componentWillUnmount() {
        this.buildBoundingBoxReactionDisposer && this.buildBoundingBoxReactionDisposer();
    }

    setCircleResize(pos: { x: number, y: number }, record: Record) {
        const startPos = record.layout.shape.position;
        const radius = distance(startPos, pos) / Math.sqrt(2);//relationship of radius vs distance to handle in square
        const r = stageToLinear(radius);
        record.setTargetNet(Math.round(Math.PI * r * r));
    }

    convertPtsWithin(coords: { x: number, y: number }, record: Record) {
        if (!record.svg) return { x: 0, y: 0 }
        const pt = this.context.stage.svg.createSVGPoint();
        pt.x = coords.x;
        pt.y = coords.y;
        return pt.matrixTransform(record.svg.getScreenCTM()?.inverse());
    }

    getBoundsResize(startingBounds: BoundingBox, dragDeltaX: number, dragDeltaY: number, dir: string, lockAspect: boolean) {
        if (lockAspect) {
            switch (dir) {
                case 'nw':
                case 'se':
                    dragDeltaY = dragDeltaX * startingBounds.height / startingBounds.width;
                    break;
                case 'ne':
                case 'sw':
                    dragDeltaY = -dragDeltaX * startingBounds.height / startingBounds.width;
                    break;
            }

        }

        const bounds = {
            x: startingBounds.x,
            y: startingBounds.y,
            width: startingBounds.width,
            height: startingBounds.height,
        };

        switch (dir) {
            case 'nw':
                bounds.width -= dragDeltaX;//negative deltaX will grow width
                bounds.height -= dragDeltaY;
                bounds.x += dragDeltaX;
                bounds.y += dragDeltaY;
                break;
            case 'ne':
                bounds.width += dragDeltaX;
                bounds.height -= dragDeltaY;
                bounds.y += dragDeltaY;
                break;
            case 'sw':
                bounds.width -= dragDeltaX;//negative deltaX will grow width
                bounds.height += dragDeltaY;
                bounds.x += dragDeltaX;
                break;
            case 'se':
                bounds.width += dragDeltaX;
                bounds.height += dragDeltaY;
                break;
        }

        return bounds;
    }

    getRectResize(dragDeltaX: number, dragDeltaY: number, rotateAngle: number, shapeWidth: number, shapeHeight: number, dir: string) {
        // https://github.com/mockingbot/react-resizable-rotatable-draggable
        let x = 0;
        let y = 0;

        let minHeight = 10;
        let minWidth = 10;

        let deltaL = getLength(dragDeltaX, dragDeltaY);
        let alpha = Math.atan2(dragDeltaY, dragDeltaX);
        let beta = alpha - degToRadian(rotateAngle);
        let deltaW = deltaL * Math.cos(beta);
        let deltaH = deltaL * Math.sin(beta);

        switch (dir) {
            case 'nw':
                deltaW = -deltaW;
                deltaH = -deltaH;
                break;
            case 'ne':
                deltaH = -deltaH;
                break;
            case 'sw':
                deltaW = -deltaW;
                break;
            case 'se':
        }

        let widthAndDeltaW = setWidthAndDeltaW(shapeWidth, deltaW, minWidth);
        deltaW = widthAndDeltaW.deltaW;
        let heightAndDeltaH = setHeightAndDeltaH(shapeHeight, deltaH, minHeight);
        deltaH = heightAndDeltaH.deltaH;
        let height = deltaH;
        let width = deltaW;

        switch (dir) {
            case 'nw':
                x = -(deltaW / 2 * cos(rotateAngle) - deltaH / 2 * sin(rotateAngle));
                y = -(deltaW / 2 * sin(rotateAngle) + deltaH / 2 * cos(rotateAngle));
                break;
            case 'ne':
                x = deltaW / 2 * cos(rotateAngle) + deltaH / 2 * sin(rotateAngle);
                y = deltaW / 2 * sin(rotateAngle) - deltaH / 2 * cos(rotateAngle);
                break;
            case 'sw':
                x = -(deltaW / 2 * cos(rotateAngle) + deltaH / 2 * sin(rotateAngle));
                y = -(deltaW / 2 * sin(rotateAngle) - deltaH / 2 * cos(rotateAngle));
                break;
            case 'se':
                x = deltaW / 2 * cos(rotateAngle) - deltaH / 2 * sin(rotateAngle);
                y = deltaW / 2 * sin(rotateAngle) + deltaH / 2 * cos(rotateAngle);
                break;
        }

        return {
            incrementSize: { width, height },
            incrementPosition: { x, y }
        };
    }

    resizePolyCorner = (e: any, dir: string) => {
        const { records } = this.context;

        if (records.selected.length !== 1) return;
        e.stopPropagation();
        const [record] = records.selected;

        if (record.layout.shape.type === shapeType.RECTANGLE) {
            record.layout.setShape(shapeType.POLYGON);
        }
        //TODO if all control points are cleared, record.setShape('rect');

        //undoManager.startGroup(() => {
        const { shape } = record.layout;

        const normPos = (e: any) => {
            const { x, y } = this.convertPtsWithin({ x: e.clientX, y: e.clientY }, record);

            return {
                x: x / shape.width,
                y: y / shape.height
            };
        };

        const onMove = (e: any) => {
            const { x, y } = normPos(e);
            shape.setControlPoint(control.ORTHO, dir, x, y);
        };

        const onUp = (e: any) => {
            const tol = 0.05;
            const { x, y } = normPos(e);

            if (dir === 'nw' && x < tol && y < tol) {
                shape.clearControlPoint(dir);
            }
            if (dir === 'ne' && (1 - x) < tol && y < tol) {
                shape.clearControlPoint(dir);
            }
            if (dir === 'se' && (1 - x) < tol && (1 - y) < tol) {
                shape.clearControlPoint(dir);
            }
            if (dir === 'sw' && x < tol && (1 - y) < tol) {
                shape.clearControlPoint(dir);
            }

            //undoManager.stopGroup();
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onUp)
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
        //});
    };

    movePolyHandle = (e: any, handle: any) => {
        const { records } = this.context;

        if (records.selected.length !== 1) return;
        e.stopPropagation();
        const [record] = records.selected;

        const normPos = (e: any) => {
            const { shape } = record.layout;

            const { x, y } = this.convertPtsWithin({ x: e.clientX, y: e.clientY }, record);

            return {
                x: x / shape.width,
                y: y / shape.height
            };
        };

        //as soon as we grab a mid-point polygon handle, we transform all handle types to 'vertex' rather than 'ortho'
        record.layout.shape.convertToVertexControlPoints();

        if (handle.grab) {
            const pos = normPos(e);
            handle.grab(record.layout.shape, pos.x, pos.y);//inserts a point if needed
        }

        //TODO if all control points are cleared, record.setShape('rect');

        //undoManager.startGroup(() => {
        e.stopPropagation();
        const { shape } = record.layout;

        const onMove = (e: any) => {
            const norm = normPos(e);
            console.log('setControlPoint', handle.id);
            shape.setControlPoint(control.VERTEX, handle.id, norm.x, norm.y);
        };

        const onUp = () => {
            //undoManager.stopGroup();
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onUp)
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
        //});
    };

    resizeShape = (e: any, dir: string) => {
        const { ui, history } = this.context;
        const records = this.context.records as RecordsStore;
        history.startGroup('resize shape');
        e.stopPropagation();

        const convertedPt = convertPts({ x: e.clientX, y: e.clientY });
        let startX = convertedPt.x;
        let startY = convertedPt.y;

        //when we resize shapes it's not a perfect matrix transform as it would be in Illustrator
        //because rectangles must remain orthogonal even if we squish the box
        //as a reasonable compromise, we use the relative bounding boxes (at the start) to apply mini transforms to all
        const recordBoundsRatios: { [key: string]: BoundingBox } = {};
        let startingBounds: BoundingBox;

        if (records.selected.length > 1) {
            this.calculateBounds = false; // can't reliably use calculated bounding boxes while resizing (given ortho requirement)

            const bboxes: BoundingBox[] = [];
            records.selected.forEach(record => {
                bboxes.push(record.layout.boundingBox);
            });

            const combinedRect = combineBBoxes(bboxes);

            records.selected.forEach(record => {
                const bb = record.layout.boundingBox;

                recordBoundsRatios[record.id] = {
                    x: (bb.x - combinedRect.x) / combinedRect.width,
                    y: (bb.y - combinedRect.y) / combinedRect.height,
                    width: bb.width / combinedRect.width,
                    height: bb.height / combinedRect.height,
                };
            });

            startingBounds = combinedRect;
        }

        const onMove = (e: any) => {
            const convertedPt = convertPts({ x: e.clientX, y: e.clientY });

            const dragDeltaX = convertedPt.x - startX;
            const dragDeltaY = convertedPt.y - startY;

            if (records.selected.length === 1) {
                if (ui.layout !== layout.PLAN) {
                    records.selected.forEach(record => {
                        this.setCircleResize(convertedPt, record);
                    });
                    return;
                }

                //NOTE: single rotated bounds uses incremental changes, whereas multiple uses absolute
                const [record] = records.selected;
                const rotateAngle = record.layout.rotation;
                const shapeHeight = record.layout.height;
                const shapeWidth = record.layout.width;

                const {
                    incrementSize,
                    incrementPosition
                } = this.getRectResize(dragDeltaX, dragDeltaY, rotateAngle, shapeWidth, shapeHeight, dir);

                record.layout.resize(incrementSize.width, incrementSize.height);
                record.layout.move(incrementPosition.x, incrementPosition.y);
                this.buildBoundingBox();
                startX = convertedPt.x;
                startY = convertedPt.y;
            } else {
                const newBounds = this.getBoundsResize(startingBounds, dragDeltaX, dragDeltaY, dir, true);

                this.setState(newBounds);

                records.selected.forEach(record => {
                    const { x, y, width, height } = recordBoundsRatios[record.id];

                    const shapeBounds = {
                        x: newBounds.x + x * newBounds.width,
                        y: newBounds.y + y * newBounds.height,
                        width: newBounds.width * width,
                        height: newBounds.height * height,
                    };

                    record.layout.shape.setBounds(shapeBounds);

                    if (ui.layout !== layout.PLAN) {
                        record.setTargetNet(stageToArea(record.layout.area));
                    }
                });
            }
        };

        const onUp = () => {
            this.calculateBounds = true;
            history.stopGroup();
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onUp)
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
    };

    handleRotation = (e: any) => {
        const records = this.context.records as RecordsStore;

        //undoManager.startGroup(() => {
        e.stopPropagation();

        let originalRotation = this.state.rotation;

        //zero rotation equates to the handle being at the top right corner, so we use this as the starting vector
        const handleZero = { x: this.state.x + this.state.width, y: this.state.y };

        const center = {
            x: this.state.x + this.state.width / 2,
            y: this.state.y + this.state.height / 2
        };

        const startVector = {
            x: handleZero.x - center.x,
            y: handleZero.y - center.y
        };

        const onMove = (e: any) => {
            this.setRotation(startVector, e);

            const rotation = this.state.rotation - originalRotation;
            const matrix = compose(
                rotateDEG(rotation, this.state.x + this.state.width / 2, this.state.y + this.state.height / 2)
            );

            records.selected.forEach(record => {
                const coords = applyToPoints(matrix, [[record.layout.x, record.layout.y], [record.layout.x, record.layout.y + record.layout.height]]);
                const rads = Math.atan((coords[0][0] - coords[1][0]) / (coords[0][1] - coords[1][1]));
                const degs = (rads * (180 / Math.PI)) * -1;

                record.layout.shape.rotate(degs);
                record.layout.move(coords[0][0] - record.layout.x, coords[0][1] - record.layout.y);
            });

            originalRotation = this.state.rotation;
        };

        const onUp = () => {
            //undoManager.stopGroup();
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onUp);

            this.buildBoundingBox();//rebuild ortho - this ensures consistent behavior, but could be annoying to have rotation handle move
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
        //});
    };

    setRotation = (startVector: Point, e: any) => {
        const getAngle = ({ x: x1, y: y1 }: Point, { x: x2, y: y2 }: Point) => {
            const dot = x1 * x2 + y1 * y2;
            const det = x1 * y2 - y1 * x2;
            const angle = Math.atan2(det, dot) / Math.PI * 180;
            let angleDeg = (angle + 360) % 360
            if (e.shiftKey) {
                angleDeg = Math.round(angleDeg / 45) * 45;
            }
            return angleDeg;
        };

        const center = {
            x: this.state.x + this.state.width / 2,
            y: this.state.y + this.state.height / 2
        };

        const convertedPt = convertPts({ x: e.clientX, y: e.clientY });

        const rotateVector = {
            x: convertedPt.x - center.x,
            y: convertedPt.y - center.y
        };

        this.setState({
            rotation: getAngle(startVector, rotateVector)
        })
    };

    arrowHandleEnter = (e: any) => {
        e.target.setAttribute('r', `${(this.arrowHandleSize + 2) / this.context.stage.scale}`);
    };

    arrowHandleLeave = (e: any) => {
        e.target.setAttribute('r', `${this.arrowHandleSize / this.context.stage.scale}`);
    };

    dragConnection = (e: any) => {
        e.stopPropagation();

        this.setState({
            arrow: true
        });

        const onUp = () => {
            const { arrows, records } = this.context;
            document.removeEventListener('mouseup', onUp);

            this.setState({
                arrow: false
            });

            const activeRecord = records.active[0];
            if (activeRecord) {
                arrows.create(records.selected[0], activeRecord);
            }
        };

        document.addEventListener('mouseup', onUp);
    };

    render() {
        const { records, stage, ui } = this.context;

        if (records.selected.length < 1) return null;

        const padding = 4;

        let handleSize = this.handleSize / stage.scale;
        handleSize = handleSize > 2 ? handleSize : 2;

        let arrowHandleSize = this.arrowHandleSize / stage.scale;
        arrowHandleSize = arrowHandleSize > 2 ? arrowHandleSize : 2;

        let inverseScale = 1 / stage.matrix.a;
        const rotationHandleSize = {
            width: 25 * inverseScale,
            height: 25 * inverseScale,
            x: 15 * inverseScale,
            y: -15 * inverseScale,
        };

        const handleOffset = handleSize * 2.5;
        const polyHandleOpacity = 0.65;
        const unusedHandleOpacity = 0.15;

        const showPolyHandles = ui.layout === layout.PLAN && records.selected.length === 1 && Math.min(this.state.width, this.state.height) > handleOffset * 5;

        const record = records.selected[0] as Record;
        const _shape = record.layout.shape;
        const showOrthoHandles = _shape.type === shapeType.RECTANGLE || (_shape as Polygon).controlPointType === control.ORTHO;

        const polyHandleX = (dir: string, xPos: number) => {
            const { controlPoints } = (_shape as Polygon);
            if (controlPoints && controlPoints.has(dir)) {
                return this.state.width * controlPoints.get(dir)!.x;
            }
            return this.state.width * xPos + handleOffset * (1 - 2 * xPos)
        };
        const polyHandleY = (dir: string, yPos: number) => {
            const { controlPoints } = (_shape as Polygon);
            if (controlPoints && controlPoints.has(dir)) {
                return this.state.height * controlPoints.get(dir)!.y;
            }
            return this.state.height * yPos + handleOffset * (1 - 2 * yPos)
        };

        const handles = (dir: string, xPos: number, yPos: number) => {
            const handlePos = {
                x: this.state.width * xPos - handleSize / 2,
                y: this.state.height * yPos - handleSize / 2,
            };

            handlePos.x += (xPos - 0.5) * handleSize;
            handlePos.y += (yPos - 0.5) * handleSize;
            const key = `${dir}-${xPos}-${yPos}`;
            return (
                <>
                    <Handle
                        key={key}
                        className={dir}
                        mouseDown={(e: any) => this.resizeShape(e, dir)}
                        // mouseUp={this.cancelResize}
                        x={handlePos.x}
                        y={handlePos.y}
                        size={handleSize}
                    />

                    {showOrthoHandles && showPolyHandles && (
                        <Handle
                            key={`ortho_${key}`}
                            className={dir}
                            shape={'circle'}
                            opacity={polyHandleOpacity}
                            mouseDown={(e: any) => this.resizePolyCorner(e, dir)}
                            // mouseUp={this.cancelResize}
                            x={polyHandleX(dir, xPos)}
                            y={polyHandleY(dir, yPos)}
                            size={handleSize}
                        />
                    )}
                </>
            );
        };

        const renderInBetweenHandles = () => {
            if (_shape.type !== shapeType.POLYGON) return null;

            const polygon = _shape as Polygon;
            const handlePositions: HandlePosition[] = [];

            const vertices: IdPoint[] = polygon.sizedVertices.map((v) => {
                return {
                    id: v.link,
                    x: v.x - handleSize / 2,
                    y: v.y - handleSize / 2,
                }
            });

            vertices.forEach((v: IdPoint, i: number) => {
                handlePositions.push({
                    id: v.id || `temp_v_${i}`,
                    opacity: polygon.controlPointType === control.ORTHO ? unusedHandleOpacity : polyHandleOpacity,
                    x: v.x,
                    y: v.y,
                });

                let nextHandle = i + 1;
                if (nextHandle >= vertices.length) {
                    nextHandle = 0; // wrap
                }

                const handlePosition: HandlePosition = {
                    id: 'mid_' + i,//TEMPORARY ID. Actual Id is created in insertControlPoint and set in grab function below
                    grab: (shape, x, y) => {
                        const poly = shape as Polygon;
                        if (poly) {
                            const cp = poly.insertControlPoint(i + 1, x, y);
                            handlePosition.id = cp.id;
                        }
                    },
                    opacity: unusedHandleOpacity,
                    x: (v.x + vertices[nextHandle].x) / 2,
                    y: (v.y + vertices[nextHandle].y) / 2,
                };

                handlePositions.push(handlePosition);
            });

            return (
                <>
                    {handlePositions.map((h) =>
                        <Handle
                            key={h.id}
                            className={'mid'}
                            shape={'square'}
                            opacity={h.opacity}
                            mouseDown={(e: any) => this.movePolyHandle(e, h)}
                            // mouseUp={this.cancelResize}
                            x={h.x}
                            y={h.y}
                            size={handleSize}/>
                    )}
                </>
            );
        }

        const renderHandles = () => {
            return <>
                {showPolyHandles && renderInBetweenHandles()}
                {handles('nw', 0, 0)}
                {handles('ne', 1, 0)}
                {handles('sw', 0, 1)}
                {handles('se', 1, 1)}
            </>
        }

        const primaryElement = records.selected[0];

        return (
            <g className={"SelectionBox"}>
                {this.state.arrow &&
                    <DrawArrow
                        x1={primaryElement.layout.shape.center.x}
                        y1={primaryElement.layout.shape.center.y}
                        x2={stage.mouse.x}
                        y2={stage.mouse.y}
                    />}

                <g transform={`translate(${this.state.x}, ${this.state.y})`}>
                    <g transform={`rotate(${this.state.rotation}, ${this.state.width / 2}, ${this.state.height / 2}) ${(ui.layout === layout.PLAN) ? `translate(0,0)` : ''}`}>
                        <line x1={-padding} y1={-padding} x2={this.state.width + padding} y2={-padding} stroke="blue"
                              strokeDasharray="4"/>
                        <line x1={-padding} y1={-padding} x2={-padding} y2={this.state.height + padding} stroke="blue"
                              strokeDasharray="4"/>
                        <line x1={this.state.width + padding} y1={-padding} x2={this.state.width + padding}
                              y2={this.state.height + padding} stroke="blue" strokeDasharray="4"/>
                        <line x1={-padding} y1={this.state.height + padding} x2={this.state.width + padding}
                              y2={this.state.height + padding} stroke="blue" strokeDasharray="4"/>

                        {/* Rotate handle*/}
                        {ui.layout === layout.PLAN && (
                            <g transform={`translate(${this.state.width + rotationHandleSize.x},${rotationHandleSize.y < -15 ? rotationHandleSize.y : -15}) scale(${inverseScale})`}
                               onMouseDown={this.handleRotation}
                            >
                                <g transform={`translate(-8,-6)`}>
                                    <BsArrow90DegLeft/>
                                </g>
                                <circle fill={'rgba(0,0,0,0.01)'} r={12}/>
                            </g>
                        )}

                        {renderHandles()}

                        {ui.layout === layout.ADJACENCY && records.selected.length === 1 && (
                            <g>
                                <defs>
                                    <filter id="shadow">
                                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.6"/>
                                    </filter>
                                </defs>

                                <circle
                                    className={"arrowHandle"}
                                    r={arrowHandleSize}
                                    fill={"#441cc1"}
                                    cx={this.state.width / 2}
                                    cy={-(arrowHandleSize + 10)}
                                    onMouseEnter={this.arrowHandleEnter}
                                    onMouseLeave={this.arrowHandleLeave}
                                    onMouseDown={this.dragConnection}
                                />

                                <circle
                                    className={"arrowHandle"}
                                    r={arrowHandleSize}
                                    fill={"#441cc1"}
                                    cx={this.state.width + arrowHandleSize + 10}
                                    cy={this.state.height / 2}
                                    onMouseEnter={this.arrowHandleEnter}
                                    onMouseLeave={this.arrowHandleLeave}
                                    onMouseDown={this.dragConnection}
                                />

                                <circle
                                    className={"arrowHandle"}
                                    r={arrowHandleSize}
                                    fill={"#441cc1"}
                                    cx={this.state.width / 2}
                                    cy={this.state.height + arrowHandleSize + 10}
                                    onMouseEnter={this.arrowHandleEnter}
                                    onMouseLeave={this.arrowHandleLeave}
                                    onMouseDown={this.dragConnection}
                                />

                                <circle
                                    className={"arrowHandle"}
                                    r={arrowHandleSize}
                                    fill={"#441cc1"}
                                    cx={-(arrowHandleSize + 10)}
                                    cy={this.state.height / 2}
                                    onMouseEnter={this.arrowHandleEnter}
                                    onMouseLeave={this.arrowHandleLeave}
                                    onMouseDown={this.dragConnection}
                                />
                            </g>
                        )}
                    </g>
                </g>
            </g>
        );
    }
}

export default SelectionBox;
