import { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';

import Arrow from "./Arrow";
import Shape from "./Shape";
import DrawRect from "./DrawRect";
import SelectionBox from "./SelectionBox";
import MarqueeTool from "./MarqueeTool";
import StageStore, { tool } from '../../stores/StageStore';
import { RemoteUser } from "./RemoteUser";
import RecordsStore from "../../stores/RecordsStore";
import ArrowsStore from "../../stores/ArrowsStore";
import SuperModel from "../../stores/SuperModelStore";
import UIStore from "../../stores/UIStore";
import { getDataUrlForImage } from "../../helpers/SvgImageDownloader";

type EmbeddedDataImageProps = {
    href: string;
    x: string;
    y: string;
};
const EmbeddedDataImage = ({ href, x, y }: EmbeddedDataImageProps) => {
    const [dataUrl, setDataUrl] = useState('');

    useEffect(() => {
        if (!dataUrl) {
            getDataUrlForImage(href, (d)=> {
                setDataUrl(d);
            });
        }
    }, []);

    let imageUrl = dataUrl || href;
    return <image href={imageUrl}
                  x={x}
                  y={y}
    />
}

type PlanViewProps = {
    exportMode?: boolean;
};

const PlanView = observer(({ exportMode = false }: PlanViewProps) => {
    //NOTE: temporary solution until we find a good way to provide strongly typed stores using useStores
    const records = useStores().records as RecordsStore;
    const arrows = useStores().arrows as ArrowsStore;
    const stage = useStores().stage as StageStore;
    const ui = useStores().ui as UIStore;
    const supermodel = useStores().supermodel as SuperModel;

    useEffect(() => {
        records.unplaced.forEach((record, i) => {
            record.layout.position.reset();
            record.layout.move(i * 50 + i * 10, 0)
        });
    }, [])

    const { image } = stage;

    let imageW = 0;
    let imageH = 0;
    if (image.size && image.size.length === 2) {
        imageW = image.size[0] * image.scale;
        imageH = image.size[1] * image.scale;
    }
    if (exportMode) {
        return (
            <g>
                {image.url &&
                    <g transform={`translate(${-imageW / 2} ${-imageH / 2}) scale(${image.scale})`}>
                        <EmbeddedDataImage href={image.url}
                                           x="500"
                                           y="300"
                        />
                    </g>
                }
                {records.sortedBySize.map((el) =>
                    <Shape
                        key={el.id}
                        // @ts-ignore
                        exportMode={exportMode}
                        record={el}
                    />
                )}
                {stage.showArrow && arrows.all.map((el) =>
                    <Arrow
                        key={`${el.id}${String(stage.scale)}`}
                        // @ts-ignore
                        exportMode={exportMode}
                        arrow={el}
                    />
                )}
            </g>
        );
    }

    return (
        <g>
            {image.url &&
                <g transform={`translate(${-imageW / 2} ${-imageH / 2}) scale(${image.scale})`}>
                    <image href={image.url}
                           x="500"
                           y="300"
                    />
                </g>
            }

            {stage.tool === tool.SCALE && stage.toolData.map(({ x, y }) => (
                <circle
                    key={String(x) + String(y)}
                    cx={x}
                    cy={y}
                    r={5}
                    fill={'red'}
                />
            ))}

            {records.sortedBySize.map((el) =>
                <Shape
                    key={el.id}
                    // @ts-ignore
                    record={el}
                />
            )}

            {stage.showArrow && arrows.all.map((el) =>
                <Arrow
                    key={`${el.id}${String(stage.scale)}`}
                    // @ts-ignore
                    arrow={el}
                />
            )}

            {records.selected.length && !stage.dragging && !stage.resizing &&
                <SelectionBox/>
            }

            {stage.tool === tool.SHAPE && stage.drawing &&
                <DrawRect/>
            }

            {stage.marqueeSelect &&
                <MarqueeTool/>
            }

            {ui.showCollaboratorsCursors &&
                <g>
                    {supermodel.userStore.planViewUsers.map(user => <RemoteUser key={user.uid} user={user}
                                                                                stroke={'#ffffff'}/>)}
                </g>
            }
        </g>
    );
});

export default PlanView;
