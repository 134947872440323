//@ts-nocheck
import {Component} from 'react';
import {observer} from "mobx-react";
import { StoresContext } from '@strategies/stores';

import {distance, stageToLinear} from "../../utils";


@observer
class DrawCircle extends Component {

    static contextType = StoresContext;

    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            originalPosition: {x: 0, y: 0}
        };
    }

    componentDidMount = () => {
        const { x, y } = this.context.stage.clickPosition;

        this.setState({
            originalPosition: { x, y },
        });
    };

    componentWillUnmount = () => {
        const { history, records, stage } = this.context;
        const { originalPosition } = this.state;
        const { mouse } = stage;

        const radius = distance(originalPosition, mouse);

        history.withGroup(() => {
            const record = records.create();

            record.layout.move(originalPosition.x, originalPosition.y);
            record.layout.shape.setRadius(radius);

            const r = stageToLinear(radius);

            record.setTargetNet(Math.round(Math.PI * r * r), true);
            record.layout.place();
        }, 'create circle');
    };

    render() {
        const { mouse } = this.context.stage; 
        const { originalPosition } = this.state;
        const radius = distance(originalPosition, mouse);
        const r = stageToLinear(radius);
        const area = Math.PI * r * r;

        return (
            <g className={"drawCircle"}
               transform={`translate(${originalPosition.x}, ${originalPosition.y})`}>
                <circle
                    stroke={"black"}
                    strokeDasharray={"4"}
                    fill={"transparent"}
                    r={radius}
                />
                <text textAnchor="middle">{Math.round(area)}</text>
            </g>
        );
    }
}

export default DrawCircle;
