import { useEffect } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';


type DrawArrowProps = {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
};

export default observer(function DrawArrow({ x1, x2, y1, y2 }: DrawArrowProps) {
    const { history } = useStores();

    useEffect(() => {
        history.startGroup('draw arrow');
        return () => history.stopGroup();
    }, [history]);

    return (
        <g>
            <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7"
                        refX="10" refY="3.5" orient="auto">
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>
            </defs>
            <path className="DrawArrow"
                  markerEnd="url(#arrowhead)"
                  d={`M${x1} ${y1} L${x2} ${y2}`}
            />
            <circle
                className={"DrawArrowOrigin"}
                fill={"white"}
                cx={x1}
                cy={y1}
                r={5}
            />
            <circle
                className={"DrawArrowTarget"}
                fill={"white"}
                cx={x2}
                cy={y2}
                r={5}
            />
        </g>
    );
})
