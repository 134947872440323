import { observer } from 'mobx-react';
import { HexColorPicker } from "react-colorful";


type PickColorsProps = {
    color: string;
    setColor: (value: string) => void
};


export const PickColors = observer(({color, setColor}: PickColorsProps) => {
    return <HexColorPicker className={'PickColorful'} color={color} onChange={setColor} />
});
export default PickColors;