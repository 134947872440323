import { useCallback, useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { stores, useStores } from '@strategies/stores';
import { Modal, Body } from '@strategies/ui';
import { FiPlus, FiArrowLeft } from 'react-icons/fi';

import versions from '../../versions';
import Logo from '../../assets/PROGRAMME-01-01.png';
import SuperModel from "../../stores/SuperModelStore";
import { filenameWithoutExtension } from "../../utils";
import UndoStore from "../../stores/UndoStore";
import File from "../../models/File";
import useDrivePicker from 'react-google-drive-picker'
import config from "../../config";
import UIStore from "../../stores/UIStore";

enum PendingActions {
    None,
    CreateNew,
    CreateFromPrgm,
}

const PickerButton = () => {
    const [openPicker, authResponse] = useDrivePicker();
    const ui = useStores().ui as UIStore;

    const onPick = async (fileId: string, fileName: string) => {
        const supermodel = stores.supermodel as SuperModel;
        const ui = stores.ui as UIStore;
        await supermodel.appStateManager?.openFromDrive(fileId, filenameWithoutExtension(fileName));
        ui.setGooglePickerActive(false);
    }

    const handleOpenPicker = () => {
        console.log('ui.setGooglePickerActive(true)');
        openPicker({
            clientId: config.clientId,
            developerKey: config.apiKey,
            appId: config.appId,
            viewId: "DOCS",
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
            callbackFunction: (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/' +
                        'close button')
                }
                console.log(data);
                if (data.action === 'loaded') {
                    ui.setGooglePickerActive(true);
                } else if (data.action === 'picked') {
                    let doc = data.docs[0];
                    onPick(doc.id, doc.name);
                } else {
                    if (data.action === 'cancel') {
                        console.log('ui.setGooglePickerActive(false)');
                        ui.setGooglePickerActive(false);
                    }
                }
            },
        })
    };
    return <button onClick={() => handleOpenPicker()}>
        <div>
            <FiPlus/>
            Open Google Drive
        </div>
    </button>
};

export default observer(function IntroModal() {
    const { app, ui } = useStores();
    const [openExisting, setOpenExisting] = useState(false);
    const [actionPending, setActionPending] = useState(PendingActions.None);
    const supermodel = useStores().supermodel as SuperModel;
    const history = useStores().history as UndoStore<File>;

    const { file } = supermodel;

    useEffect(() => {
        if (ui.introModal) {
            setOpenExisting(false);
        }
    }, [ui.introModal]);

    const close = useCallback(() => {
        ui.setForceIntroModal(false);
    }, [ui]);

    const createNew = () => {
        const { appStateManager, file } = supermodel;

        setActionPending(PendingActions.CreateNew);
        appStateManager?.createNewFile().then(() => {
            setActionPending(PendingActions.None);
            history.reset();
            close();
        })
    }

    const onFileUpload = useCallback((e) => {
        setActionPending(PendingActions.CreateFromPrgm);
        const _file = e.target.files[0];
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');

        reader.onload = async () => {
            const fileJSON = JSON.parse(reader.result as any);
            if (fileJSON) {
                const upgradedFile = versions.upgrade(fileJSON);
                await supermodel.appStateManager?.createFromSnapshot(upgradedFile, filenameWithoutExtension(_file.name))
                setActionPending(PendingActions.None);
                history.reset();
                close();
            }
        };
        reader.readAsBinaryString(_file);
    }, [file, close, actionPending]);

    return (
        <Modal className={`IntroModal${actionPending !== PendingActions.None ? ' action-pending' : ''}`}
               active={ui.introModal} onClose={() => ui.setForceIntroModal(false)}>
            <Body>
                <div className="logo">
                    <img src={Logo} alt="Programme Logo"/>
                </div>

                <p>
                    This product allows you to create different phases of your programming process by assigning
                    relationships and sketching in plan. Get started below.
                </p>

                <div className="buttons">
                    {openExisting ? <>
                        <h2>
                            <button onClick={() => setOpenExisting(false)}>
                                <FiArrowLeft/> Open Existing
                            </button>
                        </h2>
                        <ul>
                            <li>
                                <PickerButton/>
                            </li>
                            <li>
                                <label htmlFor="fileInput">
                                    <div>
                                        <FiPlus/>
                                        {(actionPending === PendingActions.CreateFromPrgm) ? 'creating please wait...' : 'Load PRGM File'}
                                    </div>
                                </label>
                                <input className={'button-like'}
                                       disabled={actionPending === PendingActions.CreateFromPrgm} id="fileInput"
                                       type="file" accept=".prgm" onChange={onFileUpload}/>
                            </li>
                        </ul>
                    </> : <>
                        <h2>Get Started</h2>
                        <ul>
                            <li>
                                <button disabled={actionPending === PendingActions.CreateNew} onClick={createNew}>
                                    <div>
                                        <FiPlus/>
                                        {(actionPending === PendingActions.CreateNew) ? 'creating please wait...' : 'Create New'}
                                    </div>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => setOpenExisting(true)}>
                                    <div>
                                        <FiPlus/>
                                        Open Existing
                                    </div>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => {
                                    ui.setSpreadsheetPasteModal(true)
                                }}>
                                    <div>
                                        <FiPlus/>
                                        Generate from Spreadsheet
                                    </div>
                                </button>
                            </li>
                            <li>
                                <a className={'button-like'}
                                   href={`https://programming-dashboard.web.app${file.fileId ? `/?file=${file.fileId}` : ''}`}
                                   rel="noopener noreferrer" target="_blank">
                                    <div>
                                        <FiPlus/>
                                        Import from Programming Dashboard
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </>}
                </div>

                <div className="whats-new">
                    <h3>WHAT'S NEW</h3>
                    <p>
                        Share your file with other collaborators and see each other working in real-time.
                    </p>
                </div>

                <div>

                </div>
            </Body>
        </Modal>
    );
});
