import { observer } from 'mobx-react';
import { ReactLogin } from '@strategies/react-login';
import { useStores } from '@strategies/stores'
import logo from '../../assets/PROGRAMME-03-01.png';
import ScreenOne from '../../assets/ASSET_01@2x.png'
import ScreenTwo from '../../assets/ASSET_02@2x.png'
import ScreenThree from '../../assets/ASSET_03@2x.png'
import ScreenFour from '../../assets/ASSET_04@2x.png'
import firebaseConfig from "../../config";
import User from "../../models/User";

export default observer(function Login() {
    const { supermodel } = useStores();

    return (
        <ReactLogin
            config={firebaseConfig}
            emailEnabled={false}
            contactLink={"https://sasakistrategies.typeform.com/to/wAR0PxmB"}
            logo={logo}
            title={"Spatial Programming"}

            screens={[
                { src: ScreenOne, alt: '', },
                { src: ScreenTwo, alt: '', },
                { src: ScreenFour, alt: '', },
                { src: ScreenThree, alt: '', },
            ]}
            whitelist={[]}

            onChange={(user) => {
                supermodel.login(user)
            }}
        />
    );
});
