import { useEffect } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import ReactTooltip from 'react-tooltip';
import { IconButton } from '@strategies/ui';
import { 
    FiExternalLink, 
    FiImage, 
    FiPlus, 
    FiMinus, 
    FiMaximize2, 
    FiRotateCcw, 
    FiRotateCw, 
    FiCircle,
    FiSquare 
} from 'react-icons/fi';
import { BsArrowLeftRight } from 'react-icons/bs';

import { layout } from '../../models/Layout';
import { tool } from '../../stores/StageStore';


export default observer(function Toolbar() {
    const { history, stage, ui } = useStores();

    useEffect(() => ReactTooltip.rebuild());

    return (
        <div className="Toolbar">
            <IconButton
                data-tip="Zoom In"
                data-place="right"
                onClick={() => stage.zoomIn()}
            >
                <FiPlus />
            </IconButton>

            <IconButton
                data-tip="Zoom Out"
                data-place="right"
                onClick={() => stage.zoomOut()}
            >
                <FiMinus />
            </IconButton>

            <IconButton 
                data-tip="Zoom Extents" 
                data-place="right" 
                onClick={() => stage.fitBounds(1.1)}
            >
                <FiMaximize2 />
            </IconButton>

            <IconButton 
                data-tip="Draw Shape (m)" 
                data-place="right"
                active={stage.tool === tool.SHAPE}
                onClick={() => stage.setTool(stage.tool !== tool.SHAPE ? tool.SHAPE : tool.PAN)}
            >
                {ui.layout === layout.PLAN ? <FiSquare/> : <FiCircle/>}
            </IconButton>

            {ui.layout === layout.PLAN && <>
                <IconButton 
                    data-tip={`${stage.showArrow ? 'Hide' : 'Show'} Arrows`}
                    data-place="right"
                    onClick={() => stage.setShowArrow(!stage.showArrow)}
                    active={stage.showArrow}
                >
                    <BsArrowLeftRight />
                </IconButton>

                <IconButton 
                    data-tip="Upload Base Image" 
                    data-place="right"
                    onClick={() => ui.setLoadImageModal(true)}
                >
                    <FiImage />
                </IconButton>

                <IconButton 
                    disabled={!stage.hasBackgroundImage}
                    data-tip="Scale Base Image" 
                    data-place="right"
                    active={stage.tool === tool.SCALE}
                    onClick={() => { stage.setTool(tool.SCALE); ui.setScaleImageModal(true) }}
                >
                    <FiExternalLink />
                </IconButton>
            </>}

            <IconButton 
                data-tip="Undo" 
                data-place="right" 
                onClick={() => history.undo()}
                disabled={!history.canUndo}
            >
                <FiRotateCcw/>
            </IconButton>

            <IconButton 
                data-tip="Redo" 
                data-place="right" 
                onClick={() => history.redo()}
                disabled={!history.canRedo}
            >
                <FiRotateCw/>
            </IconButton>
        </div>
    );
});
