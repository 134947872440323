import { Model, model, modelAction, prop } from 'mobx-keystone';


@model('pt/ExportOptions')
class ExportOptions extends Model({
    includeLegend: prop<boolean>(true).withSetter(),
    nameScale: prop<number>(1).withSetter(),
    areaScale: prop<number>(1).withSetter(),
}) {

}


export default ExportOptions;
