import chroma from "chroma-js";

import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Input, Group } from '@strategies/react-form'
import { Body, Button, Buttons, Modal, Title, SubTitle } from '@strategies/ui';

import Category from '../../models/Category';
import { useHistoryGroup } from '../../utils';
import { LabelCategory } from "./LabelCategory";
import { PickColors } from '../PickColors/PickColors'


export const EditCategory = observer(() => {
        const { history, ui, categories } = useStores();

        useHistoryGroup(!!ui.editCategory, 'edit category');

        const close = useCallback(() => {
            ui.setEditCategory(undefined);
        }, [ui]);

        const saveDescription = useCallback((d: string|number) => {
            if (typeof d !== "string") return;
            ui.editCategory.setDescription(d)
        }, [ui]);

        const saveName = useCallback((n: string|number) => {
            if (typeof n !== "string") return;
            ui.editCategory.setName(n);
        }, [ui]);

        const changeColor = useCallback((c: string|number) => {
            if (typeof c !== "string") return;
            ui.editCategory.setColor(c);
        }, [ui]);

        const cancel = useCallback(() => {
            history.cancelGroup();
            close();
        }, [history, close])

        return (
            <Modal active={!!ui.editCategory} onClose={close} className="EditCategory">
                <Title>Category Editor</Title>
                <SubTitle>Changes you make here will affect all category tags listed below</SubTitle>
                <Body>
                    {
                        !!ui.editCategory && <>
                            <Group name="CATEGORY">
                                <LabelCategory
                                    color={ui.editCategory.color}
                                >
                                    {ui.editCategory.name}
                                </LabelCategory>

                            </Group>
                            <Group name="">
                                <Input
                                    name=""
                                    placeholder="NAME"
                                    value={ui.editCategory.name}
                                    onChange={saveName}
                                />

                            </Group>
                            <Group>
                                <Input
                                    name="DESCRIPTION"
                                    placeholder="DESCRIPTION"
                                    value={ui.editCategory.description}
                                    onChange={saveDescription}
                                />
                            </Group>
                            <Group name="COLOR">
                                <div
                                    className="pick-colors"
                                >

                                    <PickColors color={ui.editCategory.color}
                                                setColor={changeColor}
                                    />
                                    <Input
                                        left="#"
                                        placeholder={ui.editCategory.color}
                                        value={ui.editCategory.color.replace("#", "")}
                                        onChange={(c) => {
                                            if (typeof c !== "string") return;
                                            if (!chroma.valid(c)) {
                                                return null
                                            }
                                            ui.editCategory.setColor(c);
                                        }}
                                    />
                                </div>
                            </Group>
                        </>
                    }
                </Body>
                <Buttons>
                    <Button className="delete secondary" onClick={() => {
                        categories.remove(ui.editCategory);
                        close();
                    }}>
                        Delete
                    </Button>
                    <Button className="secondary" onClick={cancel}>
                        Cancel
                    </Button>

                    <Button autoFocus={true} onClick={close}>
                        Save
                    </Button>
                </Buttons>
            </Modal>
        )
    }
)
