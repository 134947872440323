import { Store, stores } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';

import Record from '../models/Record';
import ImageHandler from '../helpers/ImageHandler';

export default class AppStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    imageHandler: ImageHandler = new ImageHandler();

    @observable
    filterBy: string = '';

    @action
    setFilterBy(filterBy: string = '') {
        this.filterBy = filterBy;
    }

    @computed
    get isFiltering() {
        return this.filterBy !== '';
    }

    @observable
    gridApi: any = '';

    @action
    setGridApi(gridApi: any) {
        this.gridApi = gridApi;
    }

    @observable
    editMode: boolean = false;

    @action
    setEditMode(editMode: boolean = true) {
        this.editMode = editMode;
    }

    @observable
    inPresentationMode: boolean = false;

    @action
    setPresentationMode(inPresentationMode: boolean = true) {
        this.inPresentationMode = inPresentationMode;
    }

    @computed
    get totalTargetSquareUnits() {
        return stores.records.all.reduce((total: number, r: Record) => total + r.targetGrossUnits, 0);
    }

    @computed
    get totalActualSquareUnits() {
        return stores.records.all.reduce((total: number, r: Record) => total + r.squareUnits, 0);
    }

    @computed
    get totalNetSquareUnits() {
        return stores.records.all.reduce((total: number, r: Record) => total + r.targetNetUnits, 0);
    }

    @computed
    get totalCost() {
        return stores.records.all.reduce((a: number, b: Record) => a + b.cost, 0);
    }

    @computed
    get writeInProgress() {
        return false;//TODO this was being used by the old toolbar - but the design has changed? Remove this after updating toolbar
    }

}
