import { Store, stores } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';
import { zoomOnViewerCenter } from 'react-svg-pan-zoom';

import Position from '../models/Position';
import Record from '../models/Record';
import Image from '../models/Image';
import { BoundingBox } from '../models/Shape';
import { combineBBoxes, inflateRect } from "../utils";
import TransformMatrix, { ITransformMatrix } from '../models/TransformMatrix';


export enum tool {
    ARROW = 'arrow',
    PAN = 'pan',
    SCALE = 'scale',
    SHAPE = 'shape',
    NONE = 'none',
};


export default class StageStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    clickPosition: Position = new Position({});

    @computed
    get svg(): SVGElement|null {
        return this.viewer ? this.viewer.ViewerDOM : null;
    }

    @observable
    viewer: any = null;

    @action
    setViewer(viewer: any) {
        this.viewer = viewer;
    }

    @observable
    dragging: boolean = false;

    @action
    setDragging(dragging: boolean = true) {
        this.dragging = dragging;
    }

    @observable
    drawing: boolean = false;

    @action
    setDrawing(drawing: boolean = true) {
        this.drawing = drawing;
    }

    @observable
    tool: tool = tool.PAN;

    @action
    setTool(tool: tool) {
        this.tool = tool;
    }

    @observable
    toolData: any[] = [];

    @action
    clearToolData() {
        this.toolData = observable([]);
    }

    @action
    addToolData(data: any) {
        this.toolData.push(data);
    }

    @observable
    showArrow: boolean = true;

    @action
    setShowArrow(showArrow: boolean = true) {
        this.showArrow = showArrow;
    }

    @computed
    get image(): Image {
        return stores.supermodel.file.image;
    }

    setImage(name: string, blob: string, dims: number[][]) {
        stores.supermodel.file.setImage(name, blob, dims);
    }

    @observable
    marqueeSelect: boolean = false;

    @action
    setMarqueeSelect(select: boolean = true) {
        this.marqueeSelect = select;
    }

    @computed
    get matrix(): TransformMatrix {
        return stores.supermodel.users.current.stage;
    }

    setMatrix(matrix: ITransformMatrix) {
        stores.supermodel.users.current.setStageMatrix(matrix);
    }

    @computed
    get scale() {
        return Math.sqrt(Math.pow(this.matrix.a, 2) + Math.pow(this.matrix.b, 2));
    }

    @computed
    get mouse() {
        return stores.supermodel.users.current.mouse;
    }

    @computed
    get hasBackgroundImage() {
        return this.image.name !== '' && this.image.url !== '';
    }

    @computed
    get hasScaleCoords() {
        return this.tool === tool.SCALE && this.toolData.length >= 2;
    }
    
    zoomIn() {
        this._zoom(1.25);
    }

    zoomOut() {
        this._zoom(.75);
    }

    private _zoom(scale: number) {
        this.setMatrix(zoomOnViewerCenter(this.matrix, scale));
    }

    fitBounds(inflateBy: number = 1) {
        if (this.bounds.width === 0) return;

        const {viewableArea, height: clientHeight, width: clientWidth} = stores.ui;

        const adjusted = {
            x: this.bounds.x,
            y: this.bounds.y,
            width: this.bounds.width,
            height: this.bounds.height,
        };

        //first, match and center aspect
        if (this.bounds.width / this.bounds.height > viewableArea.width / viewableArea.height) {
            adjusted.height = this.bounds.width * viewableArea.height / viewableArea.width;
            adjusted.y = this.bounds.y - (adjusted.height - this.bounds.height) / 2;
        } else {
            adjusted.width = this.bounds.height * viewableArea.width / viewableArea.height;
            adjusted.x = this.bounds.x - (adjusted.width - this.bounds.width) / 2;
        }

        //second, add additional space to the bounding box to account for the tool panels
        adjusted.width = adjusted.width / (viewableArea.width / clientWidth);
        adjusted.height = adjusted.height / (viewableArea.height / clientHeight);

        adjusted.x -= (viewableArea.left / viewableArea.width) * adjusted.width / 2;
        adjusted.y -= (viewableArea.top / viewableArea.height) * adjusted.height / 2;

        const {x, y, width, height} = inflateRect(adjusted, inflateBy);

        this.viewer.fitSelection(x, y, width, height);
    }

    @computed
    get bounds(): BoundingBox {
        const bboxes: any[] = [];

        stores.records.all.forEach((record: Record) => {
            bboxes.push(record.layout.boundingBox);
        });

        if (bboxes.length === 0) {
            return {x: 0, y: 0, width: 0, height: 0}
        }

        return combineBBoxes(bboxes);
    }


}
