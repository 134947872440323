import { Store, stores } from '@strategies/stores';
import { action, computed, makeObservable, observable } from 'mobx';

import Arrow from '../models/Arrow';
import Record from '../models/Record';
import SuperModel from './SuperModelStore';


export default class RecordsStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @action
    create(): Record {
        return stores.supermodel.file.addRecord()
    }

    @action
    remove(record: Record) {
        const { supermodel: { file }, arrows, history } = stores;

        history.withGroup(() => {
            record.arrows.forEach((arrow: Arrow) => arrows.remove(arrow));
            file.removeRecord(record);
        }, 'remove record');
    }

    @action
    copy(record: Record): Record {
        return stores.supermodel.file.copyRecord(record);
    }

    @action
    clearSelected() {
        this.selected.forEach((record: Record) => record.setSelected(false));
    }

    @computed
    get byId() {
        return this.all.reduce((byId: {[key:string]: Record}, record: Record) => {
            byId[record.id] = record;
            return byId;
        }, {});
    }

    @computed
    get all(): Record[] {
        const supermodel = stores.supermodel as SuperModel;
        return supermodel.file.records;
    }

    @computed
    get unplaced() {
        return this.all.filter((record: Record) => !record.layout.placed);
    }

    @computed
    get active() {
        return this.all.filter((record: Record) => record.active);
    }

    @computed
    get sortedBySize() {
        return this.all.slice().sort((a: Record, b: Record) => {
            const ans = b.layout.perimeter - a.layout.perimeter;
            if (ans === 0) {
                return a.id > b.id ? -1 : 1;
            }
            return ans;
        });
    }

    @computed
    get selected() {
        return this.all.filter((record: Record) => record.selected);
    }

    @computed
    get selectedUnderTarget() {
        return this.selected.filter((record: Record) => record.isUnderTarget);
    }

    @computed
    get selectedAtTarget() {
        return this.selected.filter((record: Record) => record.isAtTarget);
    }

    @computed
    get selectedOverTarget() {
        return this.selected.filter((record: Record) => record.isOverTarget);
    }

    @computed
    get unselected() {
        return this.all.filter((record: Record) => !record.selected);
    }
}
