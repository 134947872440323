import { Component } from 'react';
import { observer } from "mobx-react";
import { GlobalHotKeys } from "react-hotkeys";
import { StoresContext, useStores } from '@strategies/stores';
import ReactTooltip from 'react-tooltip';

import Stage from "./components/Stage/Stage";
import DashboardView from "./components/Dashboard/DashboardView";

import Login from './components/Login/Login';
import MenuBar from "./components/MenuBar/MenuBar";
import Toolbar from "./components/Toolbar/Toolbar";
import Modal from "./components/Modal";
import IntroModal from "./components/IntroModal/IntroModal";


import './App.scss';
import ExportModal from "./components/ExportModal/ExportModal";
import SpreadsheetPaste from "./components/Table/SpreadsheetPaste";
import CopyModal from "./components/CopyModal";
import LoadImageModal from './components/LoadImageModal/LoadImageModal';
import ScaleImageModal from './components/ScaleImageModal/ScaleImageModal';
import Table from "./components/Table/Table";
import StageStore, { tool } from './stores/StageStore';
import RecordsStore from "./stores/RecordsStore";
import InfoModal from "./components/InfoModal";
import GenerateFromTable from "./components/GenerateFromTable/GenerateFromTable";
import ActiveUsers from "./components/ActiveUsers";
import { EditCategory } from "./components/EditCategory/EditCategory";
import ProjectSettingsModal from "./components/ProjectSettingsModal/ProjectSettingsModal";
import UIStore from "./stores/UIStore";
import ExportPreview from "./components/ExportPreview/ExportPreview";

const TEN_SECONDS = 10 * 1000;
const TWENTY_SECONDS = 20 * 1000;


const DebugInfo = observer(() => {
    const { ui, supermodel } = useStores();
    const { file, users } = supermodel;

    const style: any = {
        position: 'absolute',
        color: 'blue',
        zIndex: 10,
        top: 0,
        left: 0,
    }
    return <div style={style}>
        <div>localUserId:{supermodel.userStore.localUserId}</div>
        <div>timeStamp:{supermodel.userStore.timeStamp}</div>
        <div>Logged in:{supermodel.loggedIn}</div>
    </div>;
});

@observer
class App extends Component {

    static contextType = StoresContext;
    private readonly keyMap: { [key: string]: string[] };
    timeUpdateInterval?: NodeJS.Timer;
    handlers?: {
        UNDO: () => void; //undoManager.canUndo && undoManager.undo(),
        REDO: () => void; //undoManager.canRedo && undoManager.redo(),
        PASTE: (e: any) => any; COPY: () => any; CUT: () => any; SWITCH_SHAPE: () => any; SWITCH_SELECT: () => any; DELETE: () => any; UNITS_MODE: () => any;
    };

    constructor(props: any) {
        super(props);

        this.keyMap = {
            UNDO: ["ctrl+z", "ctrl+Z"],
            REDO: ["ctrl+shift+z", "ctrl+shift+Z"],
            CUT: ["ctrl+x", "ctrl+X"],
            PASTE: ["ctrl+v", "ctrl+V"],
            COPY: ["ctrl+c", "ctrl+C"],
            SWITCH_SELECT: ["v", "V"],
            UNITS_MODE: ["u", "U"],
            SWITCH_SHAPE: ["l", "m", "L", "M"], // match Illustrator
            DELETE: ["del", "backspace"]
        };
    }

    componentDidMount() {
        const { file, users } = this.context.supermodel;

        this.timeUpdateInterval = setInterval(() => file.updateCurrentTime(), TEN_SECONDS);
        // this.activeUserPing = setInterval(() => users.current && users.current.updateLastActive(), TWENTY_SECONDS);

        const { clipboard, history, units } = this.context;


        const records = this.context.records as RecordsStore;
        const stage = this.context.stage as StageStore;
        const ui = this.context.ui as UIStore;

        this.handlers = {
            UNDO: () => history.canUndo && history.undo(),
            REDO: () => history.canRedo && history.redo(),
            PASTE: (e) => clipboard.paste(e),
            COPY: () => clipboard.copy(),
            CUT: () => clipboard.cut(),
            SWITCH_SHAPE: () => stage.setTool(tool.SHAPE),
            SWITCH_SELECT: () => stage.setTool(tool.PAN),
            DELETE: () => records.selected.forEach((record) => records.remove(record)),
            UNITS_MODE: () => units.toggleUnit(),
        };
    }

    componentWillUnmount() {
        this.timeUpdateInterval && clearInterval(this.timeUpdateInterval);
        // clearInterval(this.activeUserPing);
    }

    render() {
        const { ui, supermodel } = this.context;
        const { file } = supermodel;

        return (
            <div className="App">
                {/*<DebugInfo/>*/}
                {!supermodel.loggedIn ? (
                    <Login/>
                ) : (
                    <>
                        {/* @ts-ignore */}
                        <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}/>
                        <Stage id={'stage'} layout={ui.layout} exportMode={false}/>

                        <div className="Overlay">
                            <div className="controls-wrapper">
                                <MenuBar/>
                                <Toolbar/>
                            </div>

                            <Table />
                            <DashboardView />
                        </div>

                        <IntroModal/>

                        <InfoModal/>
                        <ProjectSettingsModal/>
                        <ExportModal/>
                        <ExportPreview/>
                        <LoadImageModal/>
                        <ScaleImageModal/>
                        <CopyModal/>
                        <EditCategory/>
                        <GenerateFromTable/>
                        <ActiveUsers/>
                        <ReactTooltip className="tooltip" />
                    </>
                )}
            </div>
        );
    }
}


export default App;
