import { observer } from 'mobx-react';

import Bar from './Bar';

type StackedBarProps = {
    label: string;
    value?: number;
    values: number|number[];
    total: number;
    format: (v: number) => string;
    ramp: string[];
};

export default observer(function StackedBar({ label, value = undefined, values, total, format, ramp }: StackedBarProps) {
    if (!Array.isArray(values)) {
        values = [values];
    }

    return (
        <div className="BarChart StackedBar">
            <label>{label}</label>

            <div className="BarChart__graphic">
                <Bar values={values} total={total} ramp={ramp} />
           </div>

            <div className="StackedBar__value">
                {format(value !== undefined ? value : values.reduce((a,b) => a + b, 0))}
            </div>
        </div>
    );
});
