import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { register } from '@strategies/stores';
import { registerRootStore } from 'mobx-keystone';

import './index.css';
import App from './App';

import File from './models/File';

import AppStore from './stores/AppStore';
import UIStore from './stores/UIStore';
import ArrowsStore from './stores/ArrowsStore';
import ClipboardStore from './stores/ClipboardStore';
import StageStore from './stores/StageStore';
import RecordsStore from './stores/RecordsStore';
import UnitsStore from './stores/UnitsStore';
import UndoStore from './stores/UndoStore';
import TableStore from './stores/TableStore';
import SuperModelStore from './stores/SuperModelStore';
import firebase from "firebase";
import firebaseConfig from "./config";
import CategoriesStore from "./stores/CategoriesStore";


/**
 * Register the stores to the shared state
 */

const supermodel = new SuperModelStore({});

firebase.initializeApp(firebaseConfig);

registerRootStore(supermodel);

register({

    // Keystone
    supermodel,

    // Pure MobX
    app: new AppStore(),
    ui: new UIStore(),
    stage: new StageStore(),
    arrows: new ArrowsStore(),
    records: new RecordsStore(),
    categories: new CategoriesStore(),
    units: new UnitsStore(),
    clipboard: new ClipboardStore(),
    history: new UndoStore<File>(supermodel.file),
    table: new TableStore(),

});


/*
 * Analytics
 */

if (window.location.host.split('.')[0] === 'staging') {
    ReactGA.initialize('UA-72138679-12');
}
else {
    ReactGA.initialize('UA-72138679-13');
}

ReactGA.pageview(window.location.pathname + window.location.search);

// Start

ReactDOM.render(<App />, document.getElementById('app-root'));
