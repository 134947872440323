import { stores } from '@strategies/stores';
import { action, computed, observable, values } from 'mobx';
import { Model, model, modelAction, prop, Ref, rootRef } from 'mobx-keystone';
import { compose, fromDefinition, toString } from 'transformation-matrix';
import { v4 } from 'node-uuid';

import Arrow from './Arrow';
import Category, { categoryRef } from './Category';
import Layout, { layout } from './Layout';
import { Circle, Rectangle, shape } from './Shape';
import { linearToStage, PLAN_SQ_FT_RATIO, stageToArea, stageToLinear } from "../utils";


type LayoutMap = { [key in layout]: Layout };

@model('pt/Record')
class Record extends Model({
    id: prop<string>(() => v4()),
    name: prop<string>('').withSetter(),
    categoryRef: prop<Ref<Category> | null>(null),
    targetGrossM: prop<number>(0),
    targetNetM: prop<number>(0),
    costPerSqM: prop<number>(2000),
    efficiencyFactor: prop<number>(80),
    comment: prop<string>(''),
    layouts: prop<LayoutMap>(() => ({
        [layout.PLAN]: new Layout({ shape: new Rectangle({}) }),
        [layout.ADJACENCY]: new Layout({ shape: new Circle({}) }),
    })),
}) {

    @modelAction
    regenerateId() {
        this.id = v4();
    }

    getRefId() {
        return this.id;
    }

    @computed
    get category() {
        return this.categoryRef ? this.categoryRef.maybeCurrent : undefined;
    }

    @observable
    svg: SVGGraphicsElement | null = null;

    @observable
    editingName: boolean = false;

    @action
    setEditingName(editingName: boolean = true) {
        if (editingName) {
            stores.history.startGroup('editing name');
        }
        else {
            stores.history.stopGroup();
        }

        this.editingName = editingName;
    }

    @observable
    editingText: boolean = false;

    @action
    setEditingText(editingText: boolean = true) {
        this.editingText = editingText;
    }

    @observable
    selected: boolean = false;

    @action
    setSelected(selected: boolean = true) {
        this.selected = selected;
    }

    @observable
    active: boolean = false;

    @action
    setActive(active: boolean = true) {
        this.active = active;
    }

    @computed
    get arrows(): Arrow[] {
        return stores.arrows.all.filter((arrow: Arrow) => arrow.target === this || arrow.origin === this);
    }

    @computed
    get cost() {
        return this.targetGrossM * this.costPerSqM;
    }

    @computed
    get layout() {
        return this.layouts[stores.ui.layout as layout];
    }

    @computed
    get squareM() {
        return stageToArea(this.layouts[layout.PLAN].area);
    }

    @computed
    get squareUnits() {
        return stores.units.toSqUnits(this.squareM);
    }

    @computed
    get targetGrossUnits() {
        return stores.units.toSqUnits(this.targetGrossM);
    }

    @computed
    get targetNetUnits() {
        return stores.units.toSqUnits(this.targetNetM);
    }

    @computed
    get costPerSqUnit() {
        return stores.units.fromSqUnits(this.costPerSqM);//hack for inverse
    }

    @computed
    get planHeightM() {
        return (stageToLinear(this.layouts[layout.PLAN].height)).toFixed(2)
    }

    @computed
    get planWidthM() {
        return (stageToLinear(this.layouts[layout.PLAN].width)).toFixed(2);
    }

    @computed
    get planHeight() {
        return stores.units.toLinearUnits(this.planHeightM);
    }

    @computed
    get planWidth() {
        return stores.units.toLinearUnits(this.planWidthM);
    }

    @computed
    get planRotation() {
        return this.layouts[layout.PLAN].rotation;
    }


    @computed
    get color() {
        if (this.category) {
            return this.category.color;
        }
        return "#aba4d1";
    }

    @computed
    private get _tolerance() {
        return stores.units.fromSqUnits(10);
    }

    @computed
    get isUnderTarget() {
        return this.squareM < (this.targetNetM - this._tolerance);
    }

    @computed
    get isOverTarget() {
        return this.squareM > (this.targetGrossM + this._tolerance);
    }

    @computed
    get isAtTarget() {
        return !this.isUnderTarget && !this.isOverTarget;
    }

    @computed
    get hasWarnings() {
        return this.isOverTarget || this.isUnderTarget;
    }

    @computed
    get matrix() {
        // @ts-ignore
        return toString(compose(fromDefinition([
            // @ts-ignore
            { type: 'translate', tx: this.layout.position.x, ty: this.layout.position.y },
            // @ts-ignore
            { type: 'rotate', angle: this.layout.rotation }
        ])));
    }

    @computed
    get canResetShape() {
        return this.shape.type !== shape.RECTANGLE
    }

    @modelAction
    resetShape() {
        console.log('RESET SHAPE');
        const planLayout: Layout = this.layouts[layout.PLAN];
        planLayout.setShape(shape.RECTANGLE)
    }

    @computed
    get shape() {
        const { shape } = this.layouts[layout.PLAN];
        return shape;
    }

    @computed
    get globalVertices(): { x: number, y: number }[] {
        if (!this.svg) return [];
        const svg = this.svg.ownerSVGElement;
        const { shape } = this;
        if (svg) {
            const vtx = svg.createSVGPoint();
            const ctm = this.svg.getScreenCTM()!;
            const mtx = this.svg.getScreenCTM()!;
            const matrixValue = stores.stage.matrix.inverse;

            mtx.a = matrixValue.a;
            mtx.b = matrixValue.b;
            mtx.c = matrixValue.c;
            mtx.d = matrixValue.d;
            mtx.e = matrixValue.e;
            mtx.f = matrixValue.f;

            return shape.vertexArray.map(([x, y]) => {
                vtx.x = x;
                vtx.y = y;
                return vtx.matrixTransform(ctm).matrixTransform(mtx);
            });

        }

        return [];
    }

    @modelAction
    setPlanHeight(height: number) {
        if (isNaN(height)) return;
        const htM = stores.units.fromLinearUnits(height);
        this.layouts[layout.PLAN].shape.setHeight(linearToStage(htM));
    }

    @modelAction
    setPlanWidth(width: number) {
        if (isNaN(width)) return;
        const wdM = stores.units.fromLinearUnits(width);
        this.layouts[layout.PLAN].shape.setWidth(linearToStage(wdM));
    }

    @modelAction
    setPlanRotation(rotation: number) {
        if (isNaN(rotation)) return;
        this.layouts[layout.PLAN].shape.setRotation(rotation);
    }

    @modelAction
    setCategory(category: Category | null | undefined) {
        this.categoryRef = category ? categoryRef(category) : null;
    }

    @modelAction
    setSquareUnits(v: number) {
        if (isNaN(v)) return;
        const valM = stores.units.fromSqUnits(v);
        values(this.layouts).forEach(layout => {
            if (layout.shape.type !== shape.CIRCLE) {
                const graphicArea = valM * PLAN_SQ_FT_RATIO;
                const currentArea = layout.shape.area;

                if (currentArea === 0) {
                    const side = Math.sqrt(graphicArea);
                    layout.shape.setWidth(side);
                    layout.shape.setHeight(side);
                } else {
                    const ratio = Math.sqrt(graphicArea / currentArea);
                    layout.shape.setWidth(ratio * layout.shape.width);
                    layout.shape.setHeight(ratio * layout.shape.height);
                }
            }
        });
    }

    @modelAction
    setTargetNetUnits(v: number) {
        if (isNaN(v)) return;
        this.setTargetNet(stores.units.fromSqUnits(v));
    }

    @modelAction
    setTargetGrossUnits(v: number) {
        if (isNaN(v)) return;
        const existingEfficiency = this.targetNetM / this.targetGrossM;
        let targetGrossM = stores.units.fromSqUnits(v);
        this.setTargetGross(targetGrossM);
        this.targetNetM = targetGrossM * existingEfficiency;
        this.updateNetShapes(false);
        // this.efficiencyFactor = Math.round(100 * (this.targetNetM / this.targetGrossM));

    }

    @modelAction
    setEfficiencyFactor(v: number) {
        if (isNaN(v)) return;
        this.efficiencyFactor = v;
        this.setTargetGross(Math.round(this.targetNetM / (this.efficiencyFactor / 100)));
    }

    @modelAction
    setCostPerSqUnit(v: number) {
        if (isNaN(v)) return;
        this.costPerSqM = stores.units.toSqUnits(v);//hack for inverse
    }

    @action
    setSVGElement(svg: SVGGraphicsElement) {
        this.svg = svg;
    }

    @modelAction
    initTargets(value: number) {
        const grossM = stores.units.guessAtRounding(value);
        this.setTargetGross(grossM);
        this.setTargetNet(grossM * (this.efficiencyFactor / 100), false);
    }

    @modelAction
    setTargetNet(valM: number, match: boolean = false) {
        this.targetNetM = valM;
        this.setTargetGross(Math.round(this.targetNetM / (this.efficiencyFactor / 100)));
        this.updateNetShapes(match);
    }

    @modelAction
    updateNetShapes(match: boolean = false) {
        const valM = this.targetNetM;
        const radiusM = Math.sqrt(valM / Math.PI);
        values(this.layouts).forEach(layout => {
            if (layout.shape.type === shape.CIRCLE) {
                (layout.shape as Circle).setRadius(linearToStage(radiusM));
            } else {
                if (match) {
                    const edge = Math.sqrt(valM * PLAN_SQ_FT_RATIO);
                    layout.shape.setWidth(edge);
                    layout.shape.setHeight(edge);
                }
            }
        });
    }

    @modelAction
    setTargetGross(targetGrossM: number) {
        this.targetGrossM = targetGrossM;
    }

    @modelAction
    setComment(comment: string) {
        this.comment = comment;
    }

    @modelAction
    resetPositions() {
        values(this.layouts).forEach(layout => {
            layout.shape.position.reset();
        });
    }

    @computed
    get categoryName() {
        if (!this.category) return '';
        return this.category.name;
    }
}


export const recordRef = rootRef<Record>("pt/RecordRef");
export default Record;
