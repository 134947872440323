import firebaseConfig from "../config";
import { stores } from "@strategies/stores";
import { computed, makeObservable } from "mobx";
import File from "../models/File";
import UserState from "../models/UserState";
import { filenameWithoutExtension } from "../utils";

export interface IFileManager {
    updateFileName(): Promise<void>;

    createFile(folderId?: string): Promise<{ name: string; id: string } | undefined>;

    handleExternallyCreatedDuplicate(fileId: string): Promise<{ nameOfDuplicate: string; origFileId: string }>;
}

export default class GoogleDriveFileManager implements IFileManager {
    constructor() {
        makeObservable(this);
    }

    @computed
    get file(): File | undefined {
        return stores.supermodel ? stores.supermodel.file : undefined;
    }

    @computed
    get user(): UserState | undefined {
        return stores.supermodel ? stores.supermodel.users.current : undefined;
    }

    private async getFile(fileId: string) {
        if (!this.user) return null;

        try {
            let response = await fetch(`${firebaseConfig.apiURL}/getFile?fileId=${fileId}&userEmail=${this.user.email}`, {
                method: 'GET',
                // @ts-ignore
                cors: 'no-cors',
            });
            return await response.json();
        } catch (error) {
            console.log('Could not get file:', fileId, error);
            return null;
        }


    };

    async updateFileName() {
        const { file } = this;
        if (!file || !this.user) return;
        if (!this.user.email) {
            console.error('Cannot update file without user email');
            return;
        }
        const response = await fetch(`${firebaseConfig.apiURL}/updateFile`, {
            method: 'POST',
            // @ts-ignore
            cors: 'no-cors',
            body: JSON.stringify({
                fileId: file.fileId,
                fileName: file.fileName,
                userEmail: this.user.email,
                origFileId: file.fileId
            }),
        });
        return await response.json();
    }

    // private async updateFileId() {
    //     const { file } = this;
    //     if (!file || !this.user) return;
    //     const response = await fetch(`${firebaseConfig.apiURL}/updateFile`, {
    //         method: 'POST',
    //         // @ts-ignore
    //         cors: 'no-cors',
    //         body: JSON.stringify({
    //             fileId: file.fileId,
    //             fileName: file.fileName,
    //             userEmail: this.user.email,
    //             origFileId: file.fileId
    //         }),
    //     });
    //     return await response.json();
    // }

    async createFile(folderId?: string) {
        const { file } = this;
        if (!file || !this.user) return;
        const reqParams: any = {
            fileName: file.fileName,
            userEmail: this.user.email
        };
        if (folderId) {
            reqParams['folderId'] = folderId;
        }
        let res = await fetch(`${firebaseConfig.apiURL}/createFile`, {
            method: 'POST',
            // @ts-ignore
            cors: 'no-cors',
            body: JSON.stringify(reqParams),
        });
        const response = await res.json();

        return { id: response.id, name: filenameWithoutExtension(response.name) }
    }

    async handleExternallyCreatedDuplicate(fileId: string) {
        const res = await this.getFile(fileId);
        if (!res) return { origFileId: null, nameOfDuplicate: null };
        const { name, description } = res;
        const { origFileId } = JSON.parse(description);

        return {
            origFileId,
            nameOfDuplicate: name
        };
    }

}