import { Store, stores } from '@strategies/stores';
import { computed, makeObservable } from 'mobx';

import Category from '../models/Category';
import Record from '../models/Record';


export default class CategoriesStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get all(): Category[] {
        return stores.supermodel.file.categories;
    }

    findByName(name: string) {
        return this.all.find(c => c.name === name);
    }

    create(name: string) {
        return stores.supermodel.file.addCategory(name);
    }

    remove(category: Category) {
        category.records.forEach((record) => record.setCategory(null))
        stores.supermodel.file.removeCategory(category);
    }

}
