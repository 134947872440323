import firebase from "firebase";
import firebaseConfig from "../config";
import { IStateContainer, MultiUserPersistence, IPersistence, IRootStore } from "@strategies/collaborate-on-fire";
import KeystoneContainer from "./KeystoneContainer";
import UserState from "../models/UserState";
import UserStore from "../stores/UserStore";
import GoogleDriveFileManager, { IFileManager } from "./GoogleDriveFileManager";
import SuperModelStore from "../stores/SuperModelStore";
import { computed } from "mobx";
import { stores } from "@strategies/stores";
import { FileMigrationHelper } from "./FileMigrationHelper";
import { portFromRealtimeFormat } from "../versions";

export class AppStateManager {
    userStore: UserStore;
    fileManager: IFileManager;
    persistence?: IPersistence;
    rootStore: SuperModelStore;
    private stateContainer: IStateContainer;
    private creatingFiles?: Promise<void>;
    private fileMigrationHelper: FileMigrationHelper;

    constructor(rootStore: SuperModelStore) {
        const { userStore } = rootStore;
        this.rootStore = rootStore;
        this.userStore = userStore;
        this.fileManager = new GoogleDriveFileManager();
        console.log('initializeApp?', firebase.apps.length);
        let app: firebase.app.App;
        if (firebase.apps.length > 0) {
            app = firebase.apps[0];
        } else {
            app = firebase.initializeApp(firebaseConfig);
        }
        this.stateContainer = new KeystoneContainer();
        this.fileMigrationHelper = new FileMigrationHelper(app);

        firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
            if (!user) return;
            userStore.setLocalUser(new UserState({ uid: user.uid, name: user.displayName }));

            this.connect();

            this.performUrlAction();
        });
    }

    @computed
    get user(): UserState | undefined {
        return stores.supermodel ? stores.supermodel.users.current : undefined;
    }

    private connect() {
        if (!this.user) return;
        this.persistence = new MultiUserPersistence(firebase.apps[0], this.rootStore, this.stateContainer, {
            sessionUserId: this.user.uid,
            sendPatchIntervalTime: 150
        });
        // new SingleUserPersistence(app ,rootStore, stateContainer);
    }

    async updateFileName() {
        await this.fileManager.updateFileName();
    }

    async createFile(connect: boolean) {
        if (this.creatingFiles === undefined) {
            const createFilesAsync = async () => {
                let createdFile = await this.fileManager.createFile();
                if (!createdFile) return;
                const { id, name } = createdFile;
                console.log('CREATED: ', id);
                if (connect) this.connect();
                await this.loadFile(id, name);
                this.creatingFiles = undefined;
            }

            this.creatingFiles = new Promise<any>((resolve, reject) => {
                createFilesAsync().then(resolve).catch(reject);
            })
        }
        return this.creatingFiles;
    }

    async createNewFile() {
        this.persistence?.disconnect();
        await this.createFile(true);
        this.rootStore.file.empty();
    }

    async ensureFileCreated() {
        console.log('ensureFileCreated', this.rootStore.file.fileId);
        if (this.rootStore.file.fileId) return;
        await this.createFile(false);
    }

    async createDuplicate(newName: string) {
        // we disable / disconnect this file from the multiuser sync first
        // otherwise it will broadcast to the other users and update the previous file's name
        this.persistence?.disconnect();

        this.rootStore.file.setFileName(newName);
        let createdFile = await this.fileManager.createFile();
        if (!createdFile) return;
        const { id, name } = createdFile;

        this.connect();
        await this.loadFile(id, name);

    }

    async loadFile(fileId: string, fileName?: string) {
        console.log('LOAD FILE', fileId);
        this.rootStore.file.setFileId(fileId);
        if (!this.persistence) {
            throw new Error('persistence required');
        }
        if (!this.persistence.connected) {
            console.log('NOT CONNECTED, calling connect()');
            try {
                await this.persistence.connect();
            }
            catch (e) {
                console.log('Could not connect, returning');
                return;
            }
        }

        if (fileName) {
            this.rootStore.file.setFileName(fileName);
        }
        //we use this trick to connect to the persistence, but prevent overwriting.
        //disableLoadSnapshot may not be needed as the cloud firestore document won't exist - so it probably won't grab a snapshot anyway
        //testing required....
        this.rootStore.file.disableLoadSnapshot = true;
        await this.persistence.load();
        this.rootStore.file.disableLoadSnapshot = false;

        const mup = this.persistence as MultiUserPersistence;
        if (mup && mup.realtimeDb) {
            const keys = mup.realtimeDb.userRealtimeConnection.userListKeys;
            mup.realtimeDb.userRealtimeConnection.onUserKeysChanged = (keys: string[]) => {
                this.userStore.onAllUserKeysDefined(keys);
            };
            this.userStore.onAllUserKeysDefined(keys);
        }
    }

    async openFromDrive(fileId: string, fileName?: string) {
        if (!this.persistence) return;

        const { firestoreDb } = this.persistence;
        const idExists = await firestoreDb.exists(fileId);
        if (!idExists) {
            //unrecognized fileId in firestore
            //first look whether this id exists in the old location
            //if that's not there, look this up in GoogleDrive to find original file 'origFileId'
            //load that original file but use new ID when saving

            const oldFileSnapshot = await this.fileMigrationHelper.getDataFromOldLocation(fileId);
            if (oldFileSnapshot) {
                await this.fileMigrationHelper.copyToFireStore(fileId, portFromRealtimeFormat(oldFileSnapshot));//Note we don't handle all version upgrades here - those happen when it loads
            } else {
                const {
                    origFileId,
                    nameOfDuplicate
                } = await this.fileManager.handleExternallyCreatedDuplicate(fileId);
                if (origFileId && origFileId !== fileId) {
                    await firestoreDb.duplicate(origFileId, fileId);
                    fileName = nameOfDuplicate;
                } else {
                    throw new Error('Could not find file for ' + fileId);
                }
            }
        }

        if (this.persistence.connected) {
            this.persistence.disconnect();
        }

        await this.loadFile(fileId, fileName);
    }

    async createFromSnapshot(snapshot: any, fileName?: string) {
        if (!snapshot) throw new Error('No snapshot provided ', snapshot);
        //e.g. if we're loading from a file snapshot, we still need a live web-based version
        await this.ensureFileCreated();
        this.rootStore.file.loadSnapshot(snapshot);
        if (fileName) {
            this.rootStore.file.setFileName(fileName);
            await this.updateFileName(); //we also need to ensure the Google Drive filename matches
        }
    }

    async performUrlAction() {
        const urlParams = new URLSearchParams(decodeURI(window.location.search));
        const state = urlParams.get('state');
        if (!state || !this.persistence) return;
        const stateObj = JSON.parse(state);

        if (stateObj) {
            let fileId, fileName;
            //this part can be moved out of this class if needed
            if (stateObj.action === 'create') {
                //create file
                let createdFile = await this.fileManager.createFile(stateObj.folderId);
                if (createdFile) {
                    const { id, name } = createdFile;
                    fileId = id;
                    fileName = name;
                    await this.loadFile(fileId, fileName);
                }
            } else if (stateObj.action === 'open') {
                fileId = stateObj.ids[0];
                await this.openFromDrive(fileId);
            }


        }
    }
}
