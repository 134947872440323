//Note: the user store includes all users - both remote and local. Computed properties provide easy access to the local
//user or a list of remote users
import { applySnapshot, Model, model, modelAction, prop } from "mobx-keystone";
import { action, computed, makeObservable, observable } from "mobx";
import { IUserStore } from "@strategies/collaborate-on-fire";
import UserState from "../models/UserState";
import { layout } from "../models/Layout";
import { sasakiPalette } from "../assets/sasaki-color-palette";

export const timestamp = () => {
    return Math.floor(Date.now() / 1000);
}

@model('pt/UserStore')
class UserStore extends Model({
    userCollection: prop<UserState[]>(() => []),
}) implements IUserStore {
    @observable
    localUserId: string = '';

    @observable
    timeStamp: number = 0;

    constructor(props: any) {
        super(props);
        makeObservable(this);

        //every second, update the timestamp to trigger updates like users becoming 'stale'
        setInterval(() => {
            this.setTimeStamp(timestamp());
        }, 1000);
    }

    @action
    setTimeStamp(t: number) {
        this.timeStamp = t;
    }

    @computed
    get allUsers() {
        // const ans: UserState[] = [];
        // this.userCollection.forEach((value, key) => {
        //     ans.push(value);
        // });
        return this.userCollection;
    }

    @computed
    get staleTimeStamp() {
        let seconds = 60;
        return this.timeStamp - seconds;
    }

    @computed
    get activeUsers() {
        return this.allUsers.filter(u => u.lastUpdate > this.staleTimeStamp);
    }

    @computed
    get remoteUsers() {
        return this.activeUsers.filter(u => u.uid !== this.localUserId);
    }

    @computed
    get planViewUsers() {
        return this.remoteUsers.filter(u => u.layout === layout.PLAN);
    }

    @computed
    get adjacencyViewUsers() {
        return this.remoteUsers.filter(u => u.layout === layout.ADJACENCY);
    }

    @computed
    get current() {//alias
        return this.localUser;
    }

    @computed
    get localUser() {
        return this.userCollection.find(v => v.uid === this.localUserId);
    }

    @modelAction
    setLocalUser(userItem: UserState) {
        this.localUserId = userItem.uid;
        if (!this.localUser) {
            this.userCollection.push(userItem);
        }
    }

    @modelAction
    clearUser() {
        const localUser = this.localUser;
        if (localUser) {
            this.userCollection.splice(this.userCollection.indexOf(localUser), 1);
            this.localUserId = '';
        }
    }

    @modelAction
    applyUserSnapshot(userKey: string, snapshot: any) {
        console.log('applyUserSnapshot', userKey, snapshot);
        if (snapshot.stages && snapshot.stages[0]) {
            //TEMP fix - figure out where the older format is coming from and/or use versioning to fix this
            snapshot.stages = {
                [layout.ADJACENCY]: snapshot.stages[0],
                [layout.PLAN]: snapshot.stages[1],
            }
        }
        const existingItem = this.userCollection.find(v => v.uid === userKey);
        if (!existingItem) {
            this.userCollection.push(new UserState(snapshot));
        } else {
            //TEMP another interim fix - need to figure out why we're losing info here...
            if (!snapshot.email && existingItem.email) {
                snapshot.email = existingItem.email;
            }
            if (!snapshot.photoURL && existingItem.photoURL) {
                snapshot.photoURL = existingItem.photoURL;
            }
           applySnapshot(existingItem, snapshot);
        }
    }

    @modelAction
    onAllUserKeysDefined(keys: string[]) {
        //we use the master list of keys to have each user pick a unique color
        const idx = keys.indexOf(this.localUserId);
        if (this.localUser && idx >= 0 && idx < sasakiPalette.length) {
            this.localUser.setColor(sasakiPalette[idx]);
        }
    }
}


export default UserStore;
