import { Model, model, prop } from 'mobx-keystone';

import File from '../models/File';
import UserStore from "./UserStore";
import { IRootStore } from "@strategies/collaborate-on-fire";
import { AppStateManager } from "../helpers/AppStateManager";
import { action, computed, makeObservable } from "mobx";
import firebase from "firebase";
import User from "../models/User";
import UserState from "../models/UserState";


@model('pt/SuperModel')
class SuperModel extends Model({
    file: prop<File>(() => new File({})),
    userStore: prop<UserStore>(() => new UserStore({})),
}) implements IRootStore {

    //Unclear if this is needed - at one point it seemed like loggedIn wasn't updating, but removing this makes no difference...
    constructor(props: any) {
        super(props);
        makeObservable(this);
    }

    appStateManager?: AppStateManager;

    onRegister() {
        this.appStateManager = new AppStateManager(this);
    }

    onUpdateRegistration() {
    }


    @computed
    get users() {//alias
        return this.userStore;
    }

    @computed
    get loggedIn() {
        return !!this.userStore.localUserId;
    }

    @action
    login(user: { uid: string, displayName: string, email: string, photoURL: string }) {
        if (user) {
            if (!user.uid) throw  new Error('UID required');
            console.log('login', user.uid, user.photoURL);
            this.users.setLocalUser(new UserState({
                uid: user.uid,
                name: user.displayName,
                email: user.email,
                photoURL: user.photoURL
            }));
        } else {
            this.users.clearUser()
        }
        // const provider = new firebase.auth.GoogleAuthProvider();
        // firebase.auth().signInWithPopup(provider);
    }

    logout() {
        firebase.auth().signOut();
        window.location.reload();
    }
}


export default SuperModel;
