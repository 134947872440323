import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Title, Body } from '@strategies/ui';

import InnerPanel from './InnerPanel';
import Category from '../../../models/Category';
import ClampedBar from '../charting/ClampedBar';
import FillBar from '../charting/FillBar';
import StackedBar from '../charting/StackedBar';



export default observer(function SummaryInnerPanel() {
    const { app, supermodel: { file }, units, ui } = useStores();
    const _SF = units.formatAreaLabel.bind(units);

    return (
        <>
            <InnerPanel 
                className="SummaryInnerPanel"
                active={ui.summaryInnerPanel}
                setActive={active => ui.setSummaryInnerPanel(active)}
            >
                <Title>Program Summary</Title>
                <Body>
                    <div className="summary-group">

                        <div className="BarChart">
                            <label>Eff %</label>
                            <div
                                className="BarChart__graphic"><label
                                className={"BarChart__graphic__label"}>{Math.round(100 * app.totalNetSquareUnits / app.totalTargetSquareUnits) || 0}% </label>
                            </div>
                        </div>


                        <ClampedBar
                            label={"Drawn"}
                            values={app.totalActualSquareUnits}
                            clamp={{ min: app.totalNetSquareUnits, max: app.totalTargetSquareUnits }}
                            ramp={['#696969']}
                            format={_SF}
                            presentationMode={app.inPresentationMode}
                        />

                        <StackedBar
                            label={"Net"}
                            values={app.totalNetSquareUnits}
                            total={app.totalTargetSquareUnits}
                            ramp={['#D9D9D9']}
                            format={_SF}
                        />

                        <StackedBar
                            label={"Gross"}
                            values={app.totalTargetSquareUnits}
                            total={app.totalTargetSquareUnits}
                            ramp={['#AAAAAA']}
                            format={_SF}
                        />

                            <div className="DepartmentView">
                                <FillBar
                                    label={`Total Gross Size All Categories`}
                                    value={app.totalTargetSquareUnits}
                                    total={app.totalTargetSquareUnits}
                                    ramp={['#696969']}
                                    format={_SF}
                                />

                                {file.categories.map((d: Category) => (
                                    <FillBar
                                        key={d.id}
                                        label={d.name}
                                        value={d.gross}
                                        total={app.totalTargetSquareUnits}
                                        ramp={[d.color]}
                                        format={_SF}
                                    />
                                ))}
                            </div>
                    </div>



                </Body>
            </InnerPanel>



        </>

    );
});
