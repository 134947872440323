import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { observer } from "mobx-react";
import { FiX } from 'react-icons/fi';
import { useStores } from '@strategies/stores';
import { Modal, Title, SubTitle, Body, Buttons, Button, IconButton } from '@strategies/ui';


export default observer(function LoadImageModal() {
    const { app, history, stage, ui } = useStores();
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState<{ url?: string, name?: string }>({
        name: stage.image.name,
        url: stage.image.url,
    });

    useEffect(() => {
        if (ui.loadImageModal) {

            setImage({
                name: stage.image.name || undefined,
                url: stage.image.url || undefined,
            });
        }
    }, [stage.image, ui.loadImageModal]);

    const onFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setPending(true);
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');

            reader.onerror = () => {
                setImage({});
                setError(true);
                reader.abort();
                setPending(false);
            };

            reader.onload = async () => {
                const arrayBuffer = reader.result;
                const imageObj = await app.imageHandler.processImage(arrayBuffer, file.type.split('/')[1]);
                setImage({
                    name: file.name,
                    url: imageObj
                });
                setLoading(false);
                setPending(false);
            };
            setLoading(true);
            reader.readAsArrayBuffer(file);
        }
    }, [app.imageHandler]);

    const save = useCallback(() => {
        if (image.name && image.url) {
            const _image = new Image();
            _image.onload = () => {
                history.withGroup(() => (
                    stage.setImage(image.name, image.url, [_image.width, _image.height])
                ), 'set background image');

                ui.setLoadImageModal(false);
            };
            _image.src = image.url;
        } else {
            stage.setImage("", "", [0, 0]);
        }

        ui.setLoadImageModal(false);
    }, [ui, stage, image, history]);

    return (
        <Modal className="LoadImageDialog" active={ui.loadImageModal} onClose={!loading ? save : undefined}>
            <Title>Load Base Image</Title>
            <SubTitle>Import an underlay image to draw over.</SubTitle>

            <Body>
                {image.name ? (
                    <div className="image">
                        <IconButton onClick={() => setImage({})}>
                            <FiX/>
                        </IconButton>
                        <p>{image.name}</p>
                    </div>
                ) : (
                    <div>
                        <label className={`Button${pending ? ' disabled' : ''}`} htmlFor="imageLoad">
                            Choose File
                        </label>

                        <input
                            disabled={pending}
                            id="imageLoad"
                            type="file"
                            accept="image/*"
                            onChange={onFileUpload}
                        />
                        {error && <p className="error">File upload error</p>}
                    </div>
                )}
            </Body>

            <Buttons>
                <Button
                    className="secondary"
                    onClick={() => ui.setLoadImageModal(false)}
                    disabled={loading}
                >
                    Cancel
                </Button>

                <Button
                    onClick={save}
                    disabled={loading}
                >
                    Save
                </Button>
            </Buttons>
        </Modal>
    );
});

