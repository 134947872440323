import { action, computed, makeObservable, observable } from 'mobx';
import { stores } from '@strategies/stores';
import { Model, model, modelAction, prop } from 'mobx-keystone';

import User from './User';
import Position from './Position';
import { unit } from '../stores/UnitsStore';
import { layout } from './Layout';
import TransformMatrix, { ITransformMatrix } from './TransformMatrix';
import { timestamp } from "../stores/UserStore";
import { IUserStore } from "@strategies/collaborate-on-fire";


type StageMap = {[key in layout]: TransformMatrix};


@model('pt/UserState')
class UserState extends Model({
    uid: prop<string>(''),
    name: prop<string>(''),
    color: prop<string>('#ff0000').withSetter(),
    email: prop<string|null>(null),
    photoURL: prop<string|null>(null),
    unit: prop<unit>(unit.FEET),
    layout: prop<layout>(layout.ADJACENCY),
    mouse: prop<Position>(() => new Position({})),
    lastUpdate: prop<number>(0),
    stages: prop<StageMap>(() => ({
        [layout.PLAN]: new TransformMatrix({}),
        [layout.ADJACENCY]: new TransformMatrix({}),
    })),
}) {
    @modelAction
    setUnit(unit: unit) {
        this.unit = unit;
        this.lastUpdate = timestamp();
    }

    @modelAction
    setLayout(layout: layout) {
        this.layout = layout;
        this.lastUpdate = timestamp();
    }

    @computed
    get stage() {
        return this.stages[this.layout];
    }

    @modelAction
    setStageMatrix(matrix: ITransformMatrix) {
        this.stages[this.layout].set(matrix);
        this.lastUpdate = timestamp();
    }

}


export default UserState;
