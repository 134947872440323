import React, { useCallback } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Body, Button, Buttons, Modal, Title } from "@strategies/ui";
import Stage from "../Stage/Stage";
import StageStore from "../../stores/StageStore";
import print from 'print-js'
import SvgImageDownloader from "../../helpers/SvgImageDownloader";
import UIStore from '../../stores/UIStore';
import { CheckboxSlider, Group, Input } from "@strategies/react-form";
import File from "../../models/File";
import InnerPanel from "../Dashboard/InnerPanel/InnerPanel";

const svgDownloader = new SvgImageDownloader();

type OptionsInnerPanelProps = {};
export const OptionsInnerPanel = observer(({}: OptionsInnerPanelProps) => {
    const ui = useStores().ui as UIStore;
    const file = useStores().supermodel.file as File;
    const { exportOptions } = file;
    if (!exportOptions) return null;
    return <InnerPanel
        className="OptionsInnerPanel"
        active={ui.exportOptionsExpanded}
        setActive={active => ui.setExportOptionsExpanded(active)}
    >
        <Title>Options</Title>
        <Body>
            <Group>
                <Input
                    name="Name Font Scale"
                    type="number"
                    value={Math.round(exportOptions.nameScale * 100)}
                    onKeyUp={(value) => {
                        if (typeof value !== "number" || isNaN(value)) return;
                        exportOptions.setNameScale(value / 100);
                    }}
                    right={'%'}
                />
                <Input
                    name="Area Font Scale"
                    type="number"
                    value={Math.round(exportOptions.areaScale * 100)}
                    onKeyUp={(value) => {
                        if (typeof value !== "number" || isNaN(value)) return;
                        exportOptions.setAreaScale(value / 100);
                    }}
                    right={'%'}
                />
                <label className="legend-toggle">
                    <CheckboxSlider
                        value={exportOptions.includeLegend}
                        onChange={() => exportOptions.setIncludeLegend(!exportOptions.includeLegend)}
                    />
                    Include Legend
                </label>
            </Group>
        </Body>
    </InnerPanel>
});


export default observer(function ExportPreview() {
    const ui = useStores().ui as UIStore;

    const close = useCallback(() => {
        ui.setExportPreviewModal(false);
    }, [ui]);

    const stage = useStores().stage as StageStore;
    const file = useStores().supermodel.file as File;

    const landscapeOrientation = (stage.bounds.width > stage.bounds.height);

    const pageSizeLong = 1700;
    const pageSizeShort = 1100;

    const exportOptions = {
        width: landscapeOrientation ? pageSizeLong : pageSizeShort,
        height: landscapeOrientation ? pageSizeShort : pageSizeLong,
        className: 'export-preview',
        includeLegend: file.exportOptions?.includeLegend
    };

    return (
        <Modal active={ui.exportPreviewModal} onClose={close} className="ExportPreview">
            <Title>Preview Image Export</Title>
            <Body>
                <OptionsInnerPanel/>
                <Stage id={'print-stage'} layout={ui.layout} exportMode={true} exportOptions={exportOptions}/>
            </Body>
            <Buttons>
                <Button className="secondary" onClick={() => ui.setExportPreviewModal(false)}>
                    Cancel
                </Button>

                <Button className={'secondary'} onClick={() => {
                    print({
                        documentTitle: 'Programme Tool Printout',
                        printable: 'print-stage',
                        type: 'html',
                        // maxWidth: 700
                    });
                }}>Print</Button>

                <Button className={'secondary'} onClick={() =>
                    svgDownloader.downloadSVG('#print-stage', 'Programme Tool')
                }>Download SVG</Button>
                <Button className={'secondary'} onClick={() =>
                    svgDownloader.downloadSVGAsPNG('#print-stage', 'Programme Tool', 10)
                }>Download PNG</Button>


            </Buttons>
        </Modal>
    )
});