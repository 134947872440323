import { KeyboardEvent } from "react";
import { RadioSlider, RawInput } from '@strategies/react-form';
import { useStores } from '@strategies/stores';
import { observer } from "mobx-react";
import Category from "../../models/Category";
import Record from "../../models/Record";
import { Body, Header, IconButton, Panel, Title, Toggle } from "@strategies/ui";
import { FiGrid, FiArrowUp, FiArrowDown } from "react-icons/fi";
import TableStore, { columns, GroupableColumn } from "../../stores/TableStore";
import { assign } from "mobx/dist/utils/utils";
import File from '../../models/File';
import UIStore from "../../stores/UIStore";
import UndoStore from "../../stores/UndoStore";
import { LabelCategory } from "../EditCategory/LabelCategory";
import { SelectCategory } from "../SelectCategory/SelectCategory";

type RecordRowProps = {
    item: Record;
};

const FocusableInput = (props: any) => {
    const history = useStores().history as UndoStore<File>;

    return (
        <RawInput
            {...props}
            onKeyPress={(_, e) => e.stopPropagation()}
            onKeyDown={(_, e) => e.stopPropagation()}
            onKeyUp={(_, e) => e.stopPropagation()}
            onFocus={(_,e) => history.startGroup('editing table')}
            onBlur={(_, e) => history.stopGroup()}
        />
    );
};

const numFromStr = (value: string) => {
    value = value.replace(/,/g, '');
    return parseFloat(value);
};

export const RecordRow = observer(({ item }: RecordRowProps) => {
    const { app, units, records } = useStores();
    const ui = useStores().ui as UIStore;
    const table = useStores().table as TableStore;

    return <tr key={item.id} className={'trow' + (item.selected ? ' selected' : '')}
               onClick={(e) => {
                   if (app.filterBy !== '') return;//When filtered by selected, this allows edits without affecting the table

                   if (e.shiftKey) {
                       table.fillSelectionUpTo(item);
                   } else {
                       if (e.ctrlKey) {
                           item.setSelected(!item.selected);
                       } else {
                           records.clearSelected();
                           item.setSelected();
                       }
                   }
               }}>
        <td>
            {
                !!item.category &&
                <IconButton
                    className={"category_edit_handle"}
                    onClick={() => ui.setEditCategory(item.category)}
                    style={{
                        background: "none"
                    }}
                >
                    <LabelCategory color={item.category.color}/>
                </IconButton>
            }
        </td>
        <td>
            <FocusableInput
                value={item.name}
                onChange={(val: string) => (item.setName(val))}
            />
        </td>
        <td className={"category"}>
            <SelectCategory setCategory={category => item.setCategory(category)} category={item.category}
            />
        </td>
        <td className={"grey"}>
            <FocusableInput
                value={units.formatLinear(item.planHeight)}
                onChange={(val: string) => (item.setPlanHeight(numFromStr(val)))}
            />
        </td>
        <td className={"grey"}>
            <FocusableInput
                value={units.formatLinear(item.planWidth)}
                onChange={(val: string) => (item.setPlanWidth(numFromStr(val)))}
            />
        </td>
        <td className={"grey"}>
            <FocusableInput
                value={units.formatArea(item.squareUnits)}
                onChange={(val: string) => (item.setSquareUnits(numFromStr(val)))}
            />
        </td>
        <td className={"d-grey"}>
            <FocusableInput
                value={units.formatArea(item.targetNetUnits)}
                onChange={(val: string) => (item.setTargetNetUnits(numFromStr(val)))}
            />
        </td>
        <td className={"d-grey"}>
            <FocusableInput
                value={units.formatArea(item.targetGrossUnits)}
                onChange={(val: string) => (item.setTargetGrossUnits(numFromStr(val)))}
            />
        </td>
        <td className={"d-grey"}>
            <FocusableInput
                value={Math.round(item.efficiencyFactor)}
                onChange={(val: string) => (item.setEfficiencyFactor(numFromStr(val)))}
            />
        </td>
        <td>
            <FocusableInput
                value={Math.round(item.costPerSqUnit)}
                onChange={(val: string) => (item.setCostPerSqUnit(numFromStr(val)))}
            />
        </td>
        <td>
            <FocusableInput
                value={item.comment}
                onChange={(val: string) => (item.setComment(val))}
            />
        </td>
    </tr>
});

export default observer(function Table(props: any) {
    const { app, ui } = useStores();

    const table = useStores().table as TableStore;

    const getHeaderContent = function (column: GroupableColumn) {
        if (!column) return <th/>;
        return <th key={column.id}
                   className={column.className}//shouldn't need this here, but when in sticky mode this will lose its background color
                   onClick={() => {
                       table.toggleSortDirection();
                       table.setSortByColumn(column);
                   }}>
            <span>{column.label}</span>
            {table.sortByColumn?.id === column.id && (table.sortAscending ? <FiArrowDown/> : <FiArrowUp/>)}
        </th>;
    }

    const colGroups = [];
    for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column.colSpan) i += (column.colSpan - 1);//skip based on the span
        colGroups.push(column);
    }

    return (<Panel
            active={ui.tabularView}
            onToggle={() => ui.setTabularView(!ui.tabularView)}
            className={`Table ${ui.dashboardView ? "" : "full"}`}
        >
            <Title>Data</Title>
            <Toggle><FiGrid/></Toggle>
            <Header>
                <RadioSlider
                    value={app.filterBy === '' ? 'ALL' : 'SELECTED'}
                    onChange={(value: any) => app.setFilterBy(value === 'ALL' ? '' : 'selected')}
                    options={['SELECTED', 'ALL']}
                />
            </Header>

            <Body>
                <table>
                    <colgroup>
                        {colGroups.map((column, i) => <col key={`col-${i}`} span={column.colSpan}
                                                           className={column.className}/>)}
                    </colgroup>
                    <thead>
                    <tr className={'group-row'}>
                        {colGroups.map((column, i) => {
                            if (column.colSpan) {
                                return <th key={`top-${i}`} colSpan={column.colSpan}
                                           style={{ width: column.width }}>{column.caption}</th>
                            }
                            return <th key={`top-${i}`} style={{ width: column.width }}/>;
                        })}
                    </tr>
                    <tr className={'title-row'}>
                        {columns.map((column) => getHeaderContent(column))}
                    </tr>

                    </thead>
                    <tbody>
                    {table.sortedRecords.map((item) => <RecordRow key={item.id} item={item}/>)}
                    </tbody>
                </table>
            </Body>
        </Panel>

    );
})
