import { Component } from 'react';
import { observer } from "mobx-react";
import { AutoSizer } from "react-virtualized";
import { ReactSVGPanZoom } from "react-svg-pan-zoom";
import { StoresContext, useStores } from '@strategies/stores';

import StageStore, { tool } from '../../stores/StageStore';
import { layout } from '../../models/Layout';

import PlanView from "./PlanView";
import AdjacencyView from "./AdjacencyView";

import { convertPts, distance, inflateRect } from "../../utils";
import { Point } from "../../models/Shape";
import Category from "../../models/Category";


const toolToString = (t: tool) => {
    switch (t) {
        case tool.PAN:
            return 'pan';
        case tool.ARROW:
            return 'arrow';
        case tool.SCALE:
            return 'scale';
        default:
            return 'none'
    }
}
const stringToTool = (s: string) => {
    switch (s) {
        case 'pan':
            return tool.PAN;
        case 'arrow' :
            return tool.ARROW;
        case 'scale' :
            return tool.SCALE;
        default:
            return tool.NONE
    }
}

const OReactSVGPanZoom = observer(ReactSVGPanZoom);

type ExportOptions = {
    width: number;
    height: number;
    includeLegend?: boolean;
    className?: string;
}

type StageProps = {
    id: string;
    layout: string;
    exportMode: boolean;
    exportOptions?: ExportOptions;
}

type StageState = {
    clickPosition: Point;
}

type CategoryLegendProps = {
    x:number,
    y:number,
};
export const CategoryLegend = observer(({x,y}: CategoryLegendProps) => {
    const { supermodel: { file } } = useStores();
    const spacingY = 20;
    return <g transform={`translate(${x}, ${y})`}>
        {file.categories.map((d: Category, i:number) => <g key={d.id} transform={`translate(${0}, ${i*spacingY})`}>
            <rect width={12} height={12} fill={d.color} stroke={'#000000'}/>
            <text dy={12} x={20}>{d.name}</text>
        </g>)
        }
    </g>
});

@observer
class Stage extends Component<StageProps, StageState> {

    static contextType = StoresContext;

    constructor(props: StageProps) {
        super(props);

        this.state = {
            clickPosition: {
                x: 0,
                y: 0
            }
        };
    }

    onMouseDown = (e: any) => {
        const { stage } = this.context;

        if (stage.tool === tool.SHAPE) {
            stage.setDrawing();
        }

        if (stage.tool === tool.PAN && e.shiftKey) {
            stage.setTool(tool.NONE);
            stage.setMarqueeSelect();
        }

        const mouseCoords = { x: e.clientX, y: e.clientY };
        const coords = convertPts(mouseCoords);

        stage.clickPosition.set(coords.x, coords.y);
        this.setState({ clickPosition: mouseCoords });
    };

    onMouseClick = (e: any) => {
        const { records, stage } = this.context;
        const tol = 1;
        const coords = { x: e.clientX, y: e.clientY };

        if (stage.tool === tool.PAN) {
            //Note that a pan operation will result in the mouse moving, but the stage position not changing
            //we want a regular click to change selection - but not a pan (click and drag)
            const dist = distance(this.state.clickPosition, coords);

            if (dist < tol) {
                records.clearSelected();
            }
        } else if (stage.tool === tool.SCALE && !stage.hasScaleCoords) {
            stage.addToolData(convertPts(coords));
        }
    };

    onMouseUp = () => {
        const { stage } = this.context;

        stage.setDrawing(false);
        stage.setMarqueeSelect(false);

        if (stage.tool !== tool.SCALE) {
            stage.setTool(tool.PAN);
        }
    };

    onMouseMove = (e: any) => {
        const { users } = this.context.supermodel;
        let coords = convertPts({ x: e.clientX, y: e.clientY });
        users.current.mouse.set(coords.x, coords.y);
    };

    svgDefs() {
        return <defs>
            <marker className="arrowhead-unselected" id={`arrowhead-unselected`} markerWidth="10"
                    markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7"/>
            </marker>
            <marker className="arrowhead-selected" id={`arrowhead-selected`} markerWidth="10"
                    markerHeight="7"
                    refX="10" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7"/>
            </marker>
        </defs>
    }

    renderPlainSvg() {
        const stage = this.context.stage as StageStore;
        const { id } = this.props;

        const {exportOptions} = this.props;
        if (!exportOptions) return null;

        const { x, y, width, height } = inflateRect(stage.bounds, 1.05);
        return <svg id={id} className={exportOptions.className} width={exportOptions.width} height={exportOptions.height} viewBox={`${x} ${y} ${width} ${height}`}>
            <style>
                {`text {
                    font-family:Inter, Calibri, Arial, sans-serif
                }`}
            </style>
            {this.svgDefs()}
            <g>
                {exportOptions.includeLegend && <CategoryLegend x={stage.bounds.x} y={stage.bounds.y}/>}
                {this.props.layout === layout.PLAN && <PlanView exportMode={true}/>}
                {this.props.layout === layout.ADJACENCY && <AdjacencyView exportMode={true}/>}
            </g>
        </svg>;
    }

    render() {
        if (this.props.exportMode) return this.renderPlainSvg();
        const { id } = this.props;
        const { stage } = this.context;

        return (
            <div
                id={id}
                className={"Stage " + stage.tool}
                tabIndex={0}
                onMouseMove={this.onMouseMove}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onClick={this.onMouseClick}
            >
                <AutoSizer>
                    {(({ width, height }) => width === 0 || height === 0 ? null : (
                        <OReactSVGPanZoom
                            ref={(viewer: any) => stage.setViewer(viewer)}
                            width={width} height={height}
                            tool={toolToString(stage.tool)} onChangeTool={(s: string) => stage.setTool(stringToTool(s))}
                            value={stage.matrix} onChangeValue={(matrix: any) => stage.setMatrix(matrix)}
                            toolbarProps={{ position: 'none' }}
                            miniatureProps={{ position: 'none' }}
                            detectAutoPan={false}
                            preventPanOutside={false}
                            scaleFactorMin={0.05}
                            scaleFactorMax={25}
                            SVGBackground={"#f2f5fa"}
                            background={"#f2f5fa"}
                            scaleFactorOnWheel={1.3}
                            className={"stage-svg"}
                        >
                            <svg
                                ref={svg => stage.setSVGElement(svg)}
                                width={2000}
                                height={1000}
                            >
                                {this.svgDefs()}
                                <g id="scale-wrapper">
                                    {{
                                        [layout.ADJACENCY]: <AdjacencyView/>,
                                        [layout.PLAN]: <PlanView/>,
                                    }[this.props.layout]}
                                </g>
                            </svg>
                        </OReactSVGPanZoom>
                    ))}
                </AutoSizer>
            </div>
        );
    }
}

export default Stage;
