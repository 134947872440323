import { observer } from 'mobx-react';

import StackedBar from './StackedBar';
import Bar from './Bar';


type ClampedBarProps = {
    label: string;
    values: number|number[];
    clamp: {min:number, max: number};
    format: any;
    ramp: string[],
    onMatchTarget?: (value: number) => void;
    onShapeReset?: () => void;
    presentationMode?: boolean;
};

export default observer(function ClampedBar(props: ClampedBarProps) {
    let { 
        label, 
        values, 
        clamp, 
        format, 
        ramp = [], 
        onMatchTarget = undefined, 
        onShapeReset = undefined, 
        presentationMode = false,
    } = props;

    if (!Array.isArray(values)) {
        values = [values];
    }

    const valueTotal = values.reduce((a,b) => a + b, 0);
    const total = Math.max(valueTotal, clamp.max);

    let outOfRange = false;
    if (valueTotal < clamp.min) {
        values.push(clamp.min - valueTotal);
        outOfRange = true;
    }
    else if (valueTotal > clamp.max) {
        values.push(Math.min(valueTotal - clamp.max, total * .05));
        outOfRange = true;
    }

    if (values.length > 1) {
        if (outOfRange && !presentationMode) {
            ramp[values.length - 1] = '#FF0000';
        } else {
            // ramp[values.length - 1] = ramp[0];
        }
    }


    const mid = clamp.min + ((clamp.max - clamp.min) / 2);

    return (
        <div className={`BarChart ClampedBar ${outOfRange ? 'out-of-range' : ''} ${presentationMode ? 'presentationMode' : ''}`}>
            <StackedBar label={label} total={total} value={valueTotal} values={values} ramp={ramp} format={format} />

            {onMatchTarget !== undefined && outOfRange && (
                <button className="match-target" onClick={() => onMatchTarget!(mid)}>Match Target</button>
            )}

            {onShapeReset !== undefined && (
                <button className="reset-shape" onClick={() => onShapeReset!()}>Reset</button>
            )}

            <div className="clamp">
                <Bar values={clamp.min} total={clamp.max} ramp={['#666']}/>
            </div>
        </div>
    );
});
