import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';

import Shape, { createShape, shape } from './Shape';


export enum layout {
    ADJACENCY = "ADJACENCY",
    PLAN = "PLAN",
}

@model('pt/Layout')
class Layout extends Model({
    shape: prop<Shape>(),
    placed: prop<boolean>(false),
}) {

    @modelAction
    place() {
        this.placed = true;
    }

    move(x: number, y: number) {
        this.shape.position.move(x, y);
    }

    setHeight(height: number) {
        this.shape.setHeight(height);
    }

    setWidth(width: number) {
        this.shape.setWidth(width);
    }

    resize(width: number, height: number) {
        this.shape.resize(width, height);
    }

    @modelAction
    setShape(type: shape) {
        if (type !== this.shape.type) {
            const newShape: Shape = createShape(type);

            newShape.setWidth(this.shape.width);
            newShape.setHeight(this.shape.height);
            newShape.setRotation(this.shape.rotation);
            newShape.position.assign(this.shape.position);

            this.shape = newShape;
        }
    }

    @computed
    get width() {
        return this.shape.width;
    }

    @computed
    get height() {
        return this.shape.height;
    }

    @computed
    get position() {
        return this.shape.position;
    }

    @computed
    get perimeter() {
        return this.shape.perimeter;
    }

    @computed
    get area() {
        return this.shape.area;
    }

    @computed
    get boundingBox() {
        return this.shape.boundingBox;
    }

    @computed
    get x() {
        return this.shape.position.x;
    }

    @computed
    get y() {
        return this.shape.position.y;
    }

    @computed
    get rotation() {
        return this.shape.rotation;
    }

    setRotation(rotation: number) {
        this.shape.setRotation(rotation);
    }

}


export default Layout;
