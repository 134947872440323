//@ts-nocheck
import {Component} from 'react';
import {observer} from "mobx-react";
import { StoresContext } from '@strategies/stores';

import {stageToLinear} from "../../utils";


@observer
class DrawRect extends Component {

    static contextType = StoresContext;

    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            originalPosition: {x: 0, y: 0}
        };
    }

    componentDidMount = () => {
        const { x , y } = this.context.stage.clickPosition;

        this.setState({
            originalPosition: { x, y }
        });
    };

    componentWillUnmount = () => {
        let x, y;
        const { history, records, stage } = this.context;
        const { mouse } = stage;
        const { originalPosition } = this.state;

        x = Math.min(originalPosition.x, mouse.x);
        y = Math.min(originalPosition.y, mouse.y);

        if (mouse.x - originalPosition.x < 0) {
            x = mouse.x;
        }

        if (mouse.y - originalPosition.y < 0) {
            y = mouse.y;
        }

        const width = Math.abs(mouse.x - originalPosition.x);
        const height = Math.abs(mouse.y - originalPosition.y);

        if (width > 10 && height > 10) {
            history.withGroup(() => {
                const record = records.create();

                record.layout.move(x + width / 2, y + height / 2);
                record.layout.setHeight(height);
                record.layout.setWidth(width);

                const w = stageToLinear(width);
                const h = stageToLinear(height);

                record.initTargets(w * h);
                record.layout.place();

                records.clearSelected();
                record.setSelected();
            }, 'create rectangle');
        }
    };

    render() {
        const { stage, units } = this.context;
        const { mouse } = stage;
        const { originalPosition } = this.state;
        let x, y;

        x = Math.min(originalPosition.x, mouse.x);
        y = Math.min(originalPosition.y, mouse.y);

        if (mouse.x - originalPosition.x < 0) {
            x = mouse.x;
        }

        if (mouse.y - originalPosition.y < 0) {
            y = mouse.y;
        }

        const width = Math.abs(mouse.x - originalPosition.x);
        const height = Math.abs(mouse.y - originalPosition.y);

        const w = stageToLinear(width);
        const h = stageToLinear(height);

        const linear = {
            w: units.toLinearUnits(w),
            h: units.toLinearUnits(h),
        };

        return (
            <g className={"drawRect"}
               transform={`translate(${x + width / 2}, ${y + height / 2})`}>
                <rect
                    stroke={"black"}
                    strokeDasharray={"4"}
                    fill={"transparent"}
                    x={-width / 2}
                    y={-height / 2}
                    width={width}
                    height={height}
                />
                <text textAnchor="middle">{Math.round(linear.w * linear.h)}</text>
                <text y={20} textAnchor="middle">{Math.round(linear.w)} x {Math.round(linear.h)}</text>
            </g>
        );
    }
}

export default DrawRect;
