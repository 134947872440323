//@ts-nocheck
import {Component} from 'react';
import {observer} from "mobx-react";
import { StoresContext } from '@strategies/stores';

import { shape } from '../../models/Shape';
import { layout } from '../../models/Layout';


@observer
class Arrow extends Component {

    static contextType = StoresContext;

    constructor(props) {
        super(props);

        this.state = {
            dragging: false
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('click', this.cancelEditMode);
    }

    handleClick = (e) => {
        e.stopPropagation();

        this.props.arrow.setSelected(!this.props.arrow.selected);
        document.addEventListener('click', this.cancelEditMode);
    };

    cancelEditMode = () => {
        this.props.arrow.setSelected(false);
        document.removeEventListener('click', this.cancelEditMode);
    };

    handleKeyDown = (e) => {
        const { arrow } = this.props;

        if (!arrow.selected) return;

        if (e.key === "Delete" || e.key === "Backspace") {
            this.context.arrows.remove(arrow);
        }
    };

    dragConnection = (e, point) => {
        const { history } = this.context;
        e.stopPropagation();

        const { arrow } = this.props;
        let previousPoint;

        this.setState({
            dragging: true
        });

        history.startGroup('connect arrow');
        if (point === "origin") {
            previousPoint = arrow.origin;
            arrow.setOrigin(null);
        } 
        else if (point === "target") {
            previousPoint = arrow.target;
            arrow.setTarget(null);
        }

        const onUp = () => {
            document.removeEventListener('mouseup', onUp);

            this.setState({
                dragging: false
            });

            const activeRecord = this.context.records.active[0];

            if (activeRecord) {
                if (point === "origin" && activeRecord.id !== arrow.target.id) {
                    arrow.setOrigin(activeRecord);
                } else if (point === "target" && activeRecord.id !== arrow.origin.id) {
                    arrow.setTarget(activeRecord);
                }
            }
            else {
                if (point === "origin") {
                    arrow.setOrigin(previousPoint);
                } else if (point === "target") {
                    arrow.setTarget(previousPoint);
                }
            }

            history.stopGroup();
        };

        document.addEventListener('mouseup', onUp);
    };

    nodeEdge(a, b, r) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        if (dist === 0) {
            return a;
        }
        const frac = r / dist;
        return {
            x: a.x - frac * dx,
            y: a.y - frac * dy
        }
    }

    render() {
        let x1, y1, x2, y2;
        const { arrow, exportMode } = this.props;
        const { stage, ui } = this.context;

        if (arrow.origin) {
            x1 = arrow.origin.layout.position.x;
            y1 = arrow.origin.layout.position.y;
        } else {
            x1 = stage.mouse.x;
            y1 = stage.mouse.y;
        }

        if (arrow.target) {
            x2 = arrow.target.layout.position.x;
            y2 = arrow.target.layout.position.y;
        } else {
            x2 = stage.mouse.x;
            y2 = stage.mouse.y;
        }


        const a = {x: x1, y: y1};
        const b = {x: x2, y: y2};

        let arrowStartP = {x: x1, y: y1}, arrowEndP = {x: x2, y: y2};

        const getRectDist = (record) => {
            return Math.min(record.planWidth, record.planHeight) * 0.75;
        };

        if (arrow.origin) {
            if (arrow.origin.layout.shape.type === shape.RECTANGLE || arrow.origin.layout.shape.type === shape.POLYGON) {
                arrowStartP = this.nodeEdge(a, b, getRectDist(arrow.origin));
            } else {
                arrowStartP = this.nodeEdge(a, b, arrow.origin.layout.shape.radius);
            }

        }
        if (arrow.target) {
            if (arrow.target.layout.shape.type === shape.RECTANGLE || arrow.target.layout.shape.type === shape.POLYGON) {
                arrowEndP = this.nodeEdge(b, a, getRectDist(arrow.target));
            } else {
                arrowEndP = this.nodeEdge(b, a, arrow.target.layout.shape.radius);
            }
        }

        if (exportMode) {
            return <path
                markerEnd={(arrow.selected ? `url(#arrowhead-selected)` : `url('#arrowhead-unselected')`)}
                stroke={'black'}
                d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
            />
        }


        return (
            <g className={"Arrow " + (this.state.dragging ? 'dragging' : '')}>
                <g>
                    <path
                        className={"arrow " + (arrow.selected ? 'editMode' : '') + ((!stage.showArrow && ui.layout === layout.PLAN) ? 'hide' : '')}
                        markerEnd={(arrow.selected ? `url(#arrowhead-selected)` : `url('#arrowhead-unselected')`)}
                        d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
                    />
                    <path
                        className={"clickArea"}
                        onClick={this.handleClick}
                        d={`M${arrowStartP.x} ${arrowStartP.y} L${arrowEndP.x} ${arrowEndP.y}`}
                    />
                </g>

                {arrow.selected &&
                <g>
                    <circle
                        className={"arrowOrigin"}
                        fill={"white"}
                        cx={arrowStartP.x}
                        cy={arrowStartP.y}
                        r={5}
                        onMouseDown={(e) => this.dragConnection(e, "origin")}
                    />
                    <circle
                        className={"arrowTarget"}
                        fill={"white"}
                        cx={arrowEndP.x}
                        cy={arrowEndP.y}
                        r={5}
                        onMouseDown={(e) => this.dragConnection(e, "target")}
                    />
                </g>}
            </g>
        );
    }
}

Arrow.defaultProps = {
    exportMode: false,
};

export default Arrow;
