import firebase from "firebase";

/*
Older files were stored in realtime DB. This helper identifies whether those files exist there and migrate them over to the new Firestore location
 */
export class FileMigrationHelper {
    private dbRef: firebase.firestore.Firestore;
    private app: firebase.app.App;

    constructor(app: firebase.app.App) {
        this.app = app;
        this.dbRef = app.firestore();
    }

    async getDataFromOldLocation(fileId: string) {
        const ref = this.app.database().ref(`/files/${fileId}`);
        const snapshot = await ref.once('value');
        return snapshot.val();
    }

    async copyToFireStore(fileId: string, snapshot:any) {
        const ref = this.dbRef.collection('files').doc(fileId);
        await ref.set(snapshot);
    }
}