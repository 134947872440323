export const sasakiPalette = [
    '#6376B9',
    '#945EA6',
    '#ED1E35',
    '#F47821',
    '#D8A428',
    '#89BB40',
    '#4D459C',
    '#702A82',
    '#871531',
    '#905E25',
    '#5E5825',
    '#005D30',
    '#8C99C1',
    '#A88CAC',
    '#B88F8F',
    '#AE9C80',
    '#999E72',
    '#80AC95',
    '#BFC9D9',
    '#D1C7D8',
    '#DEBFBE',
    '#D9CCB0',
    '#C6CEA6',
    '#BAD9C9',
]