import { useCallback, useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Input } from '@strategies/react-form';
import { Modal, Title, Body, Buttons, Button } from '@strategies/ui';

import { tool } from '../../stores/StageStore';
import { distance, stageToLinear } from '../../utils';


export default observer(function ScaleImageModal() {
    const { history, stage, ui, units } = useStores();
    const [length, setLength] = useState(0);

    const close = useCallback(() => {
        stage.clearToolData();
        ui.setScaleImageModal(false);
        stage.setTool(tool.PAN);
    }, [stage, ui]);

    const save = useCallback(() => {
        const [p1, p2] = stage.toolData;

        const m = stageToLinear(distance(p1, p2));
        console.log('DISTANCE IN M: ', m, p1, p2, distance(p1, p2));
        history.withGroup(() => (
            stage.image.setScale(stage.image.scale * length / units.toLinearUnits(m))
        ), 'scale background image');

        close();
    }, [stage, units, close, length, history]);

    return (
        <Modal
            className="ScaleImageModal"
            active={ui.scaleImageModal && stage.hasScaleCoords}
            onClose={close}
        >
            <Title>Scale Background Image</Title>

            <Body>
                <Input
                    type="increment"
                    value={length}
                    onChange={(v) => setLength(v as number)}
                    right={units.linearUnitsDisplay}
                />
            </Body>

            <Buttons>
                <Button className="secondary" onClick={close}>
                    Cancel
                </Button>

                <Button onClick={save}>
                    Save
                </Button>
            </Buttons>
        </Modal>
    );
});
