//@ts-nocheck
import React, {Component} from 'react';
import {observer} from "mobx-react";
import { StoresContext } from '@strategies/stores';

import {checkForContainment, checkForOverlap} from "../../utils";


@observer
class MarqueeTool extends Component {

    static contextType = StoresContext;

    constructor(props) {
        super(props);

        this.marqueeToolRef = React.createRef();

        this.state = {
            width: 0,
            height: 0,
            position: {x: 0, y: 0},
            originalPosition: {x: 0, y: 0}
        };

        this.selectionRect = {y: 0, x: 0, height: 0, width: 0};
        this.elRect = null;
        this.bboxes = [];
    }

    componentDidMount = () => {
        const { stage } = this.context;

        this.setState({
            position: {x: stage.mouse.x, y: stage.mouse.y},
            originalPosition: {x: stage.mouse.x, y: stage.mouse.y}
        });

        const onUp = () => {
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('mouseup', onUp);
            stage.setMarqueeSelect(false);
        };

        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', onUp);
    };

    onMouseMove = () => {
        const { records, stage } = this.context;
        const { mouse } = stage;
        let x, y;

        x = Math.min(this.state.originalPosition.x, mouse.x);
        y = Math.min(this.state.originalPosition.y, mouse.y);

        if (mouse.x - this.state.originalPosition.x < 0) {
            x = mouse.x;
        }

        if (mouse.y - this.state.originalPosition.y < 0) {
            y = mouse.y;
        }

        let width = Math.abs(mouse.x - this.state.originalPosition.x);
        let height = Math.abs(mouse.y - this.state.originalPosition.y);

        this.setState({
            position: {x: x, y: y},
            width: width,
            height: height
        });

        if (!(this.marqueeToolRef && this.marqueeToolRef.current)) return;

        const marqueeBBox = this.marqueeToolRef.current.getBoundingClientRect();

        const checkIntersection = (record) => {
            if (!record.svg) return false;
            //fast check with bounds first
            if (!checkForOverlap(record.svg.getBoundingClientRect(), marqueeBBox)) return false;
            //if fully contained, return true
            if (checkForContainment(record.svg.getBoundingClientRect(), marqueeBBox)) return true;

            //NOTE - update 3/31/2022 - this was causing a crash. It seems fine to use the bounding box alone for marquee selection
            // const poly = record.layout.shape.type === shape.CIRCLE
            //     ? ShapeInfo.circle({center: record.layout.position, radius: record.layout.shape.radius})
            //     : ShapeInfo.polygon(record.layout.shape.globalVertices);
            // const intersection = Intersection.intersect(marquee, poly);
            //
            // return intersection.points.length > 0;
        };

        records.all.forEach(record => {
            if (checkIntersection(record)) {
                record.setSelected();
            } else {
                record.setSelected(false);
            }
        });
    };

    render() {
        return (
            <g>
                <rect
                    ref={this.marqueeToolRef}
                    className={"marqueeTool"}
                    stroke={"black"}
                    strokeDasharray={"4"}
                    fill={"transparent"}
                    x={this.state.position.x}
                    y={this.state.position.y}
                    width={this.state.width}
                    height={this.state.height}
                />
            </g>
        );
    }
}

export default MarqueeTool;
