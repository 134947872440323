import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';


@model('pt/User')
class User extends Model({
    name: prop<string>(),
    email: prop<string>(),
    photoURL: prop<string>(''),
    lastActive: prop<number>(() => Date.now()),
    lastModified: prop<number>(0),
}) {

    @computed
    get lastModifiedDate() {
        return new Date(this.lastModified);
    }

    @modelAction
    updateLastModified() {
        this.lastModified = Date.now();
    }

    @modelAction
    updateLastActive() {
        this.lastActive = Date.now();
    }
}


export default User;
