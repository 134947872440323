import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import CreatableSelect from 'react-select/creatable';
import { StylesConfig, MenuPlacement } from "react-select";

import chroma from 'chroma-js'

import Category from "../../models/Category";

type SelectCategoryProps = {
    category: Category | undefined | null;
    setCategory: (newValue: Category) => void;
    menuPlacement?: MenuPlacement;
};

export interface ColourOption {
    readonly value?: string;
    readonly label?: string;
    readonly color?: string;
}
const menuPortalTarget = document.getElementById('app-root');

export const SelectCategory = observer(({ category, setCategory, menuPlacement = 'top' }: SelectCategoryProps) => {
    const { ui, categories, history, supermodel: { file } } = useStores();
    const onCreateOption = (name: string) => {
        history.withGroup(() => {
            const newCategory = categories.create(name);
            ui.setEditCategory(newCategory);
            setCategory(newCategory);
        })
    }

    const colourStyles: StylesConfig<ColourOption, true> = {
        singleValue: (provided, { isDisabled, data, hasValue }) => {
            const opacity = isDisabled ? 0.3 : 1;
            const transition = 'opacity 300ms';
            const backgroundColor = chroma(data.color || "white");
            const color = chroma("white");
            const maxWidth = "min-content";
            const borderRadius = "5px";
            const padding = "5px 8px 7px 7px";
            return {
                ...provided,
                opacity,
                transition,
                backgroundColor: data.value? backgroundColor.hex() : "none",
                color: color.hex(),
                maxWidth,
                borderRadius,
                padding,


            };
        },
        input: (provided, state) => ({
            ...provided,
            background: "none",
            borderBlock: "hidden",
            borderStyle: "hidden",


        }),

        indicatorsContainer: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        clearIndicator: (provided) => (
            {
                ...provided,
                display: "none"

            }),
        control: (styles, { selectProps: { menuIsOpen, blurInputOnSelect}, isFocused }) =>
            ({
                ...styles,
                paddingLeft: "5px",
                backgroundClip: "padding-box",
                wordWrap: "break-word",
                backgroundColor: "none",
                borderRadius: 0,
                textTransform: "uppercase",
                color: menuIsOpen ? "white" : "black",
                cursor: isFocused ? "initial" : "pointer",
                border: isFocused ? "hidden" : menuIsOpen? "hidden" : "none",
                boxShadow: "none",
            }),
        menuList: (provided, { focusedOption }) => (
            {
                ...provided,
                overflowX: "hidden"

            }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color || "white");
            return {
                ...styles,
                textTransform: "uppercase",
                margin: "10px",
                padding: "2px 5px 4px 5px",
                borderRadius: "5px",
                backgroundColor: color.hex(),
                color: data.color ? "white" : "black",
                cursor: isDisabled ? 'not-allowed' : isFocused ? 'pointer' : isSelected ? 'default' : 'default',
                maxWidth: "fit-content"

            };
        },

        noOptionsMessage:(provided) => (
            {
                ...provided,

            })
    };
    return (
        <div className={'SelectCategory'}>
            <CreatableSelect
                menuPortalTarget={menuPortalTarget}
                isClearable
                menuShouldScrollIntoView
                className={"SelectCategory-create"}
                classNamePrefix="SelectCategory"
                menuShouldBlockScroll={false}
                onChange={(value: any, meta) => {
                    const category = file.categories.find((category: Category) => category.id === value?.value)
                    history.withGroup(() => setCategory(category), 'set category');
                }
                }
                onCreateOption={(name) => onCreateOption(name)}
                options={file.categories.map((c: Category) => {
                    return { label: c.name, value: c.id, color: c.color }
                })}
                menuPlacement={menuPlacement}
                value={{ label: category?.name, value: category?.id, color: category?.color }}
                styles={colourStyles}
            />
        </div>
    )
});
