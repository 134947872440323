import firebase from "firebase";
import { action, computed, observable } from 'mobx';
import {
    applySnapshot,
    fromSnapshot,
    getSnapshot,
    Model,
    model,
    modelAction,
    prop,
    SnapshotOutOf
} from 'mobx-keystone';

import pkg from '../../package.json';

import Image from '../models/Image';
import Arrow from '../models/Arrow';
import Record from '../models/Record';
import Category from '../models/Category';
import Project from '../models/Project';
import { IFile } from "@strategies/collaborate-on-fire";
import versions from "../versions";
import record from "../models/Record";
import ExportOptions from "./ExportOptions";

@model('pt/File')
class File extends Model({
    version: prop<string>(pkg.version),
    fileId: prop<string>(''),
    fileName: prop<string>('untitled'),

    image: prop<Image>(() => new Image({})),
    exportOptions: prop<ExportOptions>(() => new ExportOptions({})),

    project: prop<Project>(() => new Project({})),
    arrows: prop<Arrow[]>(() => []),
    records: prop<Record[]>(() => []),
    categories: prop<Category[]>(() => []),
}) implements IFile {

    onInit() {
        this.updateCurrentTime();
    }

    @observable
    currentTime: number = 0;

    disableLoadSnapshot: boolean  = false;

    @action
    updateCurrentTime() {
        this.currentTime = Date.now();
    }

    @modelAction
    setFileId(id: string) {
        this.fileId = id;
    }

    @modelAction
    setFileName(name: string) {
        this.fileName = name;
    }

    @modelAction
    loadSnapshot(snapshot: SnapshotOutOf<File>) {
        if (this.disableLoadSnapshot) return;
        applySnapshot<File>(this, versions.upgrade(snapshot));
    }

    // @modelAction
    // addUser(user: User) {
    //     if (this.users) {
    //         if (this.users.length < 1) {
    //             if (user) {
    //                 this.users.push(user);
    //             }
    //         }
    //         else {
    //             let userExists = false;
    //             for (let userObj of this.users) {
    //                 if (userObj.email === user.email) {
    //                     userExists = true;
    //                     break;
    //                 }
    //             }
    //             if (!userExists) {
    //                 this.users.push(user);
    //             }
    //         }
    //     }
    // }

    @modelAction
    setImage(name: string, url: string, size: number[]) {
        this.image = new Image({ name, url, size });
    }

    @modelAction
    addCategory(name: string): Category {
        const category = new Category({ name });
        this.categories.push(category);

        return category;
    }

    @modelAction
    removeCategory(category: Category) {
        this.categories.splice(this.categories.indexOf(category), 1);
    }

    @modelAction
    addRecord(): Record {
        const record = new Record({});
        this.records.push(record);

        return record;
    }

    @modelAction
    copyRecord(record: Record): Record {
        const copy = fromSnapshot<Record>(getSnapshot<Record>(record));
        copy.regenerateId();
        this.records.push(copy);

        return copy;
    }

    @modelAction
    removeRecord(record: Record) {
        if (record.arrows.length > 0) {
            throw new Error("can't remove Record: contains Arrows; use stores.records.remove()!");
        }

        this.records.splice(this.records.indexOf(record), 1);
    }

    @modelAction
    addArrow(origin: Record, target: Record): Arrow {
        const arrow = new Arrow({});

        arrow.setOrigin(origin);
        arrow.setTarget(target);
        this.arrows.push(arrow);

        return arrow;
    }

    @modelAction
    removeArrow(arrow: Arrow) {
        this.arrows.splice(this.arrows.indexOf(arrow), 1);
    }

    @observable
    saving: boolean = false;

    @modelAction
    setSaving(saving: boolean = true) {
        this.saving = saving;
    }

    @computed
    get dbFileRef() {
        return firebase.database().ref(`/files/${this.fileId}`);
    }

    @computed
    get fileNameWithExtension() {
        return this.fileName + '.prgm';
    }

    @computed
    get blankSnapshot():any {
        const newFile = new File({});
        return getSnapshot(newFile);
    }

    @modelAction
    empty() {
        //reset all data, but keep id and name
        const {fileId, fileName} = this;
        applySnapshot<File>(this, this.blankSnapshot);
        this.fileId = fileId;
        this.fileName = fileName;
    }
}


export default File;
