import { observer } from 'mobx-react';

import Bar from './Bar';

type FillBarProps = {
    label: string;
    value: number;
    total: number;
    format: (v: number) => string;
    ramp: string[];
}

export default observer(function FillBar({ label, value, total, format, ramp }: FillBarProps) {
    return (
        <div className="BarChart FillBar">
            <label>{label}</label>

            <div className="BarChart__graphic">
                <Bar values={value} total={total} ramp={ramp} />

                <div className="FillBar__value">
                    {format(value)}
                </div>
            </div>
        </div>
    );
});
