//@ts-nocheck
import React, { Component } from 'react';
import { StoresContext } from '@strategies/stores';
import { observer } from "mobx-react";
import {Description } from '@strategies/react-form';
import { Body, Button, Buttons, Modal, SubTitle, Title } from "@strategies/ui";



@observer
class InfoModal extends Component {

    static contextType = StoresContext;

    constructor(props: any) {
        super(props);
    }

    getSectionTitle(title) {
        return (<div className="section-title">
            {title}
        </div>);
    }
    getDescription(title) {
        return (<Description className="desc">
            {title}
        </Description>);
    }
    getDivider() {
        return (<hr style={{
            backgroundColor: '#7d7a96',
            height: 1,
            width: '100%',
            marginTop: '10px'
        }}/>);
    }



    render() {

        const { ui } = this.context;

            return (
                <Modal
                    className="InfoModal"
                    active={ui.infoModal}
                    onClose={()=>ui.setInfoModal(false)}
                >
                    <Title>{"Information"}</Title>
                    <SubTitle>{"Product and project overview information."}</SubTitle>

                    <Body>
                        <div className="content">
                            {this.getSectionTitle("Product")}
                            {this.getDescription("Programme by SASAKI")}
                            {this.getDescription("Understand and test programming ideas based on various project costs and size. Understand project trade-offs early in your project by planning programmatic adjacencies and setting corresponding cost analysis. Import an early stage drawing to visualize program relationships on underlays.")}

                            {this.getDivider()}
                            {this.getSectionTitle("Contact")}
                            {this.getDescription("strategies@sasaki.com")}

                            {this.getDivider()}
                            {this.getSectionTitle("user guide")}
                            {this.getDescription("LINK")}
                        </div>


                    </Body>
                    <Buttons>
                        <Button onClick={() => {
                            ui.setInfoModal(false);
                        }}>
                            Done
                        </Button>
                    </Buttons>

                </Modal>

            );


    }
}


export default InfoModal;
