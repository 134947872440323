import { observer } from 'mobx-react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Panel, BlockProps, Header, IconButton } from '@strategies/ui';


type InnerPanelProps = BlockProps & {
    active: boolean;
    setActive: (active: boolean) => void;
}

export default observer(function InnerPanel(props: InnerPanelProps) {
    const { active, className = '', children, setActive } = props;

    return (
        <Panel className={`InnerPanel ${className}`} active={active}>
            <Header>
                <IconButton onClick={() => setActive(!active)}>
                    {active ? <FiMinus /> : <FiPlus />}
                </IconButton>
            </Header>

            {children} 
        </Panel>
    );
});
