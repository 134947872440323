import { Store, stores } from '@strategies/stores';
import { computed, makeObservable } from 'mobx';

import { guessAtRounding } from '../utils';


export const M_TO_FT = 3.28084;
export const SQM_TO_SQFT = M_TO_FT * M_TO_FT;


export enum unit {
    FEET,
    METERS
}

export const toUnit = (unitName: string) => {
    switch (unitName) {
        case 'Feet':
            return unit.FEET;
        case 'Meters':
            return unit.METERS;
    }
    throw new Error(`Invalid unit ${unitName}`);
}
export const fromUnit = (v: unit) => {
    switch (v) {
        case unit.FEET:
            return 'Feet';
        case unit.METERS:
            return 'Meters';
    }
}
export const unitOptions = ['Feet', 'Meters'];

export default class UnitsStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get unit() {
        return stores.supermodel.users.current.unit as unit;
    }

    setUnit(unit: unit) {
        stores.supermodel.users.current.setUnit(unit);
    }

    toggleUnit() {
        this.setUnit(this.unit === unit.FEET ? unit.FEET : unit.METERS);
    }

    toSqUnits(x: number) {
        if (this.unit === unit.METERS) {
            return x;
        }

        return x * SQM_TO_SQFT;
    }

    @computed
    get currencyUnitsDisplay() {
        return '$';
    }

    @computed
    get squareUnitsDisplay() {
        if (this.unit === unit.METERS) {
            return 'm²';
        }
        return 'ft²';
    }

    @computed
    get linearUnitsDisplay() {
        if (this.unit === unit.METERS) {
            return 'm';
        }
        return 'ft'
    }

    guessAtRounding(valM: number) {
        if (this.unit === unit.METERS) {
            return guessAtRounding(valM);
        }
        return guessAtRounding(valM * SQM_TO_SQFT) / SQM_TO_SQFT;
    }

    fromSqUnits(val: number) {
        if (this.unit === unit.METERS) {
            return val;
        }
        return val / SQM_TO_SQFT;
    }

    toLinearUnits(val: number) {
        if (this.unit === unit.METERS) {
            return val;
        }
        return val * M_TO_FT;
    }

    fromLinearUnits(val: number) {
        if (this.unit === unit.METERS) {
            return val;
        }
        return val / M_TO_FT;
    }

    // TODO refactor: This is indirection
    getDisplayText(genericText: string) {
        if (genericText === '$/unit') {
            return this.currencyUnitsDisplay + '/' + this.squareUnitsDisplay;
        }
    }

    displayArea(valM: number) {
        return this.formatArea(this.toSqUnits(valM)) + ' ' + this.squareUnitsDisplay;
    }

    displayLinear(valM: number) {
        return this.formatLinear(this.toLinearUnits(valM)) + ' ' + this.linearUnitsDisplay;
    }

    formatAreaLabel(val: number) {
        return this.formatArea(val) + ' ' + this.squareUnitsDisplay;
    }

    formatArea(val: number) {
        return Math.round(val).toLocaleString();
    }

    formatLinearLabel(val: number) {
        return this.formatLinear(val) + ' ' + this.squareUnitsDisplay;
    }

    formatLinear(val: number): string {
        return Math.round(val).toLocaleString();
    }

}
