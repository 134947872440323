//@ts-nocheck
import React, {Component} from 'react';
import {observer} from "mobx-react";
import { StoresContext } from '@strategies/stores';
import File from "../../models/File";


@observer
class Circle extends Component {

    static contextType = StoresContext;
    private objRef: React.RefObject<SVGGraphicsElement>;

    constructor(props) {
        super(props);
        this.objRef = React.createRef();
    }

    componentDidMount() {
        if (this.objRef.current) {
            this.props.record.setSVGElement(this.objRef.current);
        }
    }

    render() {
        const { exportMode, record } = this.props;
        const { layout } = record;
        const { stage, units } = this.context;

        const rotationString = `rotate(${layout.rotation}, ${layout.width / 2}, ${layout.height / 2})`;

        const textAreaStyle = {
            width: layout.width,
            height: layout.height
        };

        const fontSize = (baseVal) => {
            const logScale = Math.pow(stage.scale, 0.75);
            return (baseVal / logScale);
        };

        const textStyle = (baseVal, wrap) => {
            const style = {fontSize: fontSize(baseVal) + 'em'};

            if (!wrap) {
                style.whiteSpace = 'nowrap';
            }

            return style;
        };

        const scaledSize = {
            width: stage.scale * layout.width,
            height: stage.scale * layout.height,
        };

        const breakPoints = {//calibrated
            singleRowMax: {
                small: {scale: 0.75, height: 24},
                large: {scale: 5, height: 90},
            },
            doubleRowMax: {
                small: {scale: 0.75, height: 42},
                large: {scale: 5, height: 125},
            },
            tripleRowMax: {
                small: {scale: 0.75, height: 60},
                large: {scale: 5, height: 160},
            },
            widthMax: {
                small: {scale: 0.35, width: 40},
                large: {scale: 5, width: 150},
            }
        };

        const breakPoint = (key, prop) => {
            const bp = breakPoints[key];
            //use linear interpolation between small and large breakpoints to get breakpoint 'height' for current scale
            const norm = (stage.scale - bp.small.scale) / (bp.large.scale - bp.small.scale);
            return bp.small[prop] + norm * (bp.large[prop] - bp.small[prop]);
        };

        const nameWrap = () => {
            return scaledSize.height > breakPoint('tripleRowMax', 'height');
        };

        const canDisplayName = () => {
            return scaledSize.height > breakPoint('doubleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        const canDisplayArea = () => {
            return scaledSize.height > breakPoint('singleRowMax', 'height') && scaledSize.width > breakPoint('widthMax', 'width');
        };

        const labelSize = Math.sqrt(layout.shape.radius * layout.shape.radius * 2);

        if (exportMode) {
            const file = this.context.supermodel.file as File;

            let nameFontSize = 12;
            let areaFontSize = 12;

            if (file.exportOptions) {
                nameFontSize *= file.exportOptions.nameScale;
                areaFontSize *= file.exportOptions.areaScale;
            }

            return (
                <g transform={rotationString}>
                    <circle
                        fill={record.color}
                        r={layout.shape.radius}/>

                    <g>
                        {nameFontSize && <text fontSize={nameFontSize} textAnchor="middle" fill={'#111111'} y={-10}>{record.name}</text>}
                        {areaFontSize && <text fontSize={areaFontSize} textAnchor="middle" fill={'#222222'} y={10}>{units.displayArea(record.targetNetM)}</text>}
                    </g>
                </g>
            );
        }

        return (
            <g className="Circle" transform={rotationString}>

                <circle
                    fill={record.color}
                    r={layout.shape.radius}/>

                {!this.props.editLabel &&
                <foreignObject
                    className="label"
                    x={-labelSize / 2}
                    y={-labelSize / 2}
                    width={labelSize}
                    height={labelSize}>
                    <div className="label-text">
                        <div className="label-text-inner">
                            {canDisplayName() && <div className="label-name"
                                                      style={textStyle(0.9, nameWrap())}>{record.name}</div>}
                            {canDisplayArea() && <div className="label-area"
                                                      style={textStyle(1.0, false)}>{units.displayArea(record.targetNetM)}</div>}
                        </div>
                    </div>
                </foreignObject>}

                <circle
                    ref={this.objRef}
                    className={'object ' + ((record.selected) ? 'selected ' : ' ') + ((record.active) ? 'active' : '')}
                    onMouseDown={this.props.handleMouseDown}
                    onDoubleClick={this.props.handleEditingName}
                    onClick={this.props.handleMouseClick}
                    onMouseEnter={this.props.handleMouseEnter}
                    onMouseLeave={this.props.handleMouseLeave}
                    fill={"transparent"}
                    r={layout.shape.radius}/>


                {this.props.editLabel &&
                <foreignObject
                    className="label"
                    x={-labelSize / 2}
                    y={-labelSize / 2}
                    width={labelSize}
                    height={labelSize}>
                    <div className="name-edit">
                            <textarea
                                autoFocus
                                style={textAreaStyle}
                                value={record.name}
                                onMouseDown={(e) => e.stopPropagation()}
                                onKeyPress={this.props.handleKeyPress}
                                onChange={(e) => this.props.editName(e.target.value)}
                            />
                    </div>
                </foreignObject>}
            </g>
        );
    }
}

Circle.defaultProps = {
    exportMode: false,
};
export default Circle;
