import { ChangeEvent, useEffect } from 'react';
import { observer } from "mobx-react";
import ReactTooltip from 'react-tooltip';
import { useStores } from '@strategies/stores';
import { RadioSlider } from '@strategies/react-form';
import { TopRibbon, Logo, Collapsible, Body, IconButton } from '@strategies/ui';
import { FiInfo, FiSave, FiSettings, FiDownload, FiLoader } from 'react-icons/fi';

import { layout } from '../../models/Layout';
import ProgrammeLogo from '../../assets/PROGRAMME-01-01.png';
import SuperModel from "../../stores/SuperModelStore";
import { UserPhoto } from "../ActiveUsers";
import UIStore from "../../stores/UIStore";


export default observer(function MenuBar() {
    const { supermodel: { file } } = useStores();
    const ui = useStores().ui as UIStore;
    const supermodel = useStores().supermodel as SuperModel;
    const { localUser } = supermodel.users;
    const userCount = supermodel.users.remoteUsers.length + 1;

    useEffect(() => ReactTooltip.rebuild());

    return (
        <TopRibbon className="MenuBar">
            <Logo><img src={ProgrammeLogo} alt="Programme Logo"/></Logo>

            <Collapsible>
                <IconButton
                    data-tip="App Info"
                    data-place="bottom"
                    onClick={() => ui.setInfoModal(true)}
                >
                    <FiInfo />
                </IconButton>

                <IconButton
                    data-tip="Settings"
                    data-place="bottom"
                    onClick={() => ui.setSettingsModal(true)}
                >
                    <FiSettings />
                </IconButton>

                <IconButton
                    data-tip="Export"
                    data-place="bottom"
                    onClick={() => ui.setExportModal(true)}
                >
                    <FiDownload />
                </IconButton>

                <IconButton
                    data-tip="Create"
                    data-place="bottom"
                    onClick={() => ui.setForceIntroModal(true)}
                >
                    <FiLoader />
                </IconButton>

                <IconButton
                    data-tip="Save As"
                    data-place="bottom"
                    onClick={() => ui.setCopyModal(true)}
                >
                    <FiSave />
                </IconButton>
            </Collapsible>

            <Body>
                <div className="file-input">
                    <label htmlFor="FileName">NAME</label>
                    <input
                        id="FileName"
                        placeholder="File name ..."
                        value={file.fileId ? file.fileName : '-'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => file.setFileName(e.target.value)}
                        onBlur={() => {
                            if (supermodel.appStateManager) {
                                supermodel.appStateManager.updateFileName();
                            }
                        }}
                    />
                </div>

                {localUser &&
                    <button
                        className={`ss-button sq active-users-button}`}
                        onClick={(e) => ui.setShowActiveUsers(!(ui.showActiveUsers >= 0), e.clientX)}
                    >
                        <UserPhoto color={localUser.color} photoURL={localUser.photoURL || undefined}
                                   name={localUser.name}/>
                        {userCount > 1 &&
                            <div className="counter">{userCount}</div>}
                    </button>
                }

                <RadioSlider
                    value={ui.layout}
                    onChange={(v) => ui.setLayout(v as layout)}
                    options={Object.values(layout)}
                />
            </Body>
        </TopRibbon>
    );
});
