import firebase from 'firebase';
import Jimp from 'jimp';
import md5 from 'md5';

function repath(value:string) {
    value = value.replace('https://firebasestorage.googleapis.com/v0/b/programming-tool-584cc.appspot.com/o/images%2F', '');
    return `https://storage.googleapis.com/programming-tool-584cc.appspot.com/images/${value.split('?alt=media&token=')[0]}`;
}

export default class ImageHandler {
    storageRef: firebase.storage.Reference;

    constructor() {
        this.storageRef = firebase.storage().ref();
    }

    async processImage(buffer:Buffer, fileType:string) {
        const arr = new Uint8Array(buffer);
        const hash = md5(arr);
        const ref = this.storageRef.child(`images/${hash}.${fileType}`);
        const mimetype = fileType === 'png' ? Jimp.MIME_PNG : Jimp.MIME_JPEG;

        let url;

        try {//TODO store metadata on images so that if the image already exists, we can know image dimensions etc
            url = repath(await ref.getDownloadURL());// already exists...
        } catch (e) {
            const putOptions = {
                contentType: mimetype,
            };
            await ref.put(buffer, putOptions);
            const image = await Jimp.read(buffer);

            const refSize = this.storageRef.child(`images/${hash}.${fileType}`);
            await refSize.put(await image.getBufferAsync(mimetype), putOptions);
            url = repath(await refSize.getDownloadURL());
        }
        return url;
    }
}
