//alternative: https://raw.githubusercontent.com/CeamKrier/peeps-generator/21ed8f643b833ac43dd42a2aca762a5701068a3f/src/utils/save.tsx

import * as FileSaver from 'file-saver';

const saveSvg = (svgEl: HTMLElement, name: string) => {
    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const svgData = svgEl.outerHTML;
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const svgBlob = new Blob([preface, svgData], {
        type: 'image/svg+xml',
    });
    downloadResource(svgBlob, name);
};

const savePng = (
    svgEl: HTMLElement,
    name: string,
    scaleVector: number
) => {
    const canvas = document.createElement('canvas');
    canvas.width = svgEl.getBoundingClientRect().width * scaleVector;
    canvas.height = svgEl.getBoundingClientRect().height * scaleVector;

    const cleanup = function () {
        document.body.removeChild(canvas);
    }

    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    const svgData = svgEl.outerHTML;
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';

    const canvasContext = canvas.getContext('2d');

    const DOMURL = window.self.URL || window.self.webkitURL || window.self;
    const image = new Image();
    const svgBlob = new Blob([preface, svgData], {
        type: 'image/svg+xml',
    });
    const url = DOMURL.createObjectURL(svgBlob);

    canvas.style.display = 'none';
    document.body.appendChild(canvas);

    image.onload = () => {
        if (!canvasContext) {
            return;
        }

        canvasContext.drawImage(image, 0, 0, canvas.width, canvas.height);

        DOMURL.revokeObjectURL(url);
        canvas.toBlob((pngBlob) => {
            pngBlob && downloadResource(pngBlob, name);
            cleanup();
        });
    };

    image.src = url;
};

const downloadResource = (resource: Blob, name: string) => {
    FileSaver.saveAs(resource, name);
};

export const getDataUrlForImage = (url: string, callback: (dataUrl: string) => void) => {
    fetch(url)
        .then(response => response.blob())
        .then(convertBlobToBase64)
        .then((base64: any) => {
            callback(base64);
        })
};

const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});


export default class SvgImageDownloader {
    downloadSVG(selector: any, name: string = 'download') {
        const svg = document.querySelector(selector);
        saveSvg(svg, name);
    }

    downloadSVGAsPNG(selector: any, name: string = 'download', scaleVector: number = 1) {
        const svg = document.querySelector(selector);
        savePng(svg, name, scaleVector);
    }
}