import { Store, stores } from '@strategies/stores';
import { SnapshotOutOf, getSnapshot, fromSnapshot } from 'mobx-keystone';

import Record from '../models/Record';
import { BoundingBox } from '../models/Shape';
import { combineBBoxes } from '../utils';


export default class ClipboardStore extends Store {

    value: SnapshotOutOf<Record>[]|null = null;

    copy() {
        this.clipboard = stores.records.selected.map((record: Record) => getSnapshot<Record>(record));
    }

    cut() {
        const { records } = stores;

        this.copy();
        records.selected.forEach((record: Record)  => records.remove(record));
    }

    paste(e: KeyboardEvent) {
        const { history, records, stage, ui } = stores;

        if (ui.spreadsheetPasteModal) {//don't override default paste if spreadsheet paste modal is open
            return;
        }

        if (this.clipboard !== null) {
            e.preventDefault();

            const bboxes: BoundingBox[] = [];
            const newRecords: Record[] = [];

            history.withGroup(() => {
                // two loops are used to account for copying, then deleting the original record, then pasting
                this.clipboard.forEach((r: any) => {
                    const record = records.copy(fromSnapshot<Record>(r));
                    bboxes.push(record.layout.boundingBox);
                    newRecords.push(record);
                });

                const combinedBBox = combineBBoxes(bboxes);

                records.clearSelected();

                const offset = {
                    x: stage.mouse.x - combinedBBox.x,
                    y: stage.mouse.y - combinedBBox.y
                };

                newRecords.forEach(record => {
                    record.layout.move(offset.x, offset.y);
                    record.setEditingName(false);
                    record.setSelected();
                });
            }, 'copy/paste');
        }
    }

}
