import React, { useCallback, useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Body, Modal, Title } from "@strategies/ui";

import SpreadsheetPaste from "../Table/SpreadsheetPaste";

export default observer(function GenerateFromTable() {
    const [generating, setGenerating] = useState(true);
    const { ui } = useStores();

    const close = useCallback(() => {
        ui.setSpreadsheetPasteModal(false);
        setGenerating(false);
    }, [ui]);

    return(
        <Modal active={ui.spreadsheetPasteModal} onClose={!generating ? close : undefined} className="GenerateFromTable">
            <Title>Generate From Table</Title>
            <Body>
                <p>
                    Organize your data in Excel or Google Sheets. Copy and paste it into the table below. There is
                    a limit of 150 rows per import. Only Size and Quantity are required.
                </p>
                <p>
                    Note that if you enter e.g. '5' in 'quantity', 5 identical spaces will be generated. Gaps (blanks) in the data will be ignored.
                    You can import additional data at any point during your project. Please be patient after pressing 'Create Rooms' if generating large number of spaces.
                    It can take a minute or two.
                </p>

                <SpreadsheetPaste />

            </Body>

        </Modal>
    )
});