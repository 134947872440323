//@ts-nocheck
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { observer } from "mobx-react";
import { StoresContext } from '@strategies/stores';

import { shape } from '../../models/Shape';
import { tool } from '../../stores/StageStore';
import PlanShape from "./PlanShape";
import Circle from "./Circle";
import Tooltip from "./Tooltip";

const appRoot = document.getElementById('app-root');


@observer
class Shape extends Component {

    static contextType = StoresContext;

    constructor(props) {
        super(props);

        this.state = {
            editLabel: false,
        };
    }

    componentDidMount() {
        if (this.props.record.editingName) {
            this.handleEditingName();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.textEditCancel);
    }

    handleMouseEnter = () => {
        const { stage } = this.context;
        if (stage.marqueeSelect) return;// prevent flicker

        this.context.records.active.forEach((r) => {//ensure only one active record on plan (otherwise they get 'stuck' in that state)
            r.setActive(r.id === this.props.record.id);
        });

        this.props.record.setActive();
    }

    handleMouseLeave = () => {
        this.props.record.setActive(false);
    };

    handleMouseClick = (e) => {
        const { records, stage } = this.context;
        const { record } = this.props;

        if (stage.tool === tool.PAN) {
            e.stopPropagation();

            if (e.ctrlKey) {
                record.setSelected(!record.selected);
            }
            else if (!record.selected) {
                records.clearSelected();
                record.setSelected();
            }
        }
    };

    handleMouseDown = (e) => {
        const { history, records, stage } = this.context;
        const { record } = this.props;

        if (stage.tool === tool.PAN) {
            history.startGroup('move shape');
            e.stopPropagation();

            if (!record.selected && !e.ctrlKey) {
                records.clearSelected();
            }

            stage.setDragging();

            if (e.altKey) {
                records.selected.forEach(record => {
                    records.copy(record);
                });
            }

            let startX = stage.mouse.x;
            let startY = stage.mouse.y;

            const onMove = () => {
                if (!record.selected) {
                    record.setSelected();
                }

                records.selected.forEach(record => {
                    if (!record.layout.isPlaced) {
                        record.layout.place();
                    }
                    record.layout.move(stage.mouse.x - startX, stage.mouse.y - startY);
                });

                startX = stage.mouse.x;
                startY = stage.mouse.y;
            };

            const onUp = () => {
                history.stopGroup();
                stage.setDragging(false);
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp)
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
        }
    };

    handleEditingName = () => {
        this.props.record.setEditingName();
        this.setState({
            editLabel: true
        });
        document.addEventListener('mouseup', this.textEditCancel);
    };

    textEditCancel = (e) => {
        this.props.record.setEditingName(false);
        if (e.target.type === "textarea") return;
        this.setState({
            editLabel: false
        });
        document.removeEventListener('mouseup', this.textEditCancel);
    };

    render() {
        const { exportMode, record } = this.props;
        const { stage } = this.context;
        const globalTranslateString = `translate(${record.layout.position.x}, ${record.layout.position.y})`;

        if (exportMode) {
            return (
                <g transform={globalTranslateString}>
                    {(record.layout.shape.type === shape.RECTANGLE || record.layout.shape.type === shape.POLYGON) &&
                        <PlanShape
                            exportMode={exportMode}
                            record={record}
                        />
                    }
                    {record.layout.shape.type === shape.CIRCLE &&
                        <Circle
                            exportMode={exportMode}
                            record={record}
                        />}
                </g>
            );
        }

        return (
            <g className={`Shape ${record.isActive ? 'Shape--active' : ''}`} transform={globalTranslateString}>
                {(record.layout.shape.type === shape.RECTANGLE || record.layout.shape.type === shape.POLYGON) && (
                    <>
                        <PlanShape
                            record={record}
                            handleMouseDown={this.handleMouseDown}
                            handleEditingName={this.handleEditingName}
                            handleMouseClick={this.handleMouseClick}
                            handleMouseEnter={this.handleMouseEnter}
                            handleMouseLeave={this.handleMouseLeave}
                            handleKeyPress={() => {
                            }}
                            editName={name => record.setName(name)}
                            editLabel={this.state.editLabel}
                        />

                        {record.active && !stage.marqueeSelect && ReactDOM.createPortal(<Tooltip
                            record={record}/>, appRoot)}
                    </>
                )}

                {record.layout.shape.type === shape.CIRCLE &&
                    <>
                        <Circle
                            record={record}
                            handleMouseDown={this.handleMouseDown}
                            handleEditingName={this.handleEditingName}
                            handleMouseClick={this.handleMouseClick}
                            handleMouseEnter={this.handleMouseEnter}
                            handleMouseLeave={this.handleMouseLeave}
                            handleKeyPress={() => {
                            }}
                            editName={name => record.setName(name)}
                            editLabel={this.state.editLabel}
                        />
                        {record.active && !stage.marqueeSelect && ReactDOM.createPortal(<Tooltip targetMode={true}
                            record={record}/>, appRoot)}</>

                }
            </g>
        );
    }
}


Shape.defaultProps = {
    exportMode: false,
};
export default Shape;
