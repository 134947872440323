import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Title, Body, Button  } from '@strategies/ui';

import InnerPanel from './InnerPanel';
import Record from '../../../models/Record';
import ClampedBar from '../charting/ClampedBar';
import DimensionsInnerPanel from "./DimensionsInnerPanel";


export default observer(function SelectionInnerPanel() {
    const { app, records, units, ui } = useStores();
    const _SF = units.formatAreaLabel.bind(units);

    return records.selected.length > 0 ? (
        <>
            <InnerPanel 
                className="ElementsInnerPanel"
                active={ui.selectionInnerPanel}
                setActive={active => ui.setSelectionInnerPanel(active)}
            >
                <Title>Selection</Title>
                <Body>
                    {records.selected.length <= 5 ? records.selected.map((record: Record) => {
                        const {name, squareUnits, targetNetUnits, targetGrossUnits, color} = record;
                        const inRange = !record.hasWarnings;

                        return (
                            <div key={record.id} className={`record ${!inRange ? 'out-of-range' : null} ${app.inPresentationMode ? 'presentationMode' : null}`}>
                                <div className={"record_name_info"}>
                                    <div className={"name__info"}>
                                        <div className={"name__info__label"} >Name</div>
                                        <div className={"name__info__value"} >
                                            <label>{name || 'Unnamed'}</label>
                                    </div>
                                    </div>
                                    <div className={"efficiency__info"}>
                                        <div className={"efficiency__info__label"} >Efficiency</div>
                                        <span className="efficiency__info__value"><label>{Math.round(100 * squareUnits / targetGrossUnits)} %</label></span>

                                    </div>
                                </div>
                                <ClampedBar
                                    label={"Drawn"}
                                    clamp={{min: targetNetUnits, max: targetGrossUnits}}
                                    values={squareUnits}
                                    ramp={[color]}
                                    format={_SF}
                                    presentationMode={app.inPresentationMode}
                                    onMatchTarget={mid => record.setSquareUnits(mid)}
                                />

                                <div className="record__info">
                                    <span></span>
                                    <span>Net</span>
                                    <span>Gross</span>
                                </div>
                            </div>
                        );
                    }) : <>
                        <Selector name="under target" selection={records.selectedUnderTarget}  />
                        <Selector name="at target" selection={records.selectedAtTarget}  />
                        <Selector name="over target" selection={records.selectedOverTarget}  />
                    </>}
                </Body>
            </InnerPanel>
            <DimensionsInnerPanel/>
        </>

    ) : <></>;
});


function Selector({ name, selection }: { name: string, selection: Record[] }) {
    const { records } = useStores();

    return selection.length > 0 ? (
        <div className="Selector">
            <span>
                {selection.length} element{selection.length !== 1 ? 's' : ''} {name}
            </span>

            <Button className="secondary" onClick={() => {
                records.clearSelected();
                selection.forEach(record => record.setSelected());
            }}>
                Make Selection
            </Button>
        </div>
    ) : <></>;
}
