import { computed, override } from 'mobx';
import { ExtendedModel, Model, model, modelAction, objectMap, prop } from 'mobx-keystone';
import { v4 } from 'node-uuid';

import Position, { ControlPoint, control, LinkedPosition } from './Position';
import { boundingBox } from "../utils";

export type Point = { x: number, y: number };

export type BoundingBox = Point & {
    height: number;
    width: number;
};

export enum shape {
    CIRCLE,
    RECTANGLE,
    POLYGON,
}

export function createShape(type: shape) {
    return new ({
        [shape.CIRCLE]: Circle,
        [shape.RECTANGLE]: Rectangle,
        [shape.POLYGON]: Polygon,
    })[type]({});
}

abstract class Shape extends Model({
    position: prop<Position>(() => new Position({})),
    rotation: prop<number>(0),
}) {

    abstract type: shape;

    abstract get width(): number;
    abstract set width(w: number);

    abstract get height(): number;
    abstract set height(h: number);

    abstract get area(): number;
    abstract set area(a: number);

    abstract get perimeter(): number;
    abstract set perimeter(p: number);

    abstract get boundingBox(): BoundingBox;
    abstract set boundingBox(b: BoundingBox);

    abstract get vertexArray(): number[][];
    abstract set vertexArray(v: number[][]);

    abstract setWidth(width: number): void;

    abstract setHeight(height: number): void;

    abstract resize(width: number, height: number): void;

    rotate(deg: number) {
        this.setRotation(this.rotation + deg);
    }

    @modelAction
    setRotation(rotation: number) {
        this.rotation = rotation;
    }

    @modelAction
    setBounds(bounds: BoundingBox) {
        throw 'setBounds must be implemented in subclass';
    }

    @computed
    get box(): BoundingBox {
        return this.boundingBox;//NOTE: for rotated non-circles, these will NOT be the same
    }

    @computed
    get center() {
        return {
            x: this.boundingBox.x + this.boundingBox.width / 2,
            y: this.boundingBox.y + this.boundingBox.height / 2
        };
    }

}

@model('pt/Circle')
class Circle extends ExtendedModel(Shape, {
    radius: prop<number>(50),
}) {

    type = shape.CIRCLE;

    @modelAction
    setRadius(radius: number) {
        this.radius = radius;
    }

    @modelAction
    setBounds(bounds: BoundingBox) {
        this.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        const dimAdjust = Math.sqrt((bounds.width * bounds.height) / (this.boundingBox.width * this.boundingBox.height));
        this.radius = this.radius * dimAdjust;
    }

    @modelAction
    resize(width: number, height: number) {
        this.radius += Math.min(width, height);
    }

    @computed
    get height() {
        return this.radius * 2;
    }

    @computed
    get width() {
        return this.radius * 2;
    }

    // Noops to satisfy implementation
    setHeight() {
    }

    setWidth() {
    }

    @computed
    get perimeter() {
        return 2 * this.radius * Math.PI;
    }

    @computed
    get area() {
        return this.radius * this.radius * Math.PI;
    }

    @computed
    get boundingBox(): BoundingBox {
        return {
            x: this.position.x - this.radius,
            y: this.position.y - this.radius,
            width: this.width,
            height: this.height,
        };
    }

    vertexArray: number[][] = [];

}


@model('pt/Rectangle')
class Rectangle extends ExtendedModel(Shape, {
    height: prop<number>(50),
    width: prop<number>(50),
}) {

    type = shape.RECTANGLE;

    @modelAction
    setHeight(height: number) {
        this.height = height;
    }

    @modelAction
    setWidth(width: number) {
        this.width = width;
    }

    setBounds(bounds: BoundingBox) {
        this.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        const dimAdjust = Math.sqrt((bounds.width * bounds.height) / (this.boundingBox.width * this.boundingBox.height));

        this.setWidth(this.width * dimAdjust);
        this.setHeight(this.height * dimAdjust);
    }

    @modelAction
    resize(width: number, height: number) {
        if (Math.abs(this.width - width) > 10) {
            this.width += width;
        }
        if (Math.abs(this.height - height) > 10) {
            this.height += height;
        }
    }

    @computed
    get perimeter() {
        return this.height + this.width;//NOTE - not a real perimeter, but this is just used for sorting
    }

    @computed
    get area() {
        return this.height * this.width;
    }

    @computed
    get boundingBox(): BoundingBox {
        const { x, y, width, height } = this.box;
        return boundingBox(this.rotation, x, y, width, height);
    }

    @override
    get box(): BoundingBox {
        return {
            x: this.position.x - (this.width / 2),
            y: this.position.y - (this.height / 2),
            width: this.width,
            height: this.height
        }
    }

    @computed
    get vertexArray(): number[][] {
        const x = 0; //self.position.x;
        const y = 0; //self.position.y;

        return [
            [x, y],
            [x + this.width, y],
            [x + this.width, y + this.height],
            [x, y + this.height],
        ];
    }

}


const orderedKeys = ['ne', 'se', 'sw', 'nw'];
const orderedCorners = [{ x: 1, y: 0 }, { x: 1, y: 1 }, { x: 0, y: 1 }, { x: 0, y: 0 }];

@model('pt/Polygon')
class Polygon extends ExtendedModel(Rectangle, {
    vertices: prop<LinkedPosition[]>(() => []),
    controlPoints: prop(() => objectMap<ControlPoint>()),
    controlPointType: prop<control>(control.ORTHO),
}) {

    type = shape.POLYGON;

    onInit() {
        this.updateVertices();
    }

    @modelAction
    clearControlPoint(id: string) {
        if (this.controlPoints.has(id)) {
            this.controlPoints.delete(id);
        }

        this.updateVertices();
    }

    setVertex(idx: number, x: number, y: number) {
        if (idx >= this.vertices.length) return;
        this.vertices[idx].set(x, y);
    }

    @modelAction
    convertToVertexControlPoints() {
        if (this.controlPointType === control.VERTEX) return;

        this.controlPointType = control.VERTEX;
        this.controlPoints.clear();

        this.vertices.forEach((v: LinkedPosition, index: number) => {
            if (!v.link) {
                v.setLink(v4());
            }

            const controlPoint = new ControlPoint({ id: v.link, type: control.VERTEX, index });
            this.controlPoints.set(controlPoint.id, controlPoint);
            controlPoint.set(v.x, v.y);
        });
    }

    @modelAction
    insertControlPoint(index: number, x: number, y: number): ControlPoint {
        this.controlPoints.forEach((controlPoint: ControlPoint) => {
            if (controlPoint.index >= index) { //create a gap at idx
                controlPoint.setIndex(controlPoint.index + 1);
            }
        });

        const controlPoint = new ControlPoint({ id: v4(), type: control.VERTEX, index });
        this.controlPoints.set(controlPoint.id, controlPoint);
        controlPoint.set(x, y);

        this.updateVertices();

        return controlPoint;
    }

    @modelAction
    updateVertices() {
        if (this.controlPointType === control.VERTEX) {
            this.controlPoints.forEach(point => {
                this.vertices[point.index] = new LinkedPosition({ x: point.x, y: point.y, link: point.id });
            });
        } else {
            this.vertices = [];

            orderedKeys.forEach((dir, i) => {
                const corner = orderedCorners[i];

                if (!this.controlPoints.has(dir)) {
                    this.vertices.push(new LinkedPosition(corner));
                } else {
                    const { x, y } = this.controlPoints.get(dir)!;

                    const trio = [
                        new LinkedPosition({ x: corner.x, y }),
                        new LinkedPosition({ x, y }),
                        new LinkedPosition({ x, y: corner.y }),
                    ];

                    if (i % 2 === 0) {
                        trio.reverse();
                    }

                    this.vertices.push(...trio);
                }
            });
        }
    }

    @modelAction
    setControlPoint(type: control, id: string, x: number, y: number) {
        if (!id) return;

        if (!this.controlPoints.has(id)) {
            const controlPoint = new ControlPoint({ id, type, index: 0 });
            this.controlPoints.set(controlPoint.id, controlPoint);
            controlPoint.set(x, y);

            this.updateVertices();
        } else {
            this.controlPoints.get(id)!.set(x, y);

            if (this.controlPointType === control.ORTHO) {
                let idx = 0;

                orderedKeys.forEach((dir, i) => {
                    const corner = orderedCorners[i];

                    if (!this.controlPoints.has(dir)) {
                        idx++;
                    } else {
                        const { x, y } = this.controlPoints.get(dir)!;

                        const trio = [
                            { x: corner.x, y },
                            { x, y },
                            { x, y: corner.y },
                        ];

                        if (i % 2 === 0) {
                            trio.reverse();
                        }

                        trio.forEach(v => {
                            this.setVertex(idx++, v.x, v.y);
                        });
                    }
                });
            } else {
                this.updateVertices();
            }
        }
    }

    @computed
    get sizedVertices() {
        return this.vertices.map(v => ({
            link: v.link,
            x: v.x * this.width,
            y: v.y * this.height
        }));
    }

    @override
    get vertexArray() {
        const x = 0;//self.position.x;
        const y = 0;//self.position.y;

        return this.vertices.map(v => {
            return [
                x + v.x * this.width,
                y + v.y * this.height
            ];
        });
    }

    @override
    get area() {
        let total = 0;
        const { vertices } = this;
        for (let i = 0, len = vertices.length; i < len; i++) {
            const addX = vertices[i].x;
            const addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
            const subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
            const subY = vertices[i].y;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }

        return Math.abs(total) * this.height * this.width;
    }
}


export default Shape;
export {
    Circle,
    Rectangle,
    Polygon
}
