import { action, computed, observable, makeObservable } from 'mobx';
import { onPatches, applyPatches, Patch } from 'mobx-keystone';
import { Store } from '@strategies/stores';


class PatchGroup {

	public readonly name: string;
	public readonly patches: Patch[] = [];
	public readonly inversePatches: Patch[] = [];

	constructor(name: string = 'unspecified') {
		this.name = name;
	}

}

export default class UndoStore<T extends object> extends Store {

    @observable
	public isGrouping: any = false;

    @observable
	public queue: PatchGroup[] = [];

    @observable
	private _head: number = -1;

	private readonly _store: T;

	constructor(store: T) {
        super();
        makeObservable(this);

        this._store = store;

		onPatches(this._store, (patches: Patch[], inversePatches: Patch[]) => {
			if (this.isGrouping) {
                this.queue[this._head].patches.push(...patches);
                this.queue[this._head].inversePatches.push(...inversePatches);
			}
		});
	}

	@computed
	get canUndo(): boolean {
		return !this.isGrouping && this._head >= 0;
	}

	@computed
	get canRedo(): boolean {
		return !this.isGrouping && this._head < this.queue.length - 1;
	}

	@computed
	get group(): PatchGroup|undefined {
		return this._head >= 0 ? this.queue[this._head] : undefined;
	}

 	withGroup(cb: () => any, name?: string) {
		this.startGroup(name);
		const result: any = cb();
		this.stopGroup();
        return result;
	}

    @action
	startGroup(name: string = 'unspecified') {
		if (this.isGrouping) {
		    console.warn(`you must end the '${this.group?.name}' group before starting a new one`);
		}
        else {
            this.queue.length = this._head + 1;
            this.queue.push(new PatchGroup(name));
            this._setHead(this._head + 1);

            this._setIsGrouping();
        }
	}

    @action
	stopGroup(){
		this._setIsGrouping(false);

        if (this.group!.patches.length === 0) {
            this.queue.pop();
            this._setHead(this._head - 1);
        }
	}

    @action
	cancelGroup() {
		this._setIsGrouping(false);
        this.undo();
		this.queue.pop();
	}

	undo() {
		if (this.canUndo) {
			applyPatches(this._store, [...this.group!.inversePatches].reverse());
            this._setHead(this._head - 1);
		}
		else {
			console.warn('trying to undo when there is nothing to undo');
		}
	}

	redo() {
		if (this.canRedo) {
            this._setHead(this._head + 1);
			applyPatches(this._store, this.group!.patches);
		}
		else {
			console.warn('tying to redo when there is nothing to redo');
		}
	}

    @action
    private _setHead(head: number) {
        this._head = head;
    }

    @action
    private _setIsGrouping(isGrouping = true) {
        this.isGrouping = isGrouping;
    }

    @action
    reset() {
        this._setHead(-1);
        this.queue.length = 0;
    }

}
