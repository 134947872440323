import { useCallback, useState, ChangeEvent } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Input } from '@strategies/react-form';
import { Body, Button, Buttons, Modal, Title, SubTitle } from '@strategies/ui';
import SuperModel from "../stores/SuperModelStore";


export default observer(function CopyModal() {
    const [copying, setCopying] = useState(false);
    const { app, ui, supermodel: { file, users } } = useStores();
    const supermodel = useStores().supermodel as SuperModel;

    const [copyFileName, setCopyFileName] = useState(`Copy of ${file.fileName}`);

    const close = useCallback(() => {
        ui.setCopyModal(false);
    }, [ui]);

    const makeCopy = useCallback(() => {
        if (!copying) {
            setCopying(true);

            supermodel.appStateManager?.createDuplicate(copyFileName).then(() => {
                setCopying(false);
                close();
            });
        }
    }, [app, close, copying, file, users, copyFileName]);

    return (
        <Modal active={ui.copyModal} onClose={!copying ? close : undefined} className="CopyModal">
            <Title>Save As</Title>
            <SubTitle>
                Duplicate <em>{file.fileName}</em>. This will save to your root Google Drive folder.
            </SubTitle>

            <Body>
                <div className="copytext">
                    <Input
                        name="DOCUMENT NAME"
                        placeholder="Copy of ..."
                        value={copyFileName}
                        onChange={(val) => setCopyFileName(val as string)}
                    />
                </div>
            </Body>

            <Buttons>
                <Button disabled={copying} className="secondary" onClick={close}>
                    Cancel
                </Button>

                <Button disabled={copying} onClick={makeCopy}>
                    Save As
                </Button>
            </Buttons>
        </Modal>
    );
});
