import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { FiArrowUpLeft } from "react-icons/fi";
import { Panel, Title, Toggle, Body } from '@strategies/ui';

import SelectionInnerPanel from './InnerPanel/SelectionInnerPanel';
import SummaryInnerPanel from './InnerPanel/SummaryInnerPanel';
import CostInnerPanel from "./InnerPanel/CostInnerPanel";



export default observer(function DashboardView() {
    const { ui, records } = useStores();

    return (
        <Panel className="DashboardView" active={ui.dashboardView} onToggle={() => ui.setDashboardView(!ui.dashboardView)}>
            <Title>Analysis</Title>
            <Toggle><FiArrowUpLeft /></Toggle>
            <Body>
                <div className="InnerPanel">
                    <main>
                        {records.selected.length || 'No'} element{records.selected.length !== 1 ? 's' : ''} selected
                    </main>
                </div>

                <SelectionInnerPanel />
                <SummaryInnerPanel />
                <CostInnerPanel />
            </Body>
        </Panel>
    )
});
