import { v4 } from 'node-uuid';
import { action, computed, observable } from 'mobx';
import { Model, model, modelAction, prop, Ref, rootRef } from 'mobx-keystone';

import Record, { recordRef } from './Record';


@model('pt/Arrow')
class Arrow extends Model({
    id: prop<string>(() => v4()),
    targetRef: prop<Ref<Record>|null>(null),
    originRef: prop<Ref<Record>|null>(null),
}) {

    getRefId() {
        return this.id;
    }

    @computed
    get origin(): Record|null|undefined {
        return this.originRef ? this.originRef.maybeCurrent : null;
    }

    @modelAction
    setOrigin(origin: Record|null) {
        this.originRef = origin ? recordRef(origin) : null;
    }

    @computed
    get target(): Record|null|undefined {
        return this.targetRef ? this.targetRef.maybeCurrent : null;
    }

    @modelAction
    setTarget(target: Record|null) {
        this.targetRef = target ? recordRef(target) : null;
    }

    @observable
    selected: boolean = false;

    @action
    setSelected(selected: boolean = true) {
        this.selected = selected;
    }

}


export const arrowRef = rootRef<Arrow>("pt/ArrowRef");
export default Arrow;
