import React, { Component } from 'react';
import { observer } from "mobx-react";

type HandleProps = {
    x: number;
    y: number;
    size: number;
    className: string;
    shape?: string;
    opacity?: number;
    mouseDown?: any;
    mouseUp?: any;
}

@observer
class Handle extends Component<HandleProps> {
    render() {
        const { mouseDown, mouseUp, x, y, size, shape = 'square', className, opacity = 1 } = this.props;

        if (shape === 'circle') {
            return (
                <circle
                    className={`Handle ${className}`}
                    opacity={opacity}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    cx={x}
                    cy={y}
                    r={size / 2}
                    stroke={"blue"}
                    fill={"white"}
                />
            );
        }
        return (
            <rect
                className={`Handle ${className}`}
                opacity={opacity}
                onMouseDown={mouseDown}
                onMouseUp={mouseUp}
                x={x}
                y={y}
                width={size}
                height={size}
                stroke={"blue"}
                fill={"white"}
            />
        );

    }
}

export default Handle;
