import { stores } from '@strategies/stores';
import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';

import { SQM_TO_SQFT } from '../stores/UnitsStore';
import { goalSeek, toNum } from '../utils';


@model('pt/Project')
class Project extends Model({
    m_targetGrossArea: prop<number>(250000 / SQM_TO_SQFT),
    escalationRate: prop<number>(3.5), // (r) as perc
    escalationTime: prop<number>(3),   // (t) as months
    otherCosts: prop<number>(5000000),
    grossingFactor: prop<number>(75),
    softCostsPerc: prop<number>(5),
    totalProjectBudget: prop<number>(100000000),
}) {

    setEscalationCost(cost: number = 0) {
        const before = this.escalationRate;

        if (!goalSeek(cost, () => this.escalationCost, (change: number) => {
            this.setEscalationRate(this.escalationRate * change);
        })) {
            this.setEscalationRate(before);
        }
    }

    setCostPerUnit(costPerUnit: number = 0) {
        const before = this.totalProjectBudget;

        if (!goalSeek(costPerUnit, () => this.costPerUnit, (change: number) => {
            this.setTotalProjectBudget(this.totalProjectBudget * change);
        })) {
            this.setTotalProjectBudget(before);
        }
    }

    setProgramBudget(programBudget: number = 0) {
        const before = this.totalProjectBudget;
        if (!goalSeek(programBudget, () => this.programBudget, (change: number) => {
            this.setTotalProjectBudget(this.totalProjectBudget * change);
        })) {
            this.setTotalProjectBudget(before);
        }
    }

    setConstructionBudget(constructionBudget = 0) {
        const before = this.totalProjectBudget;

        if (!goalSeek(constructionBudget, () => this.constructionBudget, (change: number) => {
            this.setTotalProjectBudget(this.totalProjectBudget * change);
        })) {
            this.setTotalProjectBudget(before);
        }
    }

    setEscalationPerc(perc = 0) {
        const before = this.escalationRate;
        if (!goalSeek(perc, () => this.escalationPerc, (change: number) => {
            this.setEscalationRate(this.escalationRate * change);
        }, 0.001)) {
            this.setEscalationRate(before);
        }
    }

    @modelAction
    setEscalationRate(perc = 0) {
        this.escalationRate = toNum(perc);
    }

    @modelAction
    setEscalationTime(time = 0) {
        this.escalationTime = toNum(time);
    }

    @modelAction
    setOtherCosts(costs = 0) {
        this.otherCosts = toNum(costs);
    }

    @modelAction
    setSoftCostsPerc(perc = 0) {
        this.softCostsPerc = toNum(perc);
    }

    setSoftCost(softCost = 0) {
        const before = this.softCostsPerc;
        //NOTE: programBudget includes softCostsPerc, so this doesn't add up but it gets us close enough for goal seek
        this.setSoftCostsPerc(100 * toNum(softCost) / this.totalAfterEscalation);

        if (!goalSeek(softCost, () => this.softCost, (change: number) => {
            this.setSoftCostsPerc(this.softCostsPerc * change);
        })) {
            this.setSoftCostsPerc(before);
        }
    }

    @modelAction
    setGrossingFactor(factor = 0) {
        this.grossingFactor = toNum(factor);
    }

    @modelAction
    setTargetGrossArea(area = 0) {
        this.m_targetGrossArea = this.invert(toNum(area));
    }

    setTargetNetArea(area = 0) {
        this.setTargetGrossArea(area / (this.grossingFactor / 100));
    }

    @modelAction
    setTotalProjectBudget(budget = 0) {
        this.totalProjectBudget = toNum(budget);
    }

    convert(x: number) {
        return stores.units.toSqUnits(x);//NOTE: cannot use rounding here - otherwise it messes up calculations
    }

    invert(x: number) {
        return stores.units.fromSqUnits(x);
    }

    @computed
    get constructionBudget() {
        return this.softCost + this.programBudget;
    }

    @computed
    get totalAfterEscalation() {
        return this.escalationCost + this.otherCosts + this.programBudget;
    }

    @computed
    get costPerUnit() {
        return this.programBudget / (this.targetGrossArea || 1);
    }

    @computed
    get escalationCost() {
        return (this.otherCosts + this.programBudget) * (this.escalationPerc / 100);
    }

    @computed
    get netArea() {
        return this.targetGrossArea * (this.grossingFactor / 100);
    }

    @computed
    get programBudget() {
        const w = this.softCostsPerc / 100;
        const f = this.escalationPerc / 100;
        const q = w * f + w + f + 1;

        return (this.totalProjectBudget - (this.otherCosts * q)) / (q || 1);
    }

    @computed
    get softCost() {
        return this.totalAfterEscalation * (this.softCostsPerc / 100);
    }

    @computed
    get targetGrossArea() {
        return this.convert(this.m_targetGrossArea);
    }

    @computed
    get escalationPerc() {//c = (1+r)^t
        const r = this.escalationRate / 100;
        return 100 * Math.pow(1 + r, (this.escalationTime / 12)) - 100;
    }

}


export default Project;
