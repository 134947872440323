import { Store, stores } from '@strategies/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import Record from "../models/Record";

export type RecordAccessor = (item: Record) => string | number;
export type TableColumn = {
    id: string,
    label: string,
    accessor: RecordAccessor,
    sortable: boolean,
    width?: string,
};

export type GroupableColumn = TableColumn & { caption?: string, className?: string, colSpan?: number };
export const columns: GroupableColumn[] = [
    { id: "categoryPicker", label: "", width: '3%', accessor: (item: Record) => item.categoryName, sortable: true },
    { id: "name", label: "Name", width: '18%', accessor: (item: Record) => item.name, sortable: true },
    { id: "category", label: "Category", width: '15%', accessor: (item: Record) => item.categoryName, sortable: true },
    {
        id: "length", label: "Length", width: '21%', accessor: (item: Record) => item.planHeight, sortable: true,
        caption: 'Drawn Size', className: 'drawn-size', colSpan: 3
    },
    {
        id: "width",
        label: "Width",
        className: 'drawn-size',
        accessor: (item: Record) => item.planWidth,
        sortable: true
    },
    {
        id: "area",
        label: "Area",
        className: 'drawn-size',
        accessor: (item: Record) => item.squareUnits,
        sortable: true
    },
    {
        id: "net", label: "Net", width: '21%', accessor: (item: Record) => item.targetNetUnits, sortable: true,
        caption: 'Target Size', className: 'target-size', colSpan: 3
    },
    {
        id: "gross",
        label: "Gross",
        className: 'target-size',
        accessor: (item: Record) => item.targetGrossUnits,
        sortable: true
    },
    {
        id: "perc",
        label: "%",
        className: 'target-size',
        accessor: (item: Record) => item.efficiencyFactor,
        sortable: true
    },
    { id: "p_unit", label: "$/unit", width: '7%', accessor: (item: Record) => item.costPerSqUnit, sortable: true },
    { id: "comment", label: "Comment", width: '15%', accessor: (item: Record) => item.comment, sortable: true },
];

export default class TableStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get filteredRecords() {
        const { app } = stores;
        if (!app.filterBy) return stores.records.all;
        return stores.records[app.filterBy];
    }

    @observable
    sortAscending: boolean = true;

    @action
    toggleSortDirection() {
        this.sortAscending = !this.sortAscending;
    }

    @computed
    get sortedRecords(): Record[] {
        return [...this.filteredRecords].sort((a: Record, b: Record) => {
            if (!this.sortByColumn) {
                return 0;
            } else {
                let aVal = this.sortByColumn.accessor(a);
                let bVal = this.sortByColumn.accessor(b);
                if (aVal === bVal) return 0;
                if (aVal < bVal) {
                    return this.sortAscending ? 1 : -1;
                } else {
                    return this.sortAscending ? -1 : 1;
                }
            }
        });
    }

    @observable
    sortByColumn?: TableColumn;

    @action
    setSortByColumn(val: TableColumn | undefined) {
        this.sortByColumn = val;
    }

    @action
    fillSelectionUpTo(record: Record) {
        const targetIndex = this.sortedRecords.indexOf(record);
        let maxSelectedBeforeTarget = -1;
        for (let i = 0; i < targetIndex; i++) {
            const r = this.sortedRecords[i];
            if (r.selected) {
                maxSelectedBeforeTarget = i;
            }
        }
        // console.log('fillSelectionUpTo: targetIndex',targetIndex,'maxSelectedBeforeTarget',maxSelectedBeforeTarget);
        if (maxSelectedBeforeTarget >=0 ) {
            for (let i = maxSelectedBeforeTarget; i <= targetIndex; i++) {
                const r = this.sortedRecords[i];
                r.setSelected();
            }
        }
    }

    @computed
    get csvData() {
        const headers = this.commaSeparate(columns.map(c => c.label));
        const csvRows: string[] = this.sortedRecords.map((r) => {
            return this.commaSeparate(columns.map(c => c.accessor(r)));
        });
        return [headers, ...csvRows].join('\n');
    }

    commaSeparate(arr:any[]) {
        const encoded = arr.map(v=> {
            if (typeof v === 'string') {
                return `"${v}"`
            }
            return v;
        });
        return encoded.join(',')
    }

}
