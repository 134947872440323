//@ts-nocheck
import React, { Component } from 'react';
import { observer } from "mobx-react";
import { StoresContext } from '@strategies/stores';

import { stageToLinear } from "../../utils";


@observer
class Tooltip extends Component {

    static contextType = StoresContext;

    render() {
        const { record, targetMode, offsetX } = this.props;
        const { units } = this.context;

        let position = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };
        if (record.svg) {
            position = record.svg.getBoundingClientRect();
        }
        const w = stageToLinear(record.layout.width);
        const h = stageToLinear(record.layout.height);

        const linear = {
            w: units.toLinearUnits(w),
            h: units.toLinearUnits(h),
        };

        return (
            <div className="Tooltip" style={{
                left: position.x + position.width + offsetX,
                top: position.y
            }}>
                <div className="Shape__info-inner">
                    {targetMode && <>
                        <p>
                            Net: <b>{units.displayArea(record.targetNetM)}</b>
                        </p>
                        <p>
                            Gross: <b>{units.displayArea(record.targetGrossM)}</b>
                        </p>
                    </>
                    }
                    {!targetMode && <>
                        <p>
                            Area: <b>{units.displayArea(record.squareM)}</b>
                        </p>
                        <p>
                            Size: <b>{Math.round(linear.w)} x {Math.round(linear.h)}</b>
                        </p>
                    </>
                    }
                    <p>
                        Category: <b>{record.category && record.category.name}</b>
                    </p>
                </div>
            </div>
        );
    }
}

Tooltip.defaultProps = {
    offsetX: 10,
};

export default Tooltip;
